import { createContext, useCallback, useContext, useEffect, useState } from "react"
import { AuthContext } from "helpers/auth"

const UsersContext = createContext(null)

const UsersProvider = ({ children }) => {
  const auth = useContext(AuthContext)
  const [usersList, setUsersList] = useState([])
  const [entityList, setEntityList] = useState([])
  
  const findNameFromId = (id) => {
    const user = usersList.find((u) => u._id === id)
    return user ? `${user.firstname} ${user.lastname}` : ""
  }

  const fetchUsersList = useCallback(async (token) => {
    const userListResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/users`, { 
      method: "GET", 
      credentials: "include",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
    })
    const response = await userListResponse.json()
    // For react-mentions, "id" and "display" fields are required
    response.forEach((user) =>{ user.display = user.firstname + " " + user.lastname; user.id = user._id })
    setUsersList(response)
  }, [])

  const fetchEntityList = useCallback(async (token) => {
    const entityListResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/entities`, { 
      method: "GET", 
      credentials: "include",
      headers: { 
        "Content-Type": "application/json",
        "Authorization": "Bearer " + token
      }
    })
    const response = await entityListResponse.json()
    setEntityList(response)
  }, [])

  useEffect(() => {
    if (auth.user) { 
      fetchUsersList(auth.user.token)
      fetchEntityList(auth.user.token)
    }
  }, [auth.user, fetchEntityList, fetchUsersList])

  const value = { usersList, entityList, findNameFromId, fetchEntityList }
  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
}

export { UsersContext, UsersProvider }
