import writeXlsxFile from "write-excel-file"

const schema = [
  { column: "Task Name", type: String, value: t => t.taskName },
  { column: "Task Status", type: String, value: t => t.taskStatus },
  { column: "Task Deadline Date", type: Date, value: t => t.taskDeadlineDate, format: "yyyy-mm-dd" },
  { column: "Task Country", type: String, value: t => t.taskCountry },
  { column: "Task Legal Entity", type: String, value: t => t.taskEntity },
  { column: "Task Created By", type: String, value: t => t.taskCreatedBy },
  { column: "Task Description", type: String, value: t => t.taskDescription },
  { column: "Task Assigned Users", type: String, value: t => t.taskAssignedUsers },
  { column: "Task Type", type: String, value: t => t.taskType },
  { column: "Task Year", type: Number, value: t => t.taskYear },
  { column: "Task Period", type: String, value: t => t.taskPeriod },
  { column: "Subtask Name", type: String, value: t => t.subtaskName },
  { column: "Subtask Status", type: String, value: t => t.subtaskStatus },
  { column: "Subtask Deadline Date", type: Date, value: t => t.subtaskDeadlineDate, format: "yyyy-mm-dd" },
  { column: "Subtask Assigned Users", type: String, value: t => t.subtaskAssignedUsers },
]

const generateXlsx = async (taskList, users) => {
  var objects = []

  taskList.forEach((task) => {
    var t = {
      taskName: task.name, 
      taskStatus: task.status, 
      taskDeadlineDate: new Date(task.deadlineDate), 
      taskCountry: task.obligation.country, 
      taskEntity: task.entity.name, 
      taskCreatedBy: users.findNameFromId(task.createdBy._id),
      taskDescription: task.description,
      taskAssignedUsers: task.assignedUsers.map((u => users.findNameFromId(u._id))).join(", "),
      taskType: task.taskType,
      taskYear: task.year,
      taskPeriod: task.period 
    }

    if (task.subtasks.length > 0) {
      task.subtasks.forEach((subtask) => {
        objects.push({...t,
          subtaskName: subtask.name,
          subtaskStatus: subtask.status,
          subtaskDeadlineDate: new Date(subtask.deadlineDate),
          subtaskAssignedUsers: subtask.assignedUsers.map(u => users.findNameFromId(u)).join(", ")
        })
      })
    } else {
      objects.push({...t,
        subtaskName: null,
        subtaskStatus: null,
        subtaskDeadlineDate: null,
        subtaskAssignedUsers: null
      })
    }
  })

  await writeXlsxFile(objects, { 
    schema,
    headerStyle: { fontWeight: "bold" },
    fileName: "export.xlsx" 
  })
}

export default generateXlsx
