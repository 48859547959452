import { Checkbox, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";

import ButtonWithTooltip from "components/common/ButtonWithTooltip";
import { StyledTableCell, StyledTableRow } from "components/common/StyledTable";

const BankAccountsTable = ({ data, onViewClick }) => {
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Currency</StyledTableCell>
                        <StyledTableCell>Bank Name</StyledTableCell>
                        <StyledTableCell>Account Name</StyledTableCell>
                        <StyledTableCell>Nickname</StyledTableCell>
                        <StyledTableCell>Account Number</StyledTableCell>
                        <StyledTableCell>National Clearing Code</StyledTableCell>
                        <StyledTableCell>IBAN</StyledTableCell>
                        <StyledTableCell>SWIFT (BIC)</StyledTableCell>
                        <StyledTableCell>Active</StyledTableCell>
                        <StyledTableCell/>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {data?.map((row) => {
                        const {
                            id,
                            currencyCode,
                            bankName,
                            accountName,
                            nickname,
                            accountNumber,
                            nationalClearingCode,
                            iban,
                            bic,
                            isActive,
                        } = row;
                        return (
                            <StyledTableRow
                                key={id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <StyledTableCell>{currencyCode}</StyledTableCell>
                                <StyledTableCell>{bankName}</StyledTableCell>
                                <StyledTableCell>{accountName}</StyledTableCell>
                                <StyledTableCell>{nickname}</StyledTableCell>
                                <StyledTableCell>{accountNumber}</StyledTableCell>
                                <StyledTableCell>{nationalClearingCode}</StyledTableCell>
                                <StyledTableCell>{iban}</StyledTableCell>
                                <StyledTableCell>{bic}</StyledTableCell>
                                <StyledTableCell>
                                    <Checkbox disabled checked={isActive} />
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                    <ButtonWithTooltip
                                        onClick={() => onViewClick(id)}
                                        tooltipText="View account details"
                                        variant="outlined"
                                    >
                                        VIEW
                                    </ButtonWithTooltip>
                                </StyledTableCell>
                            </StyledTableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default BankAccountsTable;