import { memo, useCallback, useMemo } from "react";

import { useDispatch } from "react-redux";
import { ComposableMap, Geographies, Geography, ZoomableGroup } from "react-simple-maps";

import { Box } from "@mui/material";
import { TaskStatus } from "components/tasks/constants";
import { EU_COUNTRIES } from "helpers/constants";
import { COUNTRY_NAME_TO_COUNTRY_CODES } from "helpers/countries";
import { DEFAULT_MAP_CHART_HEIGHT } from "helpers/maps";
import useMapChartHelper, { HOVER_REGION_COLOR, PRESSED_REGION_COLOR } from "hooks/useMapChartHelper";
import { INITIAL_MAP_CHART_TOOLTIP_STATE, setMapChartTooltip, setTaskPageFilters } from "redux/slices/tasksSlice";
import MapChartControls from "../MapChartControls";
import useMapChartControls from "../MapChartControls/useMapChartControls";
import { classes } from "./styles";

const WorldMapChart = ({ data = [] }) => {
    const dispatch = useDispatch();
    const {
        position,
        handleZoomIn,
        handleZoomOut,
        handleMoveEnd,
    } = useMapChartControls({ initialCoordinates: [14, 8] });
    const { getRegionColor } = useMapChartHelper();

    const mapData = useMemo(() => {
        let taskStatusMapPerRegion = {
            [TaskStatus.Done]: {},
            [TaskStatus.Pending]: {},
            [TaskStatus.Overdue]: {},
        };

        data.forEach((task) => {
            const countryName = task.obligation?.country;
            const countryCode = EU_COUNTRIES.includes(countryName) ? 'EU' : (task.obligation?.countryCode ?? countryName);
            const taskStatus = task.status;
            switch (taskStatus) {
                case TaskStatus.Done: {
                    const currentCount = taskStatusMapPerRegion[TaskStatus.Done][countryCode] ?? 0;
                    taskStatusMapPerRegion[TaskStatus.Done] = {
                        ...taskStatusMapPerRegion[TaskStatus.Done],
                        [countryCode]: currentCount + 1,
                    }
                    break;
                }
                case TaskStatus.Pending: {
                    const currentCount = taskStatusMapPerRegion[TaskStatus.Pending][countryCode] ?? 0;
                    taskStatusMapPerRegion[TaskStatus.Pending] = {
                        ...taskStatusMapPerRegion[TaskStatus.Pending],
                        [countryCode]: currentCount + 1,
                    }
                    break;
                }
                case TaskStatus.Overdue: {
                    const currentCount = taskStatusMapPerRegion[TaskStatus.Overdue][countryCode] ?? 0;
                    taskStatusMapPerRegion[TaskStatus.Overdue] = {
                        ...taskStatusMapPerRegion[TaskStatus.Overdue],
                        [countryCode]: currentCount + 1,
                    }
                    break;
                }
                default:
                    break;
            }
        })
        return taskStatusMapPerRegion;
    }, [data]);

    const handleMouseEnter = useCallback((geo) => {
        const name = geo.properties.name;
        const alphaCode = EU_COUNTRIES.includes(name) ? 'EU' : COUNTRY_NAME_TO_COUNTRY_CODES[name];
        const pendingTasksCount = mapData[TaskStatus.Pending][alphaCode] ?? 0;
        const completedTasksCount = mapData[TaskStatus.Done][alphaCode] ?? 0;
        const overdueTasksCount = mapData[TaskStatus.Overdue][alphaCode] ?? 0;

        dispatch(setMapChartTooltip({
            mapChartTooltip: {
                name: alphaCode === 'EU' ? 'European Union' : name,
                data: {
                    pending: pendingTasksCount,
                    completed: completedTasksCount,
                    overdue: overdueTasksCount,
                },
            }
        }));
    }, [dispatch, mapData]);

    const handleMouseLeave = () => {
        dispatch(setMapChartTooltip({ mapChartTooltip: INITIAL_MAP_CHART_TOOLTIP_STATE }));
    };

    const getMapColor = useCallback((alphaCode) => {
        const data = {
            [TaskStatus.Pending]: mapData[TaskStatus.Pending]?.[alphaCode],
            [TaskStatus.Done]: mapData[TaskStatus.Done]?.[alphaCode],
            [TaskStatus.Overdue]: mapData[TaskStatus.Overdue]?.[alphaCode],
        };
        return getRegionColor(data);
    }, [getRegionColor, mapData]);

    return (
        <Box data-tip="" sx={classes.root}>
            <ComposableMap height={DEFAULT_MAP_CHART_HEIGHT}>
                <ZoomableGroup
                    zoom={position.zoom}
                    center={position.coordinates}
                    onMoveEnd={handleMoveEnd}
                >
                    <Geographies geography="/features.json">
                        {({ geographies }) =>
                            geographies.map((geo) => {
                                const countryName = geo.properties.name;
                                const alphaCode = COUNTRY_NAME_TO_COUNTRY_CODES[countryName];
                                const fill = getMapColor(alphaCode);
                                return (
                                    <Geography
                                        key={geo.rsmKey}
                                        geography={geo}
                                        onClick={() => {
                                            dispatch(setTaskPageFilters({
                                                pageFilters: {
                                                    country: alphaCode
                                                }
                                            }))
                                        }}
                                        onMouseEnter={() => handleMouseEnter(geo)}
                                        onMouseLeave={handleMouseLeave}
                                        style={{
                                            default: {
                                                fill,
                                                stroke: "#F6F6FA",
                                                outline: "none"
                                            },
                                            hover: {
                                                fill: HOVER_REGION_COLOR,
                                                outline: "none"
                                            },
                                            pressed: {
                                                fill: PRESSED_REGION_COLOR,
                                                outline: "none"
                                            }
                                        }}
                                    />
                                )
                            })
                        }
                    </Geographies>
                </ZoomableGroup>
            </ComposableMap>
            <MapChartControls onZoomOut={handleZoomOut} onZoomIn={handleZoomIn} />
        </Box>
    );
}

export default memo(WorldMapChart)
