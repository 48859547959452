export const US_STATES_LIST =
    ['AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];

export const US_STATE_CODE_TO_NAME = {
    "AL": "Alabama",
    "AK": "Alaska",
    "AS": "American Samoa",
    "AZ": "Arizona",
    "AR": "Arkansas",
    "CA": "California",
    "CO": "Colorado",
    "CT": "Connecticut",
    "DE": "Delaware",
    "DC": "District Of Columbia",
    "FM": "Federated States Of Micronesia",
    "FL": "Florida",
    "GA": "Georgia",
    "GU": "Guam",
    "HI": "Hawaii",
    "ID": "Idaho",
    "IL": "Illinois",
    "IN": "Indiana",
    "IA": "Iowa",
    "KS": "Kansas",
    "KY": "Kentucky",
    "LA": "Louisiana",
    "ME": "Maine",
    "MH": "Marshall Islands",
    "MD": "Maryland",
    "MA": "Massachusetts",
    "MI": "Michigan",
    "MN": "Minnesota",
    "MS": "Mississippi",
    "MO": "Missouri",
    "MT": "Montana",
    "NE": "Nebraska",
    "NV": "Nevada",
    "NH": "New Hampshire",
    "NJ": "New Jersey",
    "NM": "New Mexico",
    "NY": "New York",
    "NC": "North Carolina",
    "ND": "North Dakota",
    "MP": "Northern Mariana Islands",
    "OH": "Ohio",
    "OK": "Oklahoma",
    "OR": "Oregon",
    "PW": "Palau",
    "PA": "Pennsylvania",
    "PR": "Puerto Rico",
    "RI": "Rhode Island",
    "SC": "South Carolina",
    "SD": "South Dakota",
    "TN": "Tennessee",
    "TX": "Texas",
    "UT": "Utah",
    "VT": "Vermont",
    "VI": "Virgin Islands",
    "VA": "Virginia",
    "WA": "Washington",
    "WV": "West Virginia",
    "WI": "Wisconsin",
    "WY": "Wyoming"
};

export const CANADA_PROVINCE_LIST =
    ["AB", "BC", "MB", "NB", "NL", "NS", "NT", "NU", "ON", "PE", "QC", "SK", "YT"];

export const CANADA_PROVINCE_CODE_TO_NAME = {
    "AB": "Alberta",
    "BC": "British Columbia",
    "MB": "Manitoba",
    "NB": "New Brunswick",
    "NL": "Newfoundland and Labrador",
    "NS": "Nova Scotia",
    "NT": "Northwest Territories",
    "NU": "Nunavut",
    "ON": "Ontario",
    "PE": "Prince Edward Island",
    "QC": "Québec",
    "SK": "Saskatchewan",
    "YT": "Yukon"
};

export const CONTINENT_GROUP = {
    EMEA: "EMEA",
    AMERICAS: "AMERICAS",
    APAC: "APAC",
    GENERAL: 'GENERAL'
};

export const AMERICAS_COUNTRIES =
    ['AI', 'AG', 'AR', 'AW', 'BS', 'BB', 'BZ', 'BM', 'BO', 'BR', 'CA', 'KY', 'CL', 'CO', 'CR', 'CU', 'DM', 'DO', 'EC', 'SV', 'GF', 'GD', 'GP', 'GT', 'GY', 'HT', 'HN', 'JM', 'MQ', 'MX', 'MS', 'NI', 'PA', 'PY', 'PE', 'PR', 'KN', 'LC', 'PM', 'VC', 'SR', 'TT', 'TC', 'US', 'UY', 'VE', 'VG', 'VI'];

export const APAC_COUNTRIES =
    ['AS', 'AQ', 'AU', 'BD', 'BT', 'IO', 'BN', 'KH', 'CN', 'CX', 'CC', 'CK', 'FJ', 'PF', 'TF', 'GU', 'HM', 'HK', 'IN', 'ID', 'JP', 'KI', 'KG', "KR", 'LA', 'MO', 'MY', 'MV', 'MH', 'FM', 'MN', 'MM', 'NR', 'NP', 'NC', 'NZ', 'NU', 'NF', 'MP', 'PK', 'PW', 'PG', 'PH', 'PN', 'WS', 'SG', 'SB', 'LK', 'TW', 'TJ', 'TH', 'TL', 'TK', 'TO', 'TM', 'TV', 'UM', 'UZ', 'VU', 'VN', 'WF'];

export const EMEA_COUNTRIES =
    ["AF", "AX", "AL", "DZ", "AD", "AO", "AM", "AT", "AZ", "BH", "BY", "BE", "BJ", "BA", "BW", "BV", "BG", "BF", "BI", "CM", "CV", "CF", "TD", "KM", "CD", "CG", "CI", "HR", "CY", "CZ", "DK", "DJ", "EG", "GQ", "ER", "EE", "ET", "FK", "FO", "FI", "FR", "GA", "GM", "GE", "DE", "GH", "GI", "GR", "GL", "GG", "GN", "GW", "VA", "HU", "IS", "IR", "IQ", "IE", "IM", "IL", "IT", "JE", "JO", "KZ", "KE", "KW", "LV", "LB", "LS", "LR", "LY", "LI", "LT", "LU", "MK", "MG", "MW", "ML", "MT", "MR", "MU", "YT", "MD", "MC", "MA", "MZ", "NA", "NL", "NE", "NG", "NO", "OM", "PS", "PL", "PT", "QA", "RE", "RO", "RU", "RW", "SH", "SM", "ST", "SA", "SN", "CS", "SC", "SL", "SK", "SI", "SO", "ZA", "GS", "ES", "SD", "SJ", "SZ", "SE", "CH", "SY", "TZ", "TG", "TN", "TR", "UG", "UA", "AE", "GB", "EH", "YE", "ZM", "ZW"];

export const ALL_COUNTRIES =
    ["AF", "AX", "AL", "DZ", "AS", "AD", "AO", "AI", "AQ", "AG", "AR", "AM", "AW", "AU", "AT", "AZ", "BS", "BH", "BD", "BB", "BY", "BE", "BZ", "BJ", "BM", "BT", "BO", "BA", "BW", "BV", "BR", "IO", "BN", "BG", "BF", "BI", "KH", "CM", "CA", "CV", "KY", "CF", "TD", "CL", "CN", "CX", "CC", "CO", "KM", "CG", "CD", "CK", "CR", "HR", "CU", "CY", "CZ", "DK", "DJ", "DM", "DO", "EC", "EG", "SV", "GQ", "ER", "EE", "ET", "FK", "FO", "FJ", "FI", "FR", "GF", "PF", "TF", "GA", "GM", "GE", "DE", "GH", "GI", "GR", "GL", "GD", "GP", "GU", "GT", "GG", "GN", "GW", "GY", "HT", "HM", "VA", "HN", "HK", "HU", "IS", "IN", "ID", "IR", "IQ", "IE", "IM", "IL", "IT", "JM", "JP", "JE", "JO", "KZ", "KE", "KI", "KR", "KP", "KW", "KG", "LV", "LB", "LS", "LR", "LY", "LI", "LT", "LU", "MO", "MK", "MG", "MW", "MY", "MV", "ML", "MT", "MH", "MQ", "MR", "MU", "YT", "MX", "FM", "MD", "MC", "MN", "MS", "MA", "MZ", "MM", "NA", "NR", "NP", "NL", "AN", "NC", "NZ", "NI", "NE", "NG", "NU", "NF", "MP", "NO", "OM", "PK", "PW", "PS", "PA", "PG", "PY", "PE", "PH", "PN", "PL", "PT", "PR", "QA", "RE", "RO", "RU", "RW", "SH", "KN", "LC", "PM", "VC", "WS", "SM", "ST", "SA", "SN", "SC", "SL", "SG", "SK", "SI", "SB", "SO", "ZA", "GS", "ES", "LK", "SD", "SR", "SJ", "SZ", "SE", "CH", "SY", "TW", "TJ", "TZ", "TH", "TL", "TG", "TK", "TO", "TT", "TN", "TR", "TM", "TC", "TV", "UG", "UA", "AE", "GB", "US", "UM", "UY", "UZ", "VU", "VE", "VN", "VG", "VI", "WF", "EH", "YE", "ZM", "ZN"];

export const CONTINENT_GROUP_ORDER = [
    CONTINENT_GROUP.EMEA,
    CONTINENT_GROUP.AMERICAS,
    CONTINENT_GROUP.APAC,
];

export const CONTINENT_GROUP_COUNTRIES_MAPPING = {
    [CONTINENT_GROUP.EMEA]: EMEA_COUNTRIES,
    [CONTINENT_GROUP.AMERICAS]: AMERICAS_COUNTRIES,
    [CONTINENT_GROUP.APAC]: APAC_COUNTRIES,
    [CONTINENT_GROUP.GENERAL]: ALL_COUNTRIES,
};
