import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Grid, Typography } from "@mui/material";

import useMonexApiHelper from "hooks/useMonexApiHelper";
import { selectOwnBankAccountList } from "redux/slices/selectors";

import TableLoader from "components/common/TableLoader";
import BankAccountsTable from "../BankAccountsTable";

const OwnAccountsPage = () => {
    const navigate = useNavigate();

    const ownBankAccountList = useSelector(selectOwnBankAccountList);

    const isPageLoading = useMemo(() => ownBankAccountList?.length === 0, [ownBankAccountList?.length]);

    const { fetchOwnBankAccountList } = useMonexApiHelper();

    useEffect(() => {
        if (ownBankAccountList?.length === 0) {
            fetchOwnBankAccountList();
        }
    }, [fetchOwnBankAccountList, ownBankAccountList?.length]);

    const handlePaymentDetailRedirect = (paymentId) => {
        navigate(`/own-account/${paymentId}`);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography sx={{ m: 1 }} variant="h4">Own Bank Accounts</Typography>
            </Grid>

            <Grid item xs={12}>
                {isPageLoading ? (
                    <TableLoader />
                ) : (
                    <BankAccountsTable data={ownBankAccountList} onViewClick={handlePaymentDetailRedirect} />
                )}
            </Grid>
        </Grid>
    );
};

export default OwnAccountsPage;