import { useMemo } from "react";

import { useSelector } from "react-redux";
import { selectBalanceList, selectQuote, selectTradeList } from "redux/slices/selectors";

import { COUNTRY_CODE_TO_CURRENCY_CODE, COUNTRY_NAME_TO_COUNTRY_CODES } from "helpers/countries";
import moment from "moment";

const useTradeModal = ({ task = {} }) => {
    const { tradeId, paymentId, obligation } = task;
    const { country } = obligation || {};
    const quote = useSelector(selectQuote);
    const tradeList = useSelector(selectTradeList);
    const balanceList = useSelector(selectBalanceList);
    const today = moment().endOf('day').format("YYYY-MM-DD");

    const trade = useMemo(() => tradeList?.find((tradeItem) => tradeItem.id === tradeId) ?? {}, [tradeId, tradeList]);
    const sellCurrencyCodeOptions = useMemo(() => balanceList?.map((balance) => balance.currencyCode) ?? [], [balanceList]);

    const countryCode = COUNTRY_NAME_TO_COUNTRY_CODES[country];
    const currencyCode = COUNTRY_CODE_TO_CURRENCY_CODE[countryCode]; // Foreign currency

    const spotDate = moment().add(2, 'day').endOf('day').format("LLLL");
    const isTradeExpired = Date.parse(today) > Date.parse(trade?.valueDate);
    const hasPayment = Boolean(paymentId);
    const isTradeDisabled = (Boolean(tradeId) && !isTradeExpired) || hasPayment;
    const tradeTooltipText = useMemo(() => {
        if (hasPayment) {
            return 'There is already an existing payment for this task.';
        } else if (isTradeDisabled) {
            return 'There is already an existing trade for this task.';
        }
        return 'Create trade';
      }, [hasPayment, isTradeDisabled]);

    return {
        quote,
        countryCode,
        currencyCode,
        spotDate,
        isTradeDisabled,
        isTradeExpired,
        tradeTooltipText,
        sellCurrencyCodeOptions,
    };
};

export default useTradeModal;