
import { Chip } from "@mui/material";
import { TradeStatusColorMapping, TradeStatusLabelMapping } from "./constants";

const TradeStatusPill = ({ status }) => {
    const label = TradeStatusLabelMapping[status];

    if (!Boolean(status)) {
        return '-';
    }

    return (
        <Chip
            sx={{
                minWidth: "100px",
                background: TradeStatusColorMapping[status],
                color: 'white',
                fontWeight: 600,
            }}
            label={label}
        />
    );
}

export default TradeStatusPill;
