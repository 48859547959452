import { useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { isEmpty } from "lodash-es";

import { AuthContext } from "helpers/auth";
import useMonexApiHelper from "hooks/useMonexApiHelper";
import useTaskHelper from "hooks/useTaskHelper";
import { selectTaskList, selectTradeDetail } from "redux/slices/selectors";

import DetailTextCell from "components/common/DetailTextCell";
import FullPageLoader from "components/common/FullPageLoader";
import TradeStatusPill from "components/tasks/TradeStatusCell";

const TradeDetail = () => {
    const auth = useContext(AuthContext);
    const dispatch = useDispatch();

    const { id: tradeIdParam } = useParams();
    const tradeDetail = useSelector(selectTradeDetail);
    const taskList = useSelector(selectTaskList);
    const { id: tradeId, code, valueDate, rate, sellCurrencyCode, sellAmount, buyCurrencyCode, buyAmount, status, clientReference } = tradeDetail;
    const linkedTask = useMemo(() => taskList.find((task) => task.tradeId === tradeId) ?? {}, [tradeId, taskList]);
    const { _id: taskId, name: taskName } = linkedTask;

    const isPageLoading = useMemo(() => (
        taskList.length === 0 || isEmpty(tradeDetail) || tradeIdParam !== tradeId
    ), [taskList.length, tradeDetail, tradeIdParam, tradeId]);

    const { fetchTaskListData } = useTaskHelper();
    const { fetchTradeDetail } = useMonexApiHelper();

    useEffect(() => {
        if (auth.user && taskList.length === 0) {
            fetchTaskListData({ auth });
        }
    }, [auth, fetchTaskListData, taskList.length]);

    useEffect(() => {
        if (isEmpty(tradeDetail) || tradeIdParam !== tradeId) {
            fetchTradeDetail({ tradeId: tradeIdParam });
        }
      }, [dispatch, fetchTradeDetail, tradeDetail, tradeId, tradeIdParam]);

    return (
        <Grid container spacing={3}>
            <FullPageLoader open={isPageLoading} />
            <Grid item xs={12}>
                <Typography sx={{ m: 1 }} variant="h4">Trade Details for {taskName}</Typography>
            </Grid>

            {/* Trade Details */}
            <Grid item xs={12} md={6}>
                <Paper elevation={6} sx={{ p: 2, display: "flex", flexDirection: "column", height: "100%" }}>
                    <Box sx={{ pb: 1 }}>
                        <Grid container>
                            <DetailTextCell xs={8} header="Linked Task" detail={taskName} />
                            <Grid item xs={4}>
                                <Typography variant="subtitle2" component="div">Payment Status</Typography>
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, pt: 1 }}>
                                    <TradeStatusPill status={status} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <Box sx={{ pt: 1 }}>
                        <Grid container>
                            <DetailTextCell xs={4} header="Client Reference" detail={clientReference} />
                            <DetailTextCell xs={4} header="Code" detail={code} />
                            <DetailTextCell xs={4} header="Value Date" detail={valueDate} />
                        </Grid>
                    </Box>
                </Paper>
            </Grid>

            {/* Amount Details */}
            <Grid item xs={12} md={6}>
                <Paper elevation={6} sx={{ p: 2, display: "flex", flexDirection: "column", height: "100%" }}>
                    <Box sx={{ pb: 1 }}>
                        <Grid container>
                            <DetailTextCell header="Amount to Pay" detail={`${buyCurrencyCode} ${buyAmount}`} />
                            <DetailTextCell header="Equivalent in USD" detail={`${sellCurrencyCode} ${sellAmount}`} />
                            <DetailTextCell xs={12} header="Rate" detail={rate} />
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default TradeDetail;