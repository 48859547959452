import { useMemo } from "react";

import { Typography } from "@mui/material";
import { PaymentStatus, PaymentStatusLabelMapping } from "components/tasks/constants";

const PaymentStatusTag = ({ status }) => {
    const backgroundColor = useMemo(() => {
        switch (status) {
            case PaymentStatus.Pending:
                return '#FFB020';
            case PaymentStatus.Completed:
                return '#14B8A6';
            case PaymentStatus.Canceled:
                return '#D32F2F';
            default:
                return '#1976D2';
        }
    }, [status]);

    const label = PaymentStatusLabelMapping[status];

    if (!Boolean(status)) {
        return '-';
    }

    return (
        <Typography
            sx={{
                backgroundColor,
                color: 'white',
                fontWeight: 700,
                padding: '6px',
                borderRadius: '8px',
                fontSize: '12px',
                width: 'fit-content'
            }}
        >
            {label}
        </Typography>
    );
}

export default PaymentStatusTag;
