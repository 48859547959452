
import { useCallback } from "react";

import { toast } from "react-toastify";
import useMonexApiHelper from "./useMonexApiHelper";
import useTaskHelper from "./useTaskHelper";

const FETCH_RECEIPT_DATA_TOAST_ID = 'fetch-payment-toast-id';

const usePaymentReceiptHelper = () => {
    const { fetchPaymentDetail, fetchTradeDetail } = useMonexApiHelper();
    const { fetchTaskDetail } = useTaskHelper();

    const showErrorSnackbar = (message) => toast.error(message, { autoClose: 3500 });

    const fetchPaymentReceiptData = useCallback(
        async ({ auth, taskId, tradeId, paymentId, onOpenReceiptModal }) => {
            if (!paymentId) {
                return showErrorSnackbar("Payment data not found.");
            }
            if (!tradeId) {
                return showErrorSnackbar("Trade data not found.");
            }
            if (!taskId) {
                return showErrorSnackbar("Task data not found.");
            }

            toast('Fetching receipt data...', { autoClose: false,  toastId: FETCH_RECEIPT_DATA_TOAST_ID });
            const taskDetail = await fetchTaskDetail({ taskId, auth });
            const paymentDetail = await fetchPaymentDetail({ paymentId });
            const tradeDetail = await fetchTradeDetail({ tradeId });
            toast.done(FETCH_RECEIPT_DATA_TOAST_ID);

            if (taskDetail && paymentDetail && tradeDetail) {
                onOpenReceiptModal?.();
            } else {
                showErrorSnackbar("Encountered an error: Task or payment data not found.");
            }
        }, [fetchPaymentDetail, fetchTaskDetail, fetchTradeDetail]
    );

    return {
        fetchPaymentReceiptData,
    };
};

export default usePaymentReceiptHelper;
