import { createContext, forwardRef, useState } from "react"
import { Stack, Snackbar, Alert as MuiAlert } from "@mui/material"

const Alert = forwardRef((props, ref) => {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

const SnackbarContext = createContext(null)

const SnackbarProvider = ({ children }) => {
  const [snack, setSnack] = useState({ message: "", severity: "" })
  const [open, setOpen] = useState(false)

  const handleClose = (event, reason) => {
    if (reason === "clickaway") { return }
    setOpen(false)
  }

  const setMessage = ({ message, severity }) => {
    setSnack({ message, severity })
    setOpen(true)
  }

  const value = { setMessage }
  return (
    <SnackbarContext.Provider value={value}>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar open={open} autoHideDuration={5000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={snack.severity} sx={{ width: "100%" }}>
            {snack.message}
          </Alert>
        </Snackbar>
      </Stack>
      {children}
    </SnackbarContext.Provider>
  )
}

export { SnackbarContext, SnackbarProvider }
