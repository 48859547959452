import { Fragment, useState, useContext } from "react"
import { Button, Modal, Box, Divider, TextField, Grid, MenuItem, IconButton, FormControl, InputLabel, Select } from "@mui/material"
import { Edit as EditIcon } from "@mui/icons-material"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { ValidatorForm, SelectValidator } from "react-material-ui-form-validator"
import { AuthContext } from "helpers/auth"
import { UsersContext } from "helpers/users"
import { SnackbarContext } from "helpers/snackbar"
import COUNTRIES from "helpers/countries"


const ResidenceModal = ({ residence, actions }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    actions.toggleUpdate() 
    setCurrentResidence({
      user: "",
      country: "",
      startDate: new Date(),
      endDate: new Date(),
    })
  }

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  }

  const [currentResidence, setCurrentResidence] = useState(
    residence ? {
      ...residence,
    } 
    : {
      user: "",
      country: COUNTRIES[0],
      startDate: new Date(),
      endDate: new Date(),
  })
  const auth = useContext(AuthContext)
  const users = useContext(UsersContext)
  const snackbar = useContext(SnackbarContext)

  const handleSubmit = async () => {
    try {
      const url = residence ? `${process.env.REACT_APP_API_PROXY}/api/residences/${encodeURIComponent(residence._id)}` : `${process.env.REACT_APP_API_PROXY}/api/residences`
      const method = residence ? "PUT" : "POST"
      await fetch(url, { 
        method: method, 
        credentials: "include",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth.user.token
        },
        body: JSON.stringify(currentResidence) 
      })
      actions.toggleUpdate()
      snackbar.setMessage({ message: `Residence successfully ${residence ? "updated" : "created"}!`, severity: "success" })
      handleClose()
    } catch (error) {
      console.log(error)
      snackbar.setMessage({ message: error.message, severity: "error" })
    }
  }

  return (
    <Fragment>
      {residence ? 
        <IconButton variant="contained" onClick={handleOpen}>
          <EditIcon />
        </IconButton> 
      : 
        <Button color="primary" variant="contained" onClick={handleOpen}>
          Add Residence
        </Button>
      }
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Header */}
              <Grid item xs={12}>
                {`${residence ? "Edit" : "Add"} Residence`}
                <Divider sx={{ p: 1 }} />  
              </Grid>

              {/* User */}
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <SelectValidator
                    fullWidth
                    select
                    variant="outlined"
                    label="User"
                    value={currentResidence.user} 
                    name={"user"}
                    onChange={(e) => setCurrentResidence({...currentResidence, user: e.target.value})}
                    validators={["required"]}
                    errorMessages={["This field is required"]}
                  >
                    { users.usersList.map(user => (
                      <MenuItem key={user._id} value={user._id}>
                        {users.findNameFromId(user._id)}
                      </MenuItem>
                    )) }
                  </SelectValidator>
                </FormControl>
              </Grid>

              {/* Country */}
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel>Country</InputLabel>
                  <Select 
                    variant="outlined"
                    label="Country" 
                    value={currentResidence.country} 
                    name={"country"}
                    onChange={(e) => setCurrentResidence({...currentResidence, country: e.target.value})}
                  >
                    { COUNTRIES.map(c => <MenuItem key={c} value={c}>{c}</MenuItem>) }
                  </Select>
                </FormControl>
              </Grid>

              {/* Start date */}
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Start Date"
                    value={currentResidence.startDate}
                    name={"startDate"}
                    onChange={(newValue) => setCurrentResidence({...currentResidence, startDate: newValue})}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Grid>

              {/* End date */}
              <Grid item xs={3}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="End Date"
                    value={currentResidence.endDate}
                    name={"endDate"}
                    onChange={(newValue) => setCurrentResidence({...currentResidence, endDate: newValue})}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Grid>

              {/* Submit */}
              <Grid item xs={12}>
                <Button variant="contained" type="submit">Submit</Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Box>
      </Modal>
    </Fragment>
  )
}

export default ResidenceModal
