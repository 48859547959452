// @ts-nocheck
import { useCallback, useMemo, useState } from 'react';

import { LoadingButton } from '@mui/lab';
import { Box, Button, Divider, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, LinearProgress, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';

import useMonexApiHelper from 'hooks/useMonexApiHelper';
import useStepperHelper from 'hooks/useStepperHelper';
import useTimer from 'hooks/useTimer';
import { COOLDOWN_DURATION_IN_SEC, CREATE_TRADE_MODAL_STEPS } from './constants';

import useToastDisplay from 'hooks/useToastDisplay';
import useTradeModal from 'hooks/useTradeModal';
import './styles.css';

const CreateTradeModal = ({ task, onClose }) => {
    const [loading, setLoading] = useState(false);
    const [refreshTokenLoading, setRefreshTokenLoading] = useState(false);
    const { value: quoteTimeLeft, startTimer } = useTimer({ durationInSeconds: COOLDOWN_DURATION_IN_SEC });
    const isRateExpired = useMemo(() => quoteTimeLeft === COOLDOWN_DURATION_IN_SEC, [quoteTimeLeft]);

    const {
        quote,
        currencyCode,
        sellCurrencyCodeOptions,
    } = useTradeModal({ task });
    const { postTrade, fetchQuote, fetchTradeList } = useMonexApiHelper();
    const { showSuccessSnackbar, showErrorSnackbar } = useToastDisplay();

    const { rate, sellAmount, sellCurrencyCode, buyAmount, id: quoteId } = useMemo(() => quote ?? {}, [quote]);
    const { register, handleSubmit, formState: { isValid, errors } } = useForm({
        mode: 'onChange',
    });

    const {
        activeStep,
        handleNext,
        handleBack,
    } = useStepperHelper();

    const handleGetQuote = useCallback(async (data) => {
        const { clientReference, currencyHave, sellCurrencyCode } = data ?? {};
        const formData = {
            sellCurrencyCode,
            buyCurrencyCode: currencyCode,
            amount: currencyHave,
            amountFixed: 'buy',
            tradeType: 'spot',
            clientReference: clientReference ? clientReference : undefined,
        }
        setLoading(true);
        const fetchQuoteResponse = await fetchQuote(formData);
        setLoading(false);
        if (fetchQuoteResponse?.message) {
            showErrorSnackbar(fetchQuoteResponse?.message);
        } else {
            handleNext();
            startTimer();
        }
    }, [currencyCode, fetchQuote, handleNext, showErrorSnackbar, startTimer]);

    const handleRefreshRate = useCallback(async (data) => {
        const { clientReference, currencyHave, sellCurrencyCode } = data ?? {};
        const formData = {
            sellCurrencyCode: sellCurrencyCode,
            buyCurrencyCode: currencyCode,
            amount: currencyHave,
            amountFixed: 'buy',
            tradeType: 'spot',
            clientReference: clientReference ? clientReference : undefined,
        }
        setRefreshTokenLoading(true);
        const fetchQuoteResponse = await fetchQuote(formData);
        setRefreshTokenLoading(false);
        if (fetchQuoteResponse?.message) {
            showErrorSnackbar(fetchQuoteResponse?.message);
        } else {
            startTimer();
        }
    }, [currencyCode, fetchQuote, showErrorSnackbar, startTimer]);

    const handlePostTrade = useCallback(async () => {
        if (quoteId) {
            setLoading(true);
            const postTradeResponse = await postTrade({ quoteId, task });
            if (postTradeResponse?.message) {
                showErrorSnackbar(postTradeResponse?.message);
            } else {
                await fetchTradeList();
                setLoading(false);
                showSuccessSnackbar(`Successfully created trade request for ${task.name}.`);
                onClose();
            }
        }
    }, [fetchTradeList, onClose, postTrade, quoteId, showErrorSnackbar, showSuccessSnackbar, task]);

    return (
        <Modal open onClose={onClose}>
            <Box className="box">
                {/* Header */}
                <Grid item xs={12} sx={{ marginBottom: '16px' }}>
                    <Typography variant="h6">
                        {CREATE_TRADE_MODAL_STEPS[activeStep].title} <strong className="blue">{task.name}</strong>
                    </Typography>
                    <Divider sx={{ p: 1 }} />
                </Grid>

                {/* Content */}
                <Box>
                    {activeStep === 0 && (
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <FormControl sx={{ width: "100%" }}>
                                    <TextField
                                        id="currencyHave"
                                        label="Pending payment from task"
                                        InputProps={{ startAdornment: <InputAdornment position="start">{currencyCode}</InputAdornment> }}
                                        fullWidth
                                        type="number"
                                        defaultValue={task?.amount}
                                        {...register("currencyHave", {
                                            valueAsNumber: true,
                                            required: true,
                                        })}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel
                                        sx={{ background: 'white' }}
                                        id="currency-for-payment"
                                    >
                                        Currency for payment
                                    </InputLabel>
                                    <Select
                                        fullWidth
                                        variant="outlined"
                                        InputLabelProps={{ shrink: true }}
                                        defaultValue="USD"
                                        {...register("sellCurrencyCode")}
                                    >
                                        {sellCurrencyCodeOptions.map(option => (
                                            <MenuItem key={option} value={option}>{option}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                                <FormControl sx={{ width: "100%" }}>
                                    <TextField
                                        id="clientReference"
                                        label="Trade reference"
                                        fullWidth
                                        placeholder="Enter client reference"
                                        InputLabelProps={{ shrink: true }}
                                        defaultValue={task?.clientReference}
                                        error={Boolean(errors.clientReference)}
                                        {...register("clientReference", {
                                            required: true,
                                            pattern: {
                                                message: "Special characters and white spaces are not allowed.",
                                                value: /^([a-zA-Z0-9\u0600-\u06FF\u0660-\u0669\u06F0-\u06F9]+)$/
                                            },
                                        })}
                                    />
                                    {errors.clientReference?.type === 'pattern' &&  <FormHelperText error>{errors.clientReference.message}</FormHelperText>}
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} className="footer">
                                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Divider sx={{ p: 1 }} />
                                    <Typography variant="caption" sx={{ marginTop: '8px' }} align="center" gutterBottom>
                                        For Monex to make your payment in time, you must ensure they have received settlement within <strong>two business days</strong>.
                                    </Typography>
                                </Grid>
                                <LoadingButton variant="contained" loading={loading} onClick={handleSubmit(handleGetQuote)} className="button" disabled={!isValid}>
                                    GET A QUOTE
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    )}
                    {activeStep === 1 && (
                        <FormControl className="form">
                            <Grid container spacing={3}>
                                <Grid item xs={12}>
                                    <Grid item xs={12} className="container-info">
                                        <Typography>
                                            Live exchange rate is: <strong className="blue">{rate}</strong>
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        value={sellAmount}
                                        id="sellAmount"
                                        label="SELLING"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">{sellCurrencyCode}</InputAdornment>
                                        }}
                                        type="number"
                                        disabled
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        value={buyAmount}
                                        id="buyAmount"
                                        label="BUYING"
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">{currencyCode}</InputAdornment>
                                        }}
                                        type="number"
                                        disabled
                                        fullWidth
                                    />
                                </Grid>

                                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                    <Grid item xs={12} sx={{ width: '300px' }}>
                                        <Box sx={{ dislay: 'flex', flexDirection: 'column' }}>
                                            <LinearProgress color="success" variant="determinate" value={(quoteTimeLeft / COOLDOWN_DURATION_IN_SEC) * 100} sx={{ marginBottom: '4px' }} />
                                            {isRateExpired && (
                                                <LoadingButton
                                                    variant="contained"
                                                    color="success"
                                                    loading={refreshTokenLoading}
                                                    disabled={loading}
                                                    onClick={handleSubmit(handleRefreshRate)}
                                                    sx={{ width: '300px', height: '40px', borderRadius: 0, borderTop: 'none' }}
                                                >
                                                    REFRESH RATE
                                                </LoadingButton>
                                            )}
                                            {!isRateExpired && (
                                                <Box className="timer">
                                                    <Typography>Rate expires in <strong>{quoteTimeLeft} seconds</strong></Typography>
                                                </Box>
                                            )}
                                        </Box>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'column', gap: '10px', justifyContent: 'center', alignItems: 'center' }}>
                                    <LoadingButton
                                        variant="contained"
                                        loading={loading}
                                        onClick={handlePostTrade}
                                        disabled={isRateExpired}
                                        className="button"
                                    >
                                        FINISH
                                    </LoadingButton>
                                    <Button
                                        variant="outlined"
                                        onClick={handleBack}
                                        className="button"
                                    >
                                        BACK
                                    </Button>
                                </Grid>
                            </Grid>
                        </FormControl>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default CreateTradeModal;