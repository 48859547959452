import { useCallback, useContext, useMemo } from "react";

import { isEmpty } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { PaymentStatus } from "components/tasks/constants";
import { AuthContext } from "helpers/auth";
import { CONTINENT_GROUP } from "helpers/regions";
import { selectPaymentList, selectTaskList, selectTaskPageFilters, selectTradeList } from "redux/slices/selectors";
import { setTaskCountPerCountry, setTaskDetail, setTaskList } from "redux/slices/tasksSlice";

const useTaskHelper = () => {
    const dispatch = useDispatch();
    const taskList = useSelector(selectTaskList);
    const tradeList = useSelector(selectTradeList);
    const paymentList = useSelector(selectPaymentList);
    const auth = useContext(AuthContext);
    const serviceProvider = auth?.user?.serviceProvider;

    const pageFilters = useSelector(selectTaskPageFilters);
    const {
        continent: continentFilter,
        country: countryFilter,
        region: regionFilter,
        year: yearFilter,
        quarter: quarterFilter,
        month: monthFilter,
        user: userFilter,
        filingStatus: filingStatusFilter,
        entity: entityFilter,
    } = useMemo(() => pageFilters ?? {}, [pageFilters]);

    const taskListTableData = useMemo(() => {
        const filteredTaskList = taskList.filter((task) => {
            const countryCheck = isEmpty(countryFilter) || task.obligation?.countryCode === countryFilter;
            const filingStatusCheck = isEmpty(filingStatusFilter) || task.status === filingStatusFilter;
            const userCheck = isEmpty(userFilter) || task.assignedUsers?.map((u) => u._id).includes(userFilter);
            const regionCheck = isEmpty(regionFilter) || task.obligation?.geographicalRegionCode === regionFilter;
            const continentCheck = continentFilter === CONTINENT_GROUP.GENERAL || task.obligation?.continentCode === continentFilter;
            const yearCheck = isEmpty(yearFilter) || task.year.toString() === yearFilter;
            const quarterCheck = isEmpty(quarterFilter) || task.quarter === quarterFilter;
            const monthCheck = isEmpty(monthFilter) || task.year === monthFilter;
            const entityCheck = isEmpty(entityFilter) || task.entity?._id === entityFilter;
            const serviceProviderCheck = isEmpty(serviceProvider) || serviceProvider === task.serviceProvider;

            return countryCheck && filingStatusCheck && userCheck && regionCheck && continentCheck && yearCheck && quarterCheck && monthCheck && entityCheck && serviceProviderCheck;
        });
        return filteredTaskList.map((task) => {
            const trade = tradeList?.find((tradeItem) => tradeItem.id === task.tradeId) ?? {};
            const payment = paymentList?.find((paymentItem) => paymentItem.id === task.paymentId) ?? {};
            const { status: tradeStatus, valueDate: paymentDeadline } = trade;

            const { status: paymentStatus } = payment;
            const paymentStatusResult = task.paymentStatus?.length ? task.paymentStatus : paymentStatus;
            return { ...task, tradeStatus, paymentDeadline, paymentStatus: paymentStatusResult };
        });
    }, [continentFilter, countryFilter, entityFilter, filingStatusFilter, monthFilter, paymentList, quarterFilter, regionFilter, serviceProvider, taskList, tradeList, userFilter, yearFilter]);

    const activePaidTaskIds = useMemo(() => (
        taskListTableData.filter((task) => task.paymentStatus === PaymentStatus.Completed && task.isArchived === false).map(({ _id }) => _id)
    ), [taskListTableData]);

    const fetchTaskListData = useCallback(async ({ auth, showArchived = false }) => {
        const taskResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/tasks?` + new URLSearchParams({
            isArchived: showArchived
        }), {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + auth.user.token
            }
        })
        const response = await taskResponse.json();
        dispatch(setTaskList({ taskList: response?.tasks ?? [] }));
        dispatch(setTaskCountPerCountry({ taskCountPerCountry: response?.taskCountPerCountry ?? [] }));
        return response;

    }, [dispatch]);

    const fetchTaskDetail = useCallback(async ({ auth, taskId }) => {
        const taskResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/tasks/${taskId}`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + auth.user.token,
            }
        });
        const response = await taskResponse.json();
        dispatch(setTaskDetail({ taskDetail: response }));
        return response;

    }, [dispatch]);

    const handleArchivePaidTasks = useCallback(async ({ auth }) => {
        if (isEmpty(activePaidTaskIds)) {
            return toast.success('No tasks to archive.', { autoClose: 7500 });
        }
        const taskResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/tasks/archive-paid`, {
            method: "PUT",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + auth.user.token
            },
            body: JSON.stringify({ paidTaskIds: activePaidTaskIds })
        });
        const response = await taskResponse.json();
        if (response.acknowledged) {
            const updatedTaskList = taskList.filter((task) => !activePaidTaskIds.includes(task._id)) ?? [];
            dispatch(setTaskList({ taskList: updatedTaskList }));
            return toast.success(`Successfully archived ${response?.modifiedCount} tasks.`, { autoClose: 7500 });
        }
    }, [activePaidTaskIds, dispatch, taskList]);

    return {
        taskListTableData,
        fetchTaskListData,
        fetchTaskDetail,
        handleArchivePaidTasks,
    };
}

export default useTaskHelper;