import { Message as MessageIcon } from "@mui/icons-material"
import { Box, Button, Divider, Grid, IconButton, Modal, Typography } from "@mui/material"
import ScrollableMention from "components/tasks/messages/ScrollableMention"
import TaskMessageCard from "components/tasks/messages/TaskMessageCard"
import { AuthContext } from "helpers/auth"
import { UsersContext } from "helpers/users"
import { Fragment, useCallback, useContext, useEffect, useState } from "react"

const TaskMessagesModal = ({ task }) => {
  const [open, setOpen] = useState(false)
  const [updated, setUpdated] = useState(0)

  const toggleUpdate = () => setUpdated(!updated)
  const handleOpen = () => { setOpen(true) }
  const handleClose = () => setOpen(false)

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    minHeight: "500px",
    maxHeight: "900px",
    overflow: "scroll"
  }

  const [messageContent, setMessageContent] = useState("")
  const [messageList, setMessageList] = useState([])
  const auth = useContext(AuthContext)
  const users = useContext(UsersContext)

  const handleSubmit = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_PROXY}/api/tasks/${encodeURIComponent(task._id)}/messages`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth?.user?.token
        },
        body: JSON.stringify({
          content: messageContent
        })
      })
      setMessageContent("")
      loadMessages()
    } catch (error) {
      console.log(error)
    }
  }

  const loadMessages = useCallback(async () => {
    if (auth?.user) {
      const fetchMessages = async () => {
        const messageResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/tasks/${encodeURIComponent(task._id)}/messages`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + auth?.user?.token
          }
        })
        const response = await messageResponse.json()
        setMessageList(response)
      }

      fetchMessages()
    }
  }, [auth?.user, task._id])

  useEffect(() => {
    if (open) { loadMessages() }
  }, [updated, loadMessages, open])

  return (
    <Fragment>
      <IconButton variant="contained" onClick={handleOpen}>
        <MessageIcon />
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <Grid container spacing={1}>
            {/* Header */}
            <Grid item xs={12}>
              <Typography variant="body1">{task.name}</Typography>
              <Typography variant="body2">Message thread</Typography>
              <Divider sx={{ p: 1, mb: 1 }} />
            </Grid>

            {/* Message form */}
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {/* Message textbox */}
                <Grid item xs={11}>
                  <ScrollableMention data={users?.usersList} value={messageContent} setValue={setMessageContent} />
                </Grid>

                {/* Submit */}
                <Grid item xs={1}>
                  <Button variant="contained" onClick={handleSubmit} sx={{ width: "100%", height: "100%" }}>Submit</Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}><Divider sx={{ p: 1, mb: 1 }} /></Grid>

            {/* Messages */}
            {messageList.length !== 0 ? messageList.map((message) => (
              <Grid item xs={12} key={message._id}>
                <TaskMessageCard task={task} message={message} actions={{toggleUpdate}} />
              </Grid>
            )) : null}
          </Grid>
        </Box>
      </Modal>
    </Fragment>
  )
}

export default TaskMessagesModal
