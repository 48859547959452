import { Fragment, useState, useContext } from "react"
import { Button, Modal, Box, Divider, TextField, Grid, IconButton } from "@mui/material"
import { Edit as EditIcon, AddCircle as AddCircleIcon } from "@mui/icons-material"
import { ValidatorForm } from "react-material-ui-form-validator"
import { AuthContext } from "helpers/auth"

const NoteModal = ({ task, note, actions }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    actions.toggleUpdate()
    if (!note) { setContent("") }
  }

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  }

  const [content, setContent] = useState(note ? note.content : "")

  const auth = useContext(AuthContext)

  const handleSubmit = async () => {
    try {
      const url = note 
        ? `${process.env.REACT_APP_API_PROXY}/api/tasks/${encodeURIComponent(task._id)}/notes/${encodeURIComponent(note._id)}` 
        : `${process.env.REACT_APP_API_PROXY}/api/tasks/${encodeURIComponent(task._id)}/notes`
      const method = note ? "PUT" : "POST"
      
      await fetch(url, { 
        method: method, 
        credentials: "include",
        headers: { 
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth.user.token
        },
        body: JSON.stringify({ content }) 
      })
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Fragment>
      <IconButton variant="contained" onClick={handleOpen}>
        {note ? <EditIcon /> : <AddCircleIcon />}
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Header */}
              <Grid item xs={12}>
                {note ? "Edit" : "Add"} note
                <Divider sx={{ p: 1 }} />  
              </Grid>

              {/* Subtask name */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Content"
                  value={content}
                  onChange={(e) => setContent(e.target.value)} 
                />
              </Grid>

              {/* Submit */}
              <Grid item xs={12}>
                <Button variant="contained" type="submit">Submit</Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Box>
      </Modal>
    </Fragment>
  )
}

export default NoteModal
