import { Button, Tooltip } from "@mui/material";
import { useMemo } from "react";

const ButtonWithTooltip = ({ tooltipText, disabled, onClick, variant = "contained", ...other }) => {
    const adjustedButtonProps = useMemo(() => ({
        component: disabled ? "div" : undefined,
        onClick: disabled ? undefined : onClick,
    }), [disabled, onClick]);

    return (
        <Tooltip title={tooltipText} arrow placement="top">
            {/* <span> */}
            <Button
                {...other}
                {...adjustedButtonProps}
                disabled={disabled}
                variant={variant}
                sx={{
                    pointerEvents: 'auto !important',
                }}
            />
            {/* </span> */}
        </Tooltip>
    );
};

export default ButtonWithTooltip;
