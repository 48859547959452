export const CREATE_PAYMENT_MODAL_STEPS = [
    {
        label: 'Amount',
        title: 'MAKE PAYMENT FOR',
    },
    {
        label: 'Confirm',
        title: 'REVIEW AND CONFIRM PAYMENT FOR',
    },
];
