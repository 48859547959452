
import { useCallback } from "react";

import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setCustomerList } from "redux/slices/customerSlice";

const useCustomerHelper = () => {
    const dispatch = useDispatch();

    const showErrorSnackbar = (message) => toast.error(message, { autoClose: 3500 });

    const fetchCustomerData = useCallback(
        async ({ auth }) => {
            try {
                const customerResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/customers`, {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + auth.user.token
                    },
                });
                const customers = await customerResponse.json();

                if (customers) {
                    dispatch(setCustomerList({ customerList: customers }));
                } else {
                    showErrorSnackbar("Encountered an error: Failed to fetch customers data.");
                }
            } catch (error) {
                showErrorSnackbar(`Encountered an error: ${error.message}`);
            }
        }, [dispatch]
    );

    return {
        fetchCustomerData,
    };
};

export default useCustomerHelper;
