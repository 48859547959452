import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography
} from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import DeleteDialog from "components/common/DeleteDialog"
import UserModal from "components/settings/UserModal"
import { AuthContext } from "helpers/auth"
import { SnackbarContext } from "helpers/snackbar"
import { Fragment, useContext, useEffect, useState } from "react"

import useCustomerHelper from "hooks/useCustomerHelper"
import { isEmpty } from "lodash-es"
import { toast } from "react-toastify"
import "../common/StyledTable/styles.css"
import TwoFactorAuthModal from "./TwoFactorAuthModal"

const SettingsPage = () => {
  const auth = useContext(AuthContext)
  const snackbar = useContext(SnackbarContext)
  const [usersList, setUsersList] = useState([])
  const [updated, setUpdated] = useState(0)
  const [userSettings, setUserSettings] = useState({})
  const [twoFactorAuthModalProps, setTwoFactorAuthModalProps] = useState({});
  const toggleUpdate = () => setUpdated(!updated)
  const { fetchCustomerData } = useCustomerHelper();

  const usersColumns = [
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      minWidth: 100,
      renderCell: (params) => (
        <Fragment>
          <UserModal user={params.row} actions={{ toggleUpdate }} />
          <DeleteDialog objectName={"user"} deleteFunction={() => deleteUser(params.row)} />
        </Fragment>
      ),
      headerClassName: "styled-header",
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: 300,
      flex: 1,
      renderCell: (params) => {
        return `${params.row.firstname} ${params.row.lastname}${auth.user.email === params.row.email ? " (You)" : ""}`
      },
      headerClassName: "styled-header",
    },
    { field: "email", headerName: "Email", minWidth: 400, headerClassName: "styled-header", },
    {
      field: "serviceProvider",
      headerName: "Service Provider",
      headerClassName: "styled-header",
      align: "center",
      headerAlign: "center",
      minWidth: 250,
      renderCell: (params) => params.row?.serviceProvider ?? '-',
  },
    {
      field: "isAdmin",
      headerName: "Admin",
      minWidth: 200,
      headerClassName: "styled-header",
      renderCell: (params) => (
        params.row.isAdmin ? 'Yes' : 'No'
      ),
    },
    {
      field: "otpEnabled",
      headerName: "2FA Enabled",
      sortable: false,
      minWidth: 150,
      renderCell: (params) => (
        params.row.otpEnabled ? 'Yes' : 'No'
      ),
      headerClassName: "styled-header",
    },
  ]

  const deleteUser = async (user) => {
    await fetch(`${process.env.REACT_APP_API_PROXY}/api/users/${encodeURIComponent(user._id)}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth.user.token
      }
    })
    setUsersList((usersList) => usersList.filter((u) => u._id !== user._id))
  }

  const saveSettings = async () => {
    try {
      const userResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/users/my-settings`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth.user.token
        },
        body: JSON.stringify(userSettings)
      })

      if (userResponse.status === 200) {
        const response = await userResponse.json()
        auth.updateUserSettings(response.userSettings)
        snackbar.setMessage({ message: "Settings successfully updated!", severity: "success" })
      }
    } catch (error) {
      console.log(error)
      snackbar.setMessage({ message: error.message, severity: "error" })
    }
  }

  const generateQR = async () => {
    try {
      const userResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/users/2fa/generate/${encodeURIComponent(auth?.user?._id)}`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth.user.token
        },
      })

      if (userResponse.status === 200) {
        const response = await userResponse.json();
        setTwoFactorAuthModalProps(response);
      }
    } catch (error) {
      console.log(error)
      snackbar.setMessage({ message: error.message, severity: "error" })
    }
  }

  const disable2FA = async () => {
    try {
      const userResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/users/2fa/disable/${encodeURIComponent(auth?.user?._id)}`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth.user.token
        },
      })

      if (userResponse.status === 200) {
        const response = await userResponse.json();
        toast.success(response.message, { autoClose: 7500 });
        auth.updateUser({
          otpEnabled: response.otpEnabled,
          otpVerified: response.otpVerified,
          otpBase32: undefined,
          otpAuthUrl: undefined,
        });
      }
    } catch (error) {
      console.log(error)
      snackbar.setMessage({ message: error.message, severity: "error" })
    }
  }

  useEffect(() => {
    if (auth.user) {
      const fetchUserData = async () => {
        const userListResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/users`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + auth.user.token
          }
        })
        const response = await userListResponse.json()
        setUsersList(response)
      }

      fetchUserData()
      setUserSettings(auth.user.userSettings)
      fetchCustomerData({ auth })
    }
  }, [updated, auth, fetchCustomerData])

  return (
    <Grid container spacing={3}>
      {/* Header */}
      <Grid item xs={12}>
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", flexWrap: "wrap", m: -1 }}>
          <Typography sx={{ m: 1 }} variant="h4">Settings</Typography>
        </Box>
      </Grid>

      {/* 2FA section */}
      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xs={9}>
                <Typography color="textPrimary" gutterBottom variant="h6" sx={{ pb: 1 }}>
                  Mobile App Authentication (2FA)
                </Typography>
                <Typography color="textPrimary">
                  Secure your account with two-factor authentication.
                </Typography>
              </Grid>
              <Grid
                item xs={3}
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}
              >
                {auth?.user?.otpVerified ? (
                  <Button variant="contained" color="error" onClick={disable2FA}>DISABLE 2FA</Button>
                ) : (
                  <Button variant="contained" onClick={generateQR}>ACTIVATE 2FA</Button>
                )}
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        {!isEmpty(twoFactorAuthModalProps) && (
          <TwoFactorAuthModal
            {...twoFactorAuthModalProps}
            onClose={() => setTwoFactorAuthModalProps({})}
          />
        )}
      </Grid>

      {/* Account Settings */}
      <Grid item xs={12}>
        <Card>
          <CardHeader subheader="Manage settings for your own account" title="User Preferences" />
          <Divider />

          {/* Main settings part */}
          <CardContent>
            <Grid container spacing={12} wrap="wrap">
              {/* First page */}
              <Grid item sx={{ display: "flex", flexDirection: "column" }} xs={6}>
                <Typography color="textPrimary" gutterBottom variant="h6" sx={{ pb: 1 }}>
                  First page after logging in
                </Typography>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  value={userSettings?.firstPage || "/filings"}
                  onChange={(e) => setUserSettings({...userSettings, firstPage: e.target.value})}
                >
                  {/* <MenuItem key={"/filings"} value={"/filings"}>Filings</MenuItem> */}
                  <MenuItem key={"/returns"} value={"/returns"}>VAT Analysis</MenuItem>
                  <MenuItem key={"/tasks"} value={"/tasks"}>Compliance Tracker</MenuItem>
                  <MenuItem key={"/obligations"} value={"/obligations"}>Obligations</MenuItem>
                  <MenuItem key={"/projects"} value={"/projects"}>Projects</MenuItem>
                  {/* <MenuItem key={"/customers"} value={"/customers"}>Directory</MenuItem> */}
                </TextField>
              </Grid>

              {/* Notifications */}
              <Grid item sx={{ display: "flex", flexDirection: "column" }} xs={6}>
                <Typography color="textPrimary" gutterBottom variant="h6" sx={{ pb: 1 }}>
                  Notifications
                </Typography>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="primary"
                      checked={userSettings?.notifyOnMention || false}
                      onChange={(e) => setUserSettings({...userSettings, notifyOnMention: e.target.checked})}
                    />
                  )}
                  label="Send me an email when I am mentioned in a message"
                />
              </Grid>
            </Grid>
          </CardContent>

          <Divider />
          <Box sx={{ display: "flex", justifyContent: "flex-end", p: 2 }}>
            <Button color="primary" variant="contained" onClick={saveSettings}>Save</Button>
          </Box>
        </Card>
      </Grid>

      {/* Users */}
      {auth?.user?.isAdmin && (
        <>
          <Grid item xs={12}>
            <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", flexWrap: "wrap", m: -1 }}>
              <Typography sx={{ m: 1 }} variant="h5">Users</Typography>
              <Box sx={{ m: 1 }}>
                <UserModal actions={{ toggleUpdate }} />
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sx={{ height: 550 }}>
            <DataGrid
              rows={usersList}
              columns={usersColumns}
              getRowId={(row) => row._id}
              disableSelectionOnClick
              getRowClassName={() => "styled-row"}
              sx={{
                '.MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default SettingsPage
