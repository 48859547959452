import { createSlice } from "@reduxjs/toolkit";
import { CONTINENT_GROUP } from "helpers/regions";

export const INITIAL_FILES_PAGE_FILTERS = {
    continent: CONTINENT_GROUP.GENERAL,
    country: '',
    region: '',
    legalEntity: '',
    year: '',
    quarter: '',
    month: '',
};

const initialState = {
    filesList: [],
    pageFilters: INITIAL_FILES_PAGE_FILTERS,
};

const filesSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        setFilesList: (state, action) => {
            state.filesList = action.payload.filesList
        },
        setFilesPageFilters: (state, action) => {
            const currentFilters = state.pageFilters
            const updatedFilters = action.payload.pageFilters
            state.pageFilters = { ...currentFilters, ...updatedFilters }
        },
    }
});

export const {
    setFilesList,
    setFilesPageFilters,
} = filesSlice.actions;

export default filesSlice;