import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { isEmpty } from "lodash-es";

import useMonexApiHelper from "hooks/useMonexApiHelper";
import { selectThirdPartyBankAccountDetail } from "redux/slices/selectors";
import BankAccountsDetailContent from "../BankAccountsDetailContent";


const ThirdPartyAccountsDetail = () => {
    const { id: thirdPartyIdParam } = useParams();
    const thirdPartyBankAccountDetail = useSelector(selectThirdPartyBankAccountDetail);
    const { id: thirdPartyId } = thirdPartyBankAccountDetail;

    const isPageLoading = useMemo(() => (
        isEmpty(thirdPartyBankAccountDetail) || thirdPartyIdParam !== thirdPartyId
    ), [thirdPartyBankAccountDetail, thirdPartyId, thirdPartyIdParam]);

    const { fetchThirdPartyBankAccountDetail } = useMonexApiHelper();

    useEffect(() => {
        if (isPageLoading) {
            fetchThirdPartyBankAccountDetail({ id: thirdPartyIdParam });
        }
      }, [fetchThirdPartyBankAccountDetail, isPageLoading, thirdPartyIdParam]);

    return <BankAccountsDetailContent data={thirdPartyBankAccountDetail} isLoading={isPageLoading} />
};

export default ThirdPartyAccountsDetail;