import { Autocomplete, Box, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, Typography } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import DeleteDialog from "components/common/DeleteDialog"
import ImportModal from "components/files/ImportModal"
import ObligationModal from "components/obligations/ObligationModal"
import TaskModal from "components/tasks/TaskModal"
import { AuthContext } from "helpers/auth"
import { COUNTRY_CODE_TO_NAME } from "helpers/countries"
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react"

import { AddCircle } from "@mui/icons-material"
import { ALL_COUNTRIES, CANADA_PROVINCE_CODE_TO_NAME, CANADA_PROVINCE_LIST, US_STATES_LIST, US_STATE_CODE_TO_NAME } from "helpers/regions"
import useCustomerHelper from "hooks/useCustomerHelper"
import { isEmpty, sortBy } from "lodash-es"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"
import { setActiveCountries } from "redux/slices/commonSlice"
import { setObligationList } from "redux/slices/obligationsSlice"
import { selectActiveCountriesPreference, selectObligationList } from "redux/slices/selectors"
import "../common/StyledTable/styles.css"

const ObligationPage = () => {
  const dispatch = useDispatch();
  const { fetchCustomerData } = useCustomerHelper();

  const activeCountriesPreference = useSelector(selectActiveCountriesPreference);
  const sortedActiveCountriesPreference = activeCountriesPreference && sortBy(activeCountriesPreference, (val) => val);
  const obligationList = useSelector(selectObligationList);

  const [currentCountry, setCurrentCountry] = useState(COUNTRY_CODE_TO_NAME[activeCountriesPreference[0]]);
  const [currentCountryCode, setCurrentCountryCode] = useState(activeCountriesPreference[0]);
  const [currentRegion, setCurrentRegion] = useState('');
  const [updated, setUpdated] = useState(0)
  const [taskModalObligationData, setTaskModalObligationData] = useState({});
  const auth = useContext(AuthContext)
  const toggleUpdate = () => setUpdated(!updated)

  const shouldShowRegion = useMemo(() => currentCountryCode === 'US' || currentCountryCode === 'CA', [currentCountryCode]);
  const regionFilterLabel = useMemo(() => currentCountryCode === 'US' ? 'States' : 'Provinces', [currentCountryCode]);

  const handleAddTaskClick = useCallback((data) => {
    if (shouldShowRegion && isEmpty(currentRegion)) {
      return toast.error(`Please select a state/province for ${currentCountry}`, { autoClose: 5000 });
    }
    if (currentCountry) {
      setTaskModalObligationData(data)
    }
  }, [currentCountry, currentRegion, shouldShowRegion]);

  const obligationColumns = [
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      minWidth: 150,
      renderCell: (params) => (
        <Fragment>
          <IconButton variant="contained" onClick={() => handleAddTaskClick(params.row)}>
            <AddCircle />
          </IconButton>
          <ObligationModal country={currentCountry} geographicalRegionCode={currentRegion} obligation={params.row} actions={{ toggleUpdate }} />
          <DeleteDialog objectName={"obligation"} deleteFunction={() => deleteObligation(params.row)} />
        </Fragment>
      ),
      headerClassName: "styled-header",
    },
    { field: "name", headerName: "Name", minWidth: 200, headerClassName: "styled-header", },
    { field: "prepareOfFile", headerName: "Prepare of File", minWidth: 150, headerClassName: "styled-header", },
    { field: "format", headerName: "Format", minWidth: 200, headerClassName: "styled-header", },
    { field: "deadlineFormat", headerName: "Deadline Format", minWidth: 350, headerClassName: "styled-header", },
    { field: "notificationDeadlineFormat", headerName: "Notification Deadline", minWidth: 180, headerClassName: "styled-header", },
    { field: "threshold", headerName: "Threshold", minWidth: 300, headerClassName: "styled-header", },
    { field: "localLanguage", headerName: "Local Language", flex: 1, headerClassName: "styled-header", }
  ]

  const deleteObligation = async (obligation) => {
    await fetch(`${process.env.REACT_APP_API_PROXY}/api/obligations/${encodeURIComponent(obligation._id)}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth?.user?.token
      }
    })
    const updatedObligationList = obligationList.filter((o) => o._id !== obligation._id);
    dispatch(setObligationList({ obligationList: updatedObligationList }));
  }

  useEffect(() => {
    if (auth?.user) {
      const fetchObligationData = async () => {
        let url = `${process.env.REACT_APP_API_PROXY}/api/obligations?country=${encodeURIComponent(currentCountry)}`;
        if (shouldShowRegion && !isEmpty(currentRegion)) {
          url = `${url}&geographicalRegionCode=${currentRegion}`;
        }
        const obligationResponse = await fetch(url, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + auth?.user?.token
          }
        })
        const obligations = await obligationResponse.json()
        if (obligations) {
          dispatch(setObligationList({ obligationList: obligations }));
        }
      }

      fetchObligationData()
      fetchCustomerData({ auth });
    }
  }, [currentCountry, updated, auth, shouldShowRegion, currentRegion, dispatch])

  return (
    <Grid container spacing={3}>
      {/* Header and add button */}
      <Grid item xs={12}>
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", flexWrap: "wrap", m: -1 }}>
          <Typography sx={{ m: 1 }} variant="h4">Obligations</Typography>
          <Box sx={{ m: 1 }}>
            <ImportModal actions={{ toggleUpdate }} />
            <ObligationModal country={currentCountry} geographicalRegionCode={currentRegion} actions={{ toggleUpdate }} />
          </Box>
        </Box>
      </Grid>

      {/* Active Country select */}
      <Grid item xs={4}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", height: "100%" }}>
          <FormControl>
            <InputLabel shrink>Selected Country</InputLabel>
            <Select label="Selected Country" value={currentCountryCode} onChange={(e) => {
              const countryCode = e.target.value;
              const countryName = COUNTRY_CODE_TO_NAME[countryCode];
              if (!(countryCode === 'US' || countryCode === 'CA')) {
                setCurrentRegion('');
              }
              setCurrentCountry(countryName);
              setCurrentCountryCode(countryCode);
            }}>
              {sortedActiveCountriesPreference.map(countryCode => {
                return (
                  <MenuItem key={countryCode} value={countryCode}>{COUNTRY_CODE_TO_NAME[countryCode]}</MenuItem>
                )
              })}
            </Select>
          </FormControl>
        </Paper>
      </Grid>

      {/* Region select */}
      {shouldShowRegion && (
        <Grid item xs={4}>
          <Paper sx={{ p: 2, display: "flex", flexDirection: "column", height: "100%" }}>
            <FormControl>
              <InputLabel shrink>{regionFilterLabel}</InputLabel>
              <Select
                  notched
                  label={regionFilterLabel}
                  value={currentRegion}
                  onChange={(e) => setCurrentRegion(e.target.value)}
              >
                {currentCountryCode === 'US' && US_STATES_LIST.map((stateCode) => {
                  const stateName = US_STATE_CODE_TO_NAME[stateCode];
                  return (
                      <MenuItem key={stateCode} value={stateCode}>
                          {stateName}
                      </MenuItem>
                  );
                })}
                {currentCountryCode === 'CA' && CANADA_PROVINCE_LIST.map((provinceCode) => {
                  const provinceName = CANADA_PROVINCE_CODE_TO_NAME[provinceCode];
                  return (
                      <MenuItem key={provinceCode} value={provinceCode}>
                          {provinceName}
                      </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Paper>
        </Grid>
      )}

      {/* Preferred Country select */}
      <Grid item xs={4}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", height: "100%" }}>
        <Autocomplete
          value={activeCountriesPreference}
          onChange={(event, newValue) => {
            dispatch(setActiveCountries({ activeCountries: newValue }));
            if (!newValue.includes(currentCountryCode)) {
              setCurrentCountryCode(newValue[0]);
              setCurrentCountry(COUNTRY_CODE_TO_NAME[newValue[0]]);
            }
          }}
          multiple
          limitTags={2}
          id="preferred-countries"
          options={ALL_COUNTRIES}
          getOptionLabel={(option) => COUNTRY_CODE_TO_NAME[option]}
          disableClearable
          renderInput={(params) => (
            <TextField {...params} label="Preferred Countries" placeholder="Select country" />
          )}
        />
        </Paper>
      </Grid>

      {/* Obligations per country */}
      {obligationList && (<Grid item xs={12} sx={{ height: 550 }}>
        <DataGrid
          rows={obligationList}
          columns={obligationColumns}
          getRowId={(row) => row._id}
          disableSelectionOnClick
          getRowClassName={() => "styled-row"}
          sx={{
            '.MuiDataGrid-columnSeparator': {
              display: 'none',
            },
          }}
        />
      </Grid>)}

      {!isEmpty(taskModalObligationData) && (
        <TaskModal
          obligation={taskModalObligationData}
          onClose={() => setTaskModalObligationData({})}
        />
      )}
    </Grid>
  )
}

export default ObligationPage
