import { useCallback, useMemo } from "react";

import { isEmpty } from "lodash-es";
import { useDispatch, useSelector } from "react-redux";

import { CONTINENT_GROUP, CONTINENT_GROUP_COUNTRIES_MAPPING } from "helpers/regions";
import { setFilingList } from "redux/slices/filingsSlice";
import { selectFilingList, selectFilingPageFilters } from "redux/slices/selectors";

const useFilingHelper = () => {
    const dispatch = useDispatch();
    const filingList = useSelector(selectFilingList);

    const pageFilters = useSelector(selectFilingPageFilters);
    const {
        continent: continentFilter,
        country: countryFilter,
        region: regionFilter,
        filingType: filingTypeFilter,
        legalEntity: legalEntityFilter,
        year: yearFilter,
        quarter: quarterFilter,
        month: monthFilter,
        filingStatus: filingStatusFilter,
    } = useMemo(() => pageFilters ?? {}, [pageFilters]);

    const filingListTableData = useMemo(() => {
        const filteredFilingList = filingList.filter((filing) => {
            const continentCheck = continentFilter === CONTINENT_GROUP.GENERAL || CONTINENT_GROUP_COUNTRIES_MAPPING[continentFilter].includes(filing.countryCode);
            const countryCheck = isEmpty(countryFilter) || filing.countryCode === countryFilter;
            const regionCheck = isEmpty(regionFilter) || filing.geographicalRegionCode === regionFilter;
            const filingTypeCheck = isEmpty(filingTypeFilter) || filing.filingType === filingTypeFilter;
            const legalEntityCheck = isEmpty(legalEntityFilter); // TODO: Add legal entity handling
            const yearCheck = isEmpty(yearFilter) || filing.year === yearFilter;
            const quarterCheck = isEmpty(quarterFilter) || filing.year === quarterFilter;
            const monthCheck = isEmpty(monthFilter) || filing.year === monthFilter;
            const filingStatusCheck = isEmpty(filingStatusFilter) || filing.status === filingStatusFilter;

            return continentCheck && countryCheck && regionCheck && filingTypeCheck && legalEntityCheck && yearCheck && quarterCheck && monthCheck && filingStatusCheck;
        });
        return filteredFilingList;
    }, [continentFilter, countryFilter, filingList, filingStatusFilter, filingTypeFilter, legalEntityFilter, monthFilter, quarterFilter, regionFilter, yearFilter]);

    const fetchFilingListData = useCallback(async ({ auth }) => {
        const filingsResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/filings`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + auth.user.token
            }
        })
        const response = await filingsResponse.json();
        dispatch(setFilingList({ filingList: response ?? [] }));
        return response;

    }, [dispatch]);

    const deleteFiling = useCallback(async ({ auth, filingId }) => {
        await fetch(`${process.env.REACT_APP_API_PROXY}/api/filings/${encodeURIComponent(filingId)}`, {
            method: "DELETE",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + auth.user.token
            }
        });
        const updatedFilingList = filingList.filter((filing) => filing._id !== filingId);
        dispatch(setFilingList({ filingList: updatedFilingList }));
    }, [dispatch, filingList]);

    return {
        filingListTableData,
        fetchFilingListData,
        deleteFiling,
    };
}

export default useFilingHelper;