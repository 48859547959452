export const FilingStatus = {
    Approved: 'Approved',
    Prepared: 'Prepared',
    Initiated: 'Initiated',
    Submitted: 'Submitted',
    Paid: 'Paid',
    Confirmed: 'Confirmed',
    NotStarted: 'Not Started',
};

export const FilingStatusOrder = [
    FilingStatus.Initiated,
    FilingStatus.Prepared,
    FilingStatus.Approved,
    FilingStatus.Submitted,
    FilingStatus.Paid,
    FilingStatus.Confirmed,
    FilingStatus.NotStarted,
]

export const FilingStatusColorMapping = {
    [FilingStatus.Initiated]: '#FFDB58',
    [FilingStatus.Prepared]: '#EED202',
    [FilingStatus.Approved]: '#FFA500',
    [FilingStatus.Submitted]: '#00d4cd',
    [FilingStatus.Paid]: '#00bbb5',
    [FilingStatus.Confirmed]: '#00a19c',
    [FilingStatus.NotStarted]: '#ff0000',
}

export const FilingStatusLabelMapping = {
    [FilingStatus.Initiated]: 'Initiated',
    [FilingStatus.Prepared]: 'Prepared',
    [FilingStatus.Approved]: 'Approved',
    [FilingStatus.Submitted]: 'Submitted',
    [FilingStatus.Paid]: 'Paid',
    [FilingStatus.Confirmed]: 'Confirmed',
    [FilingStatus.NotStarted]: 'Not Started',
}

export const FilingStatusLegendMapping = {
    [FilingStatus.Initiated]: 'Request sent out for delivery of information.',
    [FilingStatus.Prepared]: 'Filing documentation in progress.',
    [FilingStatus.Approved]: 'Management to approve return.',
    [FilingStatus.Submitted]: 'Filing submitted to relevant authorities.',
    [FilingStatus.Paid]: 'Tax payment made to authorities.',
    [FilingStatus.Confirmed]: 'Received confirmation from authorities that everything is in order.',
    [FilingStatus.NotStarted]: 'No filing has been started.',
}

export const FilingType = {
    VAT: 'VAT',
    AnnualAccounts: 'Annual Accounts',
    CorporateIncomeTax: 'Corporate Income Tax',
}

export const FilingTypeOrder = [
    FilingType.VAT,
    FilingType.AnnualAccounts,
    FilingType.CorporateIncomeTax,
];