import { AddCircle as AddCircleIcon, Edit as EditIcon } from "@mui/icons-material"
import { Box, Button, Divider, Grid, IconButton, MenuItem, Modal, TextField } from "@mui/material"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import UserSelect from "components/common/UserSelect"
import { AuthContext } from "helpers/auth"
import moment from "moment"
import { Fragment, useContext, useState } from "react"
import { ValidatorForm } from "react-material-ui-form-validator"

const STATUSES = ["Pending", "Done"]

const SubtaskModal = ({ task, subtask, actions }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    if (task) {
      // Editing subtask in task page
      actions.toggleUpdate()
    }
    if (!subtask) {
      setName("")
      setStatus("")
      setDeadlineDate(moment(new Date()).format("YYYY-MM-DD"))
      setAssignedUsers([])
    }
  }

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  }

  const [name, setName] = useState(subtask ? subtask.name : "")
  const [status, setStatus] = useState(subtask ? subtask.status : "")
  const [deadlineDate, setDeadlineDate] = useState(subtask ? subtask.deadlineDate : moment(new Date()).format("YYYY-MM-DD"))
  const [assignedUsers, setAssignedUsers] = useState(subtask ? subtask.assignedUsers : [])

  const auth = useContext(AuthContext)

  const handleSubmit = async () => {
    try {
      const url = subtask
        ? `${process.env.REACT_APP_API_PROXY}/api/tasks/${encodeURIComponent(task._id)}/subtasks/${encodeURIComponent(subtask._id)}`
        : `${process.env.REACT_APP_API_PROXY}/api/tasks/${encodeURIComponent(task._id)}/subtasks`
      const method = subtask ? "PUT" : "POST"

      await fetch(url, {
        method: method,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth.user.token
        },
        body: JSON.stringify({ name, status, deadlineDate, assignedUsers })
      })
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Fragment>
      <IconButton variant="contained" onClick={handleOpen}>
        {subtask ? <EditIcon /> : <AddCircleIcon />}
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Header */}
              <Grid item xs={12}>
                {subtask ? "Edit" : "Add"} subtask
                <Divider sx={{ p: 1 }} />
              </Grid>

              {/* Subtask name */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Subtask Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>

              {/* Deadline date */}
              <Grid item xs={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label="Deadline Date"
                    value={deadlineDate}
                    onChange={(deadlineDate) => {setDeadlineDate(deadlineDate)}}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </LocalizationProvider>
              </Grid>

              {/* Status */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  select
                  variant="outlined"
                  label="Status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  {STATUSES.map(status => (
                    <MenuItem key={status} value={status}>{status}</MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* Assigned users */}
              <Grid item xs={12}>
                <UserSelect assignedUsers={assignedUsers} setAssignedUsers={setAssignedUsers} label="Ultimate Responsible Party" />
              </Grid>

              {/* Submit */}
              <Grid item xs={12}>
                <Button variant="contained" type="submit">Submit</Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Box>
      </Modal>
    </Fragment>
  )
}

export default SubtaskModal
