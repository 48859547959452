import { AuthContext } from "helpers/auth";
import { CONTINENT_GROUP, CONTINENT_GROUP_COUNTRIES_MAPPING } from "helpers/regions";
import { isEmpty } from "lodash-es";
import { useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setFilesList } from "redux/slices/filesSlice";
import { selectFilesList, selectFilesPageFilters } from "redux/slices/selectors";

const useFilesHelper = () => {
    const auth = useContext(AuthContext);
    const dispatch = useDispatch();

    const filesList = useSelector(selectFilesList);
    const pageFilters = useSelector(selectFilesPageFilters);

    const {
        continent: continentFilter,
        country: countryFilter,
        region: regionFilter,
        legalEntity: legalEntityFilter,
        year: yearFilter,
        quarter: quarterFilter,
        month: monthFilter,
    } = useMemo(() => pageFilters ?? {}, [pageFilters]);

    const filteredFilesTableData = useMemo(() => {
        const filteredFilesList = filesList.filter((file) => {
            const continentCheck = continentFilter === CONTINENT_GROUP.GENERAL || CONTINENT_GROUP_COUNTRIES_MAPPING[continentFilter].includes(file.countryCode);
            const countryCheck = isEmpty(countryFilter) || file.countryCode === countryFilter;
            const regionCheck = isEmpty(regionFilter) || file.geographicalRegionCode === regionFilter;
            const legalEntityCheck = isEmpty(legalEntityFilter) || file.entity?._id === legalEntityFilter;
            const yearCheck = isEmpty(yearFilter) || file.year === yearFilter;
            const quarterCheck = isEmpty(quarterFilter) || file.quarter === quarterFilter;
            const monthCheck = isEmpty(monthFilter) || file.year === monthFilter;

            return continentCheck && countryCheck && regionCheck && legalEntityCheck && yearCheck && quarterCheck && monthCheck;
        });
        return filteredFilesList;
    }, [continentFilter, countryFilter, filesList, legalEntityFilter, monthFilter, quarterFilter, regionFilter, yearFilter]);

    const handleFetchFilesList = async ({ filingId }) => {
        try {
            let url = `${process.env.REACT_APP_API_PROXY}/api/files`;

            if (filingId) {
                url = `${process.env.REACT_APP_API_PROXY}/api/files?filing_id=${encodeURIComponent(filingId)}`;
            }

            const fileResponse = await fetch(url, {
                method: "GET",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + auth?.user?.token
                }
            });
            const files = await fileResponse.json();
            dispatch(setFilesList({ filesList: files }));
        } catch (error) {
            toast.error(`Encountered error: ${error.message}`, { autoClose: 5000 });
        }
    }

    const handleUploadFiles = async ({ files = [], props = {}, onSuccessCallback }) => {
        await Promise.all(files.map(async (file) => {
            const getUploadURLResponse =
                await fetch(`${process.env.REACT_APP_API_PROXY}/api/files/get-upload-url`,
                    {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + auth?.user?.token,
                        }
                    });

            const response = (await getUploadURLResponse.json());
            const s3UploadResponse = await fetch(response.uploadURL, {
                method: "PUT",
                headers: { "Content-Type": "multipart/form-data" },
                body: file,
            });

            if (s3UploadResponse.status === 200) {
                const s3Key = response.s3Key;
                const filename = file.name;
                const body = { filename, s3Key, ...props };

                await fetch(`${process.env.REACT_APP_API_PROXY}/api/files`, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer " + auth?.user?.token,
                    },
                    body: JSON.stringify(body),
                });

                onSuccessCallback?.();
            }
        }))
    };

    const handleDownloadFile = async (file) => {
        const getFileData = await fetch(
            `${process.env.REACT_APP_API_PROXY}/api/files/${encodeURIComponent(file._id)}`, {
            method: "GET",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                "Authorization": "Bearer " + auth?.user?.token
            }
        }
        );

        const downloadURL = (await getFileData.json()).downloadURL;
        window.open(downloadURL);
    };

    const handleDeleteFile = async (file, onDelete) => {
        try {
            await fetch(`${process.env.REACT_APP_API_PROXY}/api/files/${encodeURIComponent(file._id)}`, {
                method: "DELETE",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + auth?.user?.token,
                },
            });

            onDelete?.();
            toast.success("Successfully deleted file.", { autoClose: 5000 });
        } catch (error) {
            toast.error(`Encountered error: ${error.message}`, { autoClose: 5000 });
        }
    };

    return {
        filteredFilesTableData,
        handleFetchFilesList,
        handleDeleteFile,
        handleDownloadFile,
        handleUploadFiles,
    };
};

export default useFilesHelper;
