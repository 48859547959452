import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";

import { setBalanceList, setOwnBankAccountDetail, setOwnBankAccountList, setPaymentDetail, setPaymentList, setQuote, setThirdPartyBankAccountDetail, setThirdPartyBankAccountList, setTradeDetail, setTradeList } from "redux/slices/monexSlice";
import { selectTaskList } from "redux/slices/selectors";
import { setTaskList } from "redux/slices/tasksSlice";

const useMonexApiHelper = () => {
    const dispatch = useDispatch();
    const taskList = useSelector(selectTaskList);

    //// QUOTES
    const fetchQuote = useCallback(async (formData) => {
        const quote = await axios({
            method: 'post',
            url: process.env.REACT_APP_API_PROXY + '/api/monex/quotes',
            data: formData,
        });
        dispatch(setQuote({ quote: quote.data }))
        return quote.data;
    }, [dispatch]);

    //// TRADES
    const postTrade = useCallback(async ({ quoteId, task }) => {
        const response = await axios({
            method: 'post',
            url: process.env.REACT_APP_API_PROXY + '/api/monex/trades',
            data: { quoteId, task },
        });
        const updatedTask = response.data;
        const updatedTaskList = taskList.map((task) => {
            if (task._id === updatedTask._id) {
                return { ...task, tradeId: updatedTask.tradeId };
            }
            return task;
        });
        dispatch(setTaskList({ taskList: updatedTaskList }))
        return updatedTask;
    }, [dispatch, taskList]);

    const fetchTradeList = useCallback(async () => {
        const tradeList = await axios({
            method: 'get',
            url: process.env.REACT_APP_API_PROXY + '/api/monex/trades',
        });
        dispatch(setTradeList({ tradeList: tradeList.data.items }));
    }, [dispatch]);

    const fetchTradeDetail = useCallback(async ({ tradeId }) => {
        const tradeDetail = await axios({
            method: 'get',
            url: process.env.REACT_APP_API_PROXY + `/api/monex/trades/${tradeId}`,
        });
        dispatch(setTradeDetail({ tradeDetail: tradeDetail.data }));
        return tradeDetail.data;
    }, [dispatch]);

    //// THIRD PARTY
    const fetchThirdPartyBankAccountList = useCallback(async () => {
        const thirdPartyBankAccountList = await axios({
            method: 'get',
            url: process.env.REACT_APP_API_PROXY + '/api/monex/bank-accounts/third-party',
        });
        dispatch(setThirdPartyBankAccountList({ thirdPartyBankAccountList: thirdPartyBankAccountList.data.items }));
        return thirdPartyBankAccountList.data;
    }, [dispatch]);

    const fetchThirdPartyBankAccountDetail = useCallback(async ({ id }) => {
        const thirdPartyBankAccountDetail = await axios({
            method: 'get',
            url: process.env.REACT_APP_API_PROXY + `/api/monex/bank-accounts/third-party/${id}`,
        });
        dispatch(setThirdPartyBankAccountDetail({ thirdPartyBankAccountDetail: thirdPartyBankAccountDetail.data }));
        return thirdPartyBankAccountDetail.data;
    }, [dispatch]);

    //// THIRD PARTY
    const fetchOwnBankAccountList = useCallback(async () => {
        const ownBankAccountList = await axios({
            method: 'get',
            url: process.env.REACT_APP_API_PROXY + '/api/monex/bank-accounts/own',
        });
        dispatch(setOwnBankAccountList({ ownBankAccountList: ownBankAccountList.data.items }));
        return ownBankAccountList.data;
    }, [dispatch]);

    const fetchOwnBankAccountDetail = useCallback(async ({ id }) => {
        const ownBankAccountDetail = await axios({
            method: 'get',
            url: process.env.REACT_APP_API_PROXY + `/api/monex/bank-accounts/own/${id}`,
        });
        dispatch(setOwnBankAccountDetail({ ownBankAccountDetail: ownBankAccountDetail.data }));
        return ownBankAccountDetail.data;
    }, [dispatch]);

    //// ACCOUNTING
    const fetchBalanceList = useCallback(async ({ date }) => {
        const balanceList = await axios({
            method: 'get',
            url: process.env.REACT_APP_API_PROXY + `/api/monex/accounting/${date}`,
        });
        dispatch(setBalanceList({ balanceList: balanceList.data.items }));
        return balanceList.data;
    }, [dispatch]);

    //// PAYMENTS
    const postPayment = useCallback(async ({ formData, task }) => {
        const response = await axios({
            method: 'post',
            url: process.env.REACT_APP_API_PROXY + '/api/monex/payments/out',
            data: { formData, task },
        });
        const updatedTask = response.data;
        const updatedTaskList = taskList.map((task) => {
            if (task._id === updatedTask._id) {
                return { ...task, paymentId: updatedTask.paymentId };
            }
            return task;
        });
        dispatch(setTaskList({ taskList: updatedTaskList }));
        return updatedTask;
    }, [dispatch, taskList]);

    const fetchPaymentList = useCallback(async () => {
        const paymentList = await axios({
            method: 'get',
            url: process.env.REACT_APP_API_PROXY + '/api/monex/payments',
        });
        dispatch(setPaymentList({ paymentList: paymentList.data.items }));
    }, [dispatch]);

    const fetchPaymentDetail = useCallback(async ({ paymentId }) => {
        const paymentDetail = await axios({
            method: 'get',
            url: process.env.REACT_APP_API_PROXY + `/api/monex/payments/${paymentId}`,
        });
        dispatch(setPaymentDetail({ paymentDetail: paymentDetail.data }));
        return paymentDetail.data;
    }, [dispatch]);

    return {
        fetchQuote,
        fetchTradeList,
        fetchTradeDetail,
        fetchThirdPartyBankAccountDetail,
        fetchThirdPartyBankAccountList,
        fetchOwnBankAccountList,
        fetchOwnBankAccountDetail,
        fetchBalanceList,
        fetchPaymentList,
        fetchPaymentDetail,
        postTrade,
        postPayment,
    };
};

export default useMonexApiHelper;