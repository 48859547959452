import { useCallback, useState } from "react";

const useStepperHelper = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());

    const isStepSkipped = useCallback((step) => {
        return skipped.has(step);
    }, [skipped]);

    const handleNext = useCallback(() => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    }, [activeStep, isStepSkipped, skipped]);

    const handleBack = useCallback(() => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }, []);

    return {
        activeStep,
        isStepSkipped,
        handleNext,
        handleBack,
    };
};

export default useStepperHelper;