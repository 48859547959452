import { CircularProgress } from "@mui/material";

import './styles.css';

const TableLoader = () => {
    return (
        <div className="loader-root">
            <CircularProgress size={100} />
        </div>
    )
}

export default TableLoader;
