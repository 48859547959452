
import { Chip } from "@mui/material";
import { PaymentStatusColorMapping, PaymentStatusLabelMapping } from "./constants";

const PaymentStatusPill = ({ status }) => {
    const label = PaymentStatusLabelMapping[status];

    if (!Boolean(status)) {
        return '-';
    }

    return (
        <Chip
            sx={{
                minWidth: "100px",
                background: PaymentStatusColorMapping[status],
                color: 'white',
                fontWeight: 600,
            }}
            label={label}
        />
    );
}

export default PaymentStatusPill;
