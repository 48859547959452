import { Navigate, Route, BrowserRouter as Router, Routes } from "react-router-dom"
import { ToastContainer } from 'react-toastify'

import OwnAccountsDetail from "components/beneficiaries/OwnAccountsDetail"
import OwnAccountsPage from "components/beneficiaries/OwnAccountsPage"
import ThirdPartyAccountsDetail from "components/beneficiaries/ThirdPartyAccountsDetail"
import ThirdPartyAccountsPage from "components/beneficiaries/ThirdPartyAccountsPage"
import CustomerPage from "components/customers/CustomerPage"
import EntityPage from "components/entities/EntityPage"
import FilePage from "components/files/FilePage"
import AppLayout from "components/layout/AppLayout"
import LoginPage from "components/layout/LoginPage"
import ObligationPage from "components/obligations/ObligationPage"
import PaymentDetail from "components/payments/PaymentDetail"
import PaymentPage from "components/payments/PaymentPage"
import ProjectDetail from "components/projects/ProjectDetail"
import ProjectPage from "components/projects/ProjectPage"
import PropertyPage from "components/properties/PropertyPage"
import ResidencePage from "components/residences/ResidencePage"
import ReturnsPage from "components/returns/ReturnsPage"
import SettingsPage from "components/settings/SettingsPage"
import TaskDetail from "components/tasks/TaskDetail"
import TaskPage from "components/tasks/TaskPage"
import TradeDetail from "components/trades/TradeDetail"
import TradePage from "components/trades/TradePage"

import { AuthProvider, RequireAuth } from "helpers/auth"
import { SnackbarProvider } from "helpers/snackbar"
import { UsersProvider } from "helpers/users"

import 'react-toastify/dist/ReactToastify.css'
import "./App.css"

const App = () => {
  return (
    <AuthProvider>
      <UsersProvider>
        <SnackbarProvider>
        <ToastContainer></ToastContainer>
          <Router>
            <Routes>
              <Route>
                <Route path="/login" element={<LoginPage />} />
              </Route>
              <Route element={<AppLayout />}>
                <Route path="*" element={<Navigate to="/filings" replace />} />
                <Route path="/tasks">
                  <Route path="" element={<RequireAuth><TaskPage /></RequireAuth>} />
                  <Route path=":id" element={<RequireAuth><TaskDetail /></RequireAuth>} />
                </Route>
                <Route path="/obligations" element={<RequireAuth><ObligationPage /></RequireAuth>} />
                <Route path="/projects">
                  <Route path="" element={<RequireAuth><ProjectPage /></RequireAuth>} />
                  <Route path=":id" element={<RequireAuth><ProjectDetail /></RequireAuth>} />
                </Route>
                <Route path="/entities" element={<RequireAuth><EntityPage /></RequireAuth>} />
                <Route path="/residences" element={<RequireAuth><ResidencePage /></RequireAuth>} />
                <Route path="/properties" element={<RequireAuth><PropertyPage /></RequireAuth>} />
                <Route path="/returns" element={<RequireAuth><ReturnsPage /></RequireAuth>} />
                {/* <Route path="/filings" element={<RequireAuth><FilingsPage /></RequireAuth>} /> */}
                <Route path="/filings" element={<RequireAuth><TaskPage /></RequireAuth>} />
                <Route path="/customers" element={<RequireAuth><CustomerPage /></RequireAuth>} />
                <Route path="/files" element={<RequireAuth><FilePage /></RequireAuth>} />
                <Route path="/settings" element={<RequireAuth><SettingsPage /></RequireAuth>} />
                <Route path="/trades">
                  <Route path="" element={<RequireAuth><TradePage /></RequireAuth>} />
                  <Route path=":id" element={<RequireAuth><TradeDetail /></RequireAuth>} />
                </Route>
                <Route path="/payments">
                  <Route path="" element={<RequireAuth><PaymentPage /></RequireAuth>} />
                  <Route path=":id" element={<RequireAuth><PaymentDetail /></RequireAuth>} />
                </Route>
                <Route path="/third-party-account">
                  <Route path="" element={<RequireAuth><ThirdPartyAccountsPage /></RequireAuth>} />
                  <Route path=":id" element={<RequireAuth><ThirdPartyAccountsDetail /></RequireAuth>} />
                </Route>
                <Route path="/own-account">
                  <Route path="" element={<RequireAuth><OwnAccountsPage /></RequireAuth>} />
                  <Route path=":id" element={<RequireAuth><OwnAccountsDetail /></RequireAuth>} />
                </Route>
                <Route path="*" element={<Navigate to="/" replace />} />
              </Route>
            </Routes>
          </Router>
        </SnackbarProvider>
      </UsersProvider>
    </AuthProvider>
  )
}

export default App
