const PHASES = ["Preparation", "Filing", "Approval"]
const PERIODS = ["Monthly", "Quarterly", "Annual"]
const TYPES = ["Statutory Requirement", "Other Requirement"]
const STATUSES = ["Pending", "Done", "Tasks Overdue"]

const YEARS = ["2025", "2024", "2023", "2022", "2021", "2020", "2019", "2018"];
const QUARTERS = ["Q1", "Q2", "Q3", "Q4"];
const MONTHS = {
  JAN: "JAN",
  FEB: "FEB",
  MAR: "MAR",
  APR: "APR",
  MAY: "MAY",
  JUN: "JUN",
  JUL: "JUL",
  AUG: "AUG",
  SEP: "SEP",
  OCT: "OCT",
  NOV: "NOV",
  DEC: "DEC",
}
const MONTHS_LABEL_MAPPING = {
  [MONTHS.JAN]: "January",
  [MONTHS.FEB]: "February",
  [MONTHS.MAR]: "March",
  [MONTHS.APR]: "April",
  [MONTHS.MAY]: "May",
  [MONTHS.JUN]: "June",
  [MONTHS.JUL]: "July",
  [MONTHS.AUG]: "August",
  [MONTHS.SEP]: "September",
  [MONTHS.OCT]: "October",
  [MONTHS.NOV]: "November",
  [MONTHS.DEC]: "December",
}
const PAYMENT_STATUS = ["New", "In progress", "Approved"];
const MONTH_MAPPING_PER_QUARTER = {
  Q1: [MONTHS.JAN, MONTHS.FEB, MONTHS.MAR],
  Q2: [MONTHS.APR, MONTHS.MAY, MONTHS.JUN],
  Q3: [MONTHS.JUL, MONTHS.AUG, MONTHS.SEP],
  Q4: [MONTHS.OCT, MONTHS.NOV, MONTHS.DEC],
  ALL: [
    MONTHS.JAN, MONTHS.FEB, MONTHS.MAR,
    MONTHS.APR, MONTHS.MAY, MONTHS.JUN,
    MONTHS.JUL, MONTHS.AUG, MONTHS.SEP,
    MONTHS.OCT, MONTHS.NOV, MONTHS.DEC,
  ],
}

const FILING_STATUSES = ["Not Started", "Initiated", "Prepared", "Approved", "Submitted", "Paid", "Confirmed"]
const FILING_TYPES = ["VAT", "Annual Accounts", "Corporate Income Tax"]

const FILING_COUNTRIES = [
  'Algeria',
  'Angola',
  'Argentina',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahrain',
  'Belarus',
  'Belgium',
  'Bolivia',
  'Brazil',
  'Bulgaria',
  'Burkina Faso',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Chile',
  'China',
  'Colombia',
  'Costa Rica',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Estonia',
  'European Union',
  'Finland',
  'France',
  'Gabon',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Guatemala',
  'Haiti',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Ireland',
  'Israel',
  'Italy',
  'Ivory Coast',
  'Japan',
  'Kazakhstan',
  'Kenya',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Mexico',
  'Morocco',
  'Mozambique',
  'Namibia',
  'The Netherlands',
  'New Zealand',
  'Nigeria',
  'Norway',
  'Oman',
  'Pakistan',
  'Panama',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Qatar',
  'Romania',
  'Russia',
  'Saudi Arabia',
  'Senegal',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'South Africa',
  'South Korea',
  'Spain',
  'Sri Lanka',
  'Sweden',
  'Switzerland',
  'Taiwan',
  'Tanzania',
  'Thailand',
  'Turkey',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'Uruguay',
  'United States',
  'Venezuela',
  'Vietnam',
  'Zambia'
]

const EU_COUNTRIES = [
  'Austria',
  'Belgium',
  'Bulgaria',
  'Croatia',
  'Cyprus',
  'Czech Republic',
  'Denmark',
  'Estonia',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Hungary',
  'Ireland',
  'Italy',
  'Latvia',
  'Lithuania',
  'Luxembourg',
  'Malta',
  'The Netherlands',
  'Poland',
  'Portugal',
  'Romania',
  'Slovakia',
  'Slovenia',
  'Spain',
  'Sweden'
]

export { EU_COUNTRIES, FILING_COUNTRIES, FILING_STATUSES, FILING_TYPES, MONTHS, MONTHS_LABEL_MAPPING, MONTH_MAPPING_PER_QUARTER, PERIODS, PHASES, QUARTERS, STATUSES, TYPES, YEARS, PAYMENT_STATUS };

