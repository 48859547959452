import { Box, Button, Divider, FormControl, FormHelperText, Grid, List, ListItem, ListItemText, Modal, TextField, Typography } from "@mui/material";
import { AuthContext } from "helpers/auth";
import { ErrorMessage } from "helpers/validations";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { classes } from "./styles";

const TwoFactorAuthModal = ({ qr, secret, onClose }) => {
    const auth = useContext(AuthContext);
    const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange' });

    const onSubmit = async (data) => {
        const { authCode } = data ?? {};
        const body = {
            token: authCode,
        };

        try {
            const userResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/users/2fa/verify/${encodeURIComponent(auth?.user?._id)}`, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + auth.user.token
                },
                body: JSON.stringify(body),
            });

            const response = await userResponse.json();
            if (userResponse.status === 200) {
                auth.updateUser({ otpEnabled: response.otpEnabled, otpVerified: response.otpVerified });
                onClose();
                toast.success(response.message, { autoClose: 7500 });
            } else if (userResponse.status === 401) {
                toast.error(response.message, { autoClose: 7500 });
            }
          } catch (error) {
                toast.error(`Error encountered: ${error.message}`, { autoClose: 7500 });
          }
    };

    return (
        <Modal open onClose={onClose}>
            <Box sx={classes.modal}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography sx={classes.modalHeader}>
                            TWO-FACTOR AUTHENTICATION (2FA)
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={classes.sectionHeader}>
                            Configuring Microsoft Authenticator
                        </Typography>
                        <Divider />
                        <List dense>
                            <ListItem>
                                <ListItemText>
                                    1. Install Microsoft Authenticator (IOS/Android)
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    2. In the authenticator app, select "+" icon.
                                </ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemText>
                                    3. Select "Other (Google, Facebook, etc.)" option.
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={classes.sectionHeader}>
                            Scan QR Code
                        </Typography>
                        <Divider />
                        <Box sx={classes.qrImage}><img src={qr} alt="Scan QR Code" /></Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={classes.sectionHeader}>
                            Or Enter code Into Your App
                        </Typography>
                        <Divider />
                        <Box sx={classes.secretSection}>
                            <Typography>Secret Key: </Typography>
                            <Typography sx={classes.secret}>{secret}</Typography>
                            <Typography>(Base32 encoded)</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography sx={classes.sectionHeader}>
                            Verify Code
                        </Typography>
                        <Divider />
                        <Box sx={classes.verifySection}>
                            <Typography>
                                Enter the authentication code from the app to verify your account.
                            </Typography>
                            <FormControl sx={{ width: '100%' }}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Authentication Code"
                                    {...register("authCode", { required: ErrorMessage.Required })}
                                    error={Boolean(errors.authCode)}
                                />
                                {errors.authCode && (
                                    <FormHelperText error>
                                        {errors.authCode.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid xs={12} sx={classes.buttons}>
                        <Button variant="outlined" onClick={onClose}>
                            CLOSE
                        </Button>
                        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                            VERIFY & ACTIVATE
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
};

export default TwoFactorAuthModal;
