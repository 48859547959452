import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import {
  Box,
  Button,
  Chip,
  Collapse,
  Divider, Grid,
  IconButton,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer, TableHead,
  TableRow,
  Typography
} from "@mui/material"
import ImportDropzone from "components/files/ImportDropzone"
import { AuthContext } from "helpers/auth"
import { SnackbarContext } from "helpers/snackbar"
import moment from "moment"
import { Fragment, useContext, useState } from "react"

const ImportTaskRow = ({ task }) => {
  const [open, setOpen] = useState(false)

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell>
          {task.isValid
            ? <Chip sx={{ minWidth: "80px" }} label={"Valid"} color={"success"} />
            : <Chip sx={{ minWidth: "80px" }} label={"Invalid"} color={"error"} />
          }
        </TableCell>
        <TableCell component="th" scope="row">{task.name}</TableCell>
        <TableCell align="right">{moment(task.deadlineDate).format("YYYY-MM-DD")}</TableCell>
        <TableCell align="right">{task.entity}</TableCell>
        <TableCell align="right">{task.description}</TableCell>
        <TableCell align="right">{task.taskType}</TableCell>
        <TableCell align="right">{task.year}</TableCell>
        <TableCell align="right">{task.period}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div" sx={{ pb: 1 }}>
                Subtasks
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell component="th" scope="row">Name</TableCell>
                    <TableCell align="right">Deadline Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {task.subtasks ? task.subtasks.map((subtask) => (
                    <TableRow key={subtask.name}>
                      <TableCell component="th" scope="row">{subtask.name}</TableCell>
                      <TableCell align="right">{moment(subtask.deadlineDate).format("YYYY-MM-DD")}</TableCell>
                    </TableRow>
                  )) : null}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  )
}

const ImportModal = ({ actions }) => {
  const [open, setOpen] = useState(false)
  const [importObligations, setImportObligations] = useState([])
  const [importTasks, setImportTasks] = useState([])

  const snackbar = useContext(SnackbarContext)

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    actions.toggleUpdate()
  }

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    maxHeight: "900px",
    overflow: "scroll"
  }

  const auth = useContext(AuthContext)

  const processImport = async () => {
    try {
      const uploadResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/import`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth?.user?.token
        },
        body: JSON.stringify({ importObligations, importTasks })
      })

      if (uploadResponse.status === 200) {
        snackbar.setMessage({ message: "Tasks and obligations successfully imported!", severity: "success" })
        setImportObligations([])
        setImportTasks([])
        setOpen(false)
      } else {
        throw Error("Failed to import.")
      }
    } catch (error) {
      snackbar.setMessage({ message: error.message, severity: "error" })
    }
  }

  return (
    <Fragment>
      <Button color="primary" variant="contained" sx={{ mr: 1 }} onClick={handleOpen}>
        Import from Excel
      </Button>

      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h5">Import tasks and obligations from an Excel file</Typography>
              <Divider sx={{ p: 1 }} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Step 1</Typography>
              <Divider sx={{ p: 1, mb: 1 }} />
              <Typography variant="body1" sx={{ my: 1 }} gutterBottom>
                In order to create new tasks and obligations, please use this template.
              </Typography>
              <Button color="primary" variant="contained" href="/import.xlsx">
                Download template
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Step 2</Typography>
              <Divider sx={{ p: 1, mb: 1 }} />
              <Typography variant="body1"  sx={{ my: 1 }} gutterBottom>
                Upload the filled-up template below.
              </Typography>
              <ImportDropzone actions={{setImportObligations, setImportTasks}} />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Step 3</Typography>
              <Divider sx={{ p: 1, mb: 1 }} />
              <Typography variant="body1"  sx={{ my: 1 }} gutterBottom>
                Verify the validity and content of the tasks to be created.
              </Typography>

              {/* Import obligations table */}
              {importObligations?.length !== 0 ?
                <TableContainer component={Paper} sx={{ py: 1 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>New/Existing</TableCell>
                        <TableCell component="th" scope="row">Obligation Name</TableCell>
                        <TableCell align="right">Country</TableCell>
                        <TableCell align="right">Prepare of File</TableCell>
                        <TableCell align="right">Format</TableCell>
                        <TableCell align="right">Deadline Format</TableCell>
                        <TableCell align="right">Notification Deadline Format</TableCell>
                        <TableCell align="right">Threshold</TableCell>
                        <TableCell align="right">Local Language</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {importObligations?.map((obligation) => (
                        <TableRow key={obligation.name + " " + obligation.country}>
                          <TableCell>
                            {obligation.exists
                              ? <Chip sx={{ minWidth: "80px" }} label={"Existing"} color={"primary"} />
                              : <Chip sx={{ minWidth: "80px" }} label={"New"} color={"success"} />
                            }
                          </TableCell>
                          <TableCell component="th" scope="row">{obligation.name}</TableCell>
                          <TableCell align="right">{obligation.country}</TableCell>
                          <TableCell align="right">{obligation.prepareOfFile}</TableCell>
                          <TableCell align="right">{obligation.format}</TableCell>
                          <TableCell align="right">{obligation.deadlineFormat}</TableCell>
                          <TableCell align="right">{obligation.notificationDeadlineFormat}</TableCell>
                          <TableCell align="right">{obligation.threshold}</TableCell>
                          <TableCell align="right">{obligation.localLanguage}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              : null}

              {/* Import tasks table */}
              {importTasks.length !== 0 ?
                <TableContainer component={Paper} sx={{ py: 1 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>Obligation Valid</TableCell>
                        <TableCell component="th" scope="row">Task Name</TableCell>
                        <TableCell align="right">Task Deadline Date</TableCell>
                        <TableCell align="right">Task Legal Entity</TableCell>
                        <TableCell align="right">Task Description</TableCell>
                        <TableCell align="right">Task Type</TableCell>
                        <TableCell align="right">Task Year</TableCell>
                        <TableCell align="right">Task Period</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {importTasks.map((task) => (
                        <ImportTaskRow key={task.name} task={task} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              : null}
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6">Step 4</Typography>
              <Divider sx={{ p: 1, mb: 1 }} />
              <Typography variant="body1" sx={{ my: 1 }} gutterBottom>
                Finalize the creation of the new obligations and valid tasks.
              </Typography>
              <Button
                color="primary"
                variant="contained"
                disabled={importObligations?.length + importTasks.length === 0}
                onClick={processImport}
              >
                Process import
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Fragment>
  )
}

export default ImportModal
