import { Grid, Typography } from "@mui/material";

const DetailTextCell = ({ xs = 6, header, detail }) => {
    return (
        <Grid item xs={xs}>
            <Typography variant="subtitle2" component="div">{header}</Typography>
            <Typography variant="body2" component="div" sx={{ minHeight: "1.2em" }}>{detail ?? '-'}</Typography>
        </Grid>
    )
}

export default DetailTextCell;
