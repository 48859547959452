import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const ConfirmFileDeleteDialog = ({ open, onDelete, onClose }) => {
    return (
        <Dialog open={open} onClose={onClose}>
            <Box sx={{ padding: '16px' }}>
                <DialogTitle>
                    Confirm delete
                </DialogTitle>

                <DialogContent>
                    <DialogContentText>
                        Are you sure you would like to delete this item?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    <Button
                        variant="outlined"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={onDelete}
                        autoFocus
                        disableElevation
                    >
                        Confirm Delete
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default ConfirmFileDeleteDialog;
