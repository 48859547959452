import {
  Box,
  Grid, Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import DeleteDialog from "components/common/DeleteDialog"
import { StyledTableCell, StyledTableRow } from "components/common/StyledTable"
import EntityModal from "components/entities/EntityModal"
import { AuthContext } from "helpers/auth"
import { UsersContext } from "helpers/users"
import { useContext, useEffect, useState } from "react"

const EntityPage = () => {
  const auth = useContext(AuthContext)
  const users = useContext(UsersContext)
  const [updated, setUpdated] = useState(0)
  const toggleUpdate = () => setUpdated(!updated)

  const deleteEntity = async (entity) => {
    await fetch(`${process.env.REACT_APP_API_PROXY}/api/entities/${encodeURIComponent(entity._id)}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth.user.token
      }
    })
    toggleUpdate()
  }

  useEffect(() => {
    if (auth.user) { users.fetchEntityList(auth.user.token) }
  }, [updated]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", flexWrap: "wrap", m: -1 }}>
          <Typography sx={{ m: 1 }} variant="h4">Legal Entities</Typography>
          <Box sx={{ m: 1 }}>
            <EntityModal actions={{ toggleUpdate }} />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper} sx={{ height: "100%" }} >
          <Table sx={{border: "1px grey"}}>
            <colgroup>
              <col style={{width:"60%"}} />
              <col style={{width:"20%"}} />
              <col style={{width:"20%"}} />
            </colgroup>
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Country</StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.entityList?.entities?.map(ent => (
                <StyledTableRow key={ent._id}>
                  <StyledTableCell>{ent.name}</StyledTableCell>
                  <StyledTableCell>{ent.country}</StyledTableCell>
                  <StyledTableCell align="center">
                    <EntityModal entity={ent} actions={{ toggleUpdate }} />
                    <DeleteDialog objectName={"entity"} deleteFunction={() => deleteEntity(ent)} />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default EntityPage
