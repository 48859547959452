import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper, Select,
  Switch,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@mui/material"
import { StyledTableCell } from "components/common/StyledTable"
import ProjectModal from "components/projects/ProjectModal"
import ProjectRow from "components/projects/ProjectRow"
import { TaskStatus, TaskStatusLabelMapping } from "components/tasks/constants"
import { AuthContext } from "helpers/auth"
import COUNTRIES from "helpers/countries"
import { UsersContext } from "helpers/users"
import { useContext, useEffect, useState } from "react"

const ProjectPage = () => {
  const [currentCountry, setCurrentCountry] = useState("")
  const [currentEntity, setCurrentEntity] = useState("")
  const [currentStatus, setCurrentStatus] = useState("")
  const [currentUser, setCurrentUser] = useState("")
  const [taskList, setTaskList] = useState([])
  const [showArchived, setShowArchived] = useState(false)
  const [updated, setUpdated] = useState(0)
  const auth = useContext(AuthContext)
  const users = useContext(UsersContext)
  const toggleUpdate = () => setUpdated(!updated)

  useEffect(() => {
    if (auth.user) {
      const fetchTaskListData = async () => {
        const taskResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/projects?` + new URLSearchParams({
          isArchived: showArchived
        }), {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + auth.user.token
          }
        })
        const response = await taskResponse.json()
        setTaskList(response.tasks)
      }

      fetchTaskListData()
    }
  }, [auth, updated, showArchived])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", flexWrap: "wrap", m: -1 }}>
          <Typography sx={{ m: 1 }} variant="h4">Projects</Typography>
          <Box sx={{ m: 1 }}>
            <ProjectModal actions={{ toggleUpdate }} />
          </Box>
        </Box>
      </Grid>

      {/* Filters */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", height: "100%" }}>
          <Grid container spacing={2}>
            {/* Country filter */}
            <Grid item xs={2.5}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink>Country</InputLabel>
                <Select displayEmpty notched label="Country" value={currentCountry} onChange={(e) => { setCurrentCountry(e.target.value) }}>
                  <MenuItem key={"All Countries"} value="">All Countries</MenuItem>
                  {COUNTRIES.map(country => {
                    return (
                      <MenuItem key={country} value={country}>{country}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            {/* Legal entity filter */}
            <Grid item xs={2.5}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink>Legal Entity</InputLabel>
                <Select displayEmpty notched label="Legal Entity" value={currentEntity} onChange={(e) => { setCurrentEntity(e.target.value) }}>
                  <MenuItem key={"All Entities"} value="">All Entities</MenuItem>
                  {users.entityList?.entities?.map(ent => (
                    <MenuItem key={ent._id} value={ent._id}>{ent.name}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Status filter */}
            <Grid item xs={2.5}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink>Task Status</InputLabel>
                <Select displayEmpty notched label="Task Status" value={currentStatus} onChange={(e) => { setCurrentStatus(e.target.value) }}>
                  <MenuItem key={"All Statuses"} value="">All Statuses</MenuItem>
                  <MenuItem key={"Pending"} value={"Pending"}>{TaskStatusLabelMapping[TaskStatus.Pending]}</MenuItem>
                  <MenuItem key={"Done"} value={"Done"}>{TaskStatusLabelMapping[TaskStatus.Done]}</MenuItem>
                  <MenuItem key={"Tasks Overdue"} value={"Tasks Overdue"}>{TaskStatusLabelMapping[TaskStatus.Overdue]}</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Assigned user filter */}
            <Grid item xs={2.5}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink>Assigned User</InputLabel>
                <Select displayEmpty notched label="Assigned User" value={currentUser} onChange={(e) => { setCurrentUser(e.target.value) }}>
                  <MenuItem key={"All Users"} value="">All Users</MenuItem>
                  {users.usersList?.map(user => (
                    <MenuItem key={user._id} value={user._id}>{user.firstname} {user.lastname}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {/* Clear all filters */}
            <Grid item xs={2}>
              <Button
                variant="outlined"
                sx={{ height: "100%", width: "100%" }}
                onClick={() => {
                  setCurrentCountry("")
                  setCurrentEntity("")
                  setCurrentStatus("")
                  setCurrentUser("")
                }}
              >
                Clear filters
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>

      {/* Archived filter */}
      <Grid item xs={12}>
        <FormControlLabel sx={{ pl: 2 }}
          control={(
            <Switch checked={showArchived} onChange={(e) => setShowArchived(e.target.checked)} />
          )}
          label="Show archived tasks"
        />
      </Grid>

      {/* Tasks */}
      <Grid item xs={12}>
        <TableContainer component={Paper} sx={{ height: "100%" }} >
          <Table sx={{ border: "1px grey" }}>
            <colgroup>
              <col style={{ width: "1%" }} />
              <col style={{ width: "30%" }} />
              <col style={{ width: "12%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "13%" }} />
              <col style={{ width: "5%" }} />
            </colgroup>
            <TableHead>
              <TableRow>
                <StyledTableCell />
                <StyledTableCell>Project Name</StyledTableCell>
                <StyledTableCell>Status</StyledTableCell>
                <StyledTableCell>Deadline Date</StyledTableCell>
                <StyledTableCell>Country</StyledTableCell>
                <StyledTableCell>Legal Entity</StyledTableCell>
                <StyledTableCell>Created by</StyledTableCell>
                <StyledTableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {taskList ? taskList.map((task) => {
                let includeTask = true
                if ((currentCountry && task.country !== currentCountry)
                  || (currentEntity && task.entity._id !== currentEntity)
                  || (currentStatus && task.status !== currentStatus)
                  || (currentUser && !task.assignedUsers.map((u) => u._id).includes(currentUser))) includeTask = false
                return includeTask ? <ProjectRow key={task._id} task={task} /> : null
              }) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default ProjectPage
