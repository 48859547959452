import { Grid, Typography } from "@mui/material";

const PaymentHeader = ({ xs = 6, header, detail }) => {
    return (
        <Grid item xs={xs}>
            <Typography
                sx={{
                    fontWeight: 500,
                    fontSize: '12px',
                    color: '#6F7378',
                    textTransform: 'uppercase',
                }}
            >
                {header}
            </Typography>
            <Typography
                sx={{
                    fontWeight: 700,
                    fontSize: '20px',
                }}
            >
                {detail ?? '-'}
            </Typography>
        </Grid>
    );
}

export default PaymentHeader;
