import { useSelector } from "react-redux";

import { Close as CloseIcon, Download as DownloadIcon } from "@mui/icons-material";
import { Box, Modal, Paper } from "@mui/material";
import { Margin, usePDF } from "react-to-pdf";
import { selectPaymentDetail, selectTaskDetail, selectTradeDetail } from "redux/slices/selectors";

import ButtonWithTooltip from "components/common/ButtonWithTooltip";
import PaymentReceipt from ".";

const PaymentReceiptModal = ({ onClose }) => {
    const taskDetail = useSelector(selectTaskDetail);
    const paymentDetail = useSelector(selectPaymentDetail);
    const tradeDetail = useSelector(selectTradeDetail);

    const { toPDF, targetRef } = usePDF({
        filename: `${taskDetail?.name}-${paymentDetail.id}.pdf`,
        page: { margin: Margin.MEDIUM }
    });

    return (
        <Modal
            open
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    maxWidth: '836px',
                    minWidth: '500px',
                    width: '100%',
                    gap: '8px',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    position: 'absolute',
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                    <ButtonWithTooltip onClick={toPDF} startIcon={<DownloadIcon />}>
                        DOWNLOAD RECEIPT
                    </ButtonWithTooltip>
                    <ButtonWithTooltip onClick={onClose}>
                        <CloseIcon />
                    </ButtonWithTooltip>
                </Box>
                <Paper sx={{ padding: '30px' }}>
                    <PaymentReceipt
                        task={taskDetail}
                        payment={paymentDetail}
                        trade={tradeDetail}
                        targetRef={targetRef}
                    />
                </Paper>
            </Box>
        </Modal>
    );
}

export default PaymentReceiptModal;
