import { useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Button, Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import { AuthContext } from "helpers/auth";
import useMonexApiHelper from "hooks/useMonexApiHelper";
import useTaskHelper from "hooks/useTaskHelper";
import { selectPaymentList, selectTaskList, selectTradeList } from "redux/slices/selectors";

import { StyledTableCell, StyledTableRow } from "components/common/StyledTable";
import TableLoader from "components/common/TableLoader";
import PaymentStatusPill from "components/tasks/PaymentStatusCell";

const PaymentPage = () => {
    const auth = useContext(AuthContext);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const taskList = useSelector(selectTaskList);
    const tradeList = useSelector(selectTradeList);
    const paymentList = useSelector(selectPaymentList);

    const paymentListTableData = useMemo(() => {
        return paymentList?.flatMap((payment) => {
            const task = taskList?.find((task) => task.paymentId === payment.id);
            if (task) {
                const { name: taskName } = task;
                return [{ ...payment, taskName }];
            }
            return []; // Remove payment items without task linked
        }) ?? [];
    }, [paymentList, taskList]);

    const isPageLoading = useMemo(() => (
        paymentList?.length === 0 || taskList?.length === 0 || paymentListTableData.length === 0
    ), [paymentList?.length, taskList?.length, paymentListTableData.length]);

    const { fetchTaskListData } = useTaskHelper();
    const { fetchTradeList, fetchPaymentList } = useMonexApiHelper();

    useEffect(() => {
        if (auth.user && taskList?.length === 0) {
            fetchTaskListData({ auth });
        }
    }, [auth, fetchTaskListData, taskList?.length]);

    useEffect(() => {
        if (tradeList?.length === 0) {
            fetchTradeList();

        }
      }, [dispatch, fetchTradeList, tradeList?.length]);

    useEffect(() => {
        if (paymentList?.length === 0) {
            fetchPaymentList();
        }
    }, [fetchPaymentList, paymentList?.length]);

    const handlePaymentDetailRedirect = (paymentId) => {
        navigate(`/payments/${paymentId}`);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography sx={{ m: 1 }} variant="h4">Payments</Typography>
            </Grid>

            <Grid item xs={12}>
                {isPageLoading ? (
                    <TableLoader />
                ) : (
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Task Name</StyledTableCell>
                                    <StyledTableCell align="center">Reference</StyledTableCell>
                                    <StyledTableCell align="center">Purpose</StyledTableCell>
                                    <StyledTableCell align="center">Amount</StyledTableCell>
                                    <StyledTableCell align="center">Value Date</StyledTableCell>
                                    <StyledTableCell align="center">Payment Status</StyledTableCell>
                                    <StyledTableCell align="center">Payment Code</StyledTableCell>
                                    <StyledTableCell/>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {paymentListTableData?.map((row) => {
                                    const { id: paymentId, code, valueDate, status, currencyCode, amount, taskName, reference, purpose } = row;
                                    return (
                                        <StyledTableRow
                                            key={paymentId}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <StyledTableCell>{taskName}</StyledTableCell>
                                            <StyledTableCell align="center">{reference ?? '-'}</StyledTableCell>
                                            <StyledTableCell align="center">{purpose ?? '-'}</StyledTableCell>
                                            <StyledTableCell align="center">{currencyCode} {amount.toFixed(2)}</StyledTableCell>
                                            <StyledTableCell align="center">{valueDate}</StyledTableCell>
                                            <StyledTableCell align="center"><PaymentStatusPill status={status} /></StyledTableCell>
                                            <StyledTableCell align="center">{code}</StyledTableCell>
                                            <StyledTableCell align="right">
                                                <Button
                                                    onClick={() => handlePaymentDetailRedirect(paymentId)}
                                                    variant="outlined"
                                                >
                                                    VIEW
                                                </Button>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Grid>
        </Grid>
    );
};

export default PaymentPage;