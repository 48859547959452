import { memo, useCallback, useMemo } from "react";

import { geoCentroid } from "d3-geo";
import {
    Annotation,
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    ZoomableGroup
} from "react-simple-maps";

import { TaskStatus } from "components/tasks/constants";
import { US_GEO_URL, US_MAP_OFFSETS } from "helpers/mapData/constants";
import useMapChartHelper, { HOVER_REGION_COLOR, PRESSED_REGION_COLOR } from "hooks/useMapChartHelper";
import { useDispatch } from "react-redux";
import { INITIAL_MAP_CHART_TOOLTIP_STATE, setMapChartTooltip, setTaskPageFilters } from "redux/slices/tasksSlice";

import { Box } from "@mui/material";
import { DEFAULT_MAP_CHART_HEIGHT } from "helpers/maps";
import allStates from "../../../../helpers/mapData/usa_states.json";
import MapChartControls from "../MapChartControls";
import useMapChartControls from "../MapChartControls/useMapChartControls";
import { classes } from "./styles";

const UnitedStatesMapChart = ({ data = [] }) => {
    const dispatch = useDispatch();
    const {
        position,
        handleZoomIn,
        handleZoomOut,
        handleMoveEnd,
    } = useMapChartControls({
        initialZoom: 0.9,
        initialCoordinates: [-96, 38]
    });
    const { getRegionColor } = useMapChartHelper();

    const mapData = useMemo(() => {
        let taskStatusMapPerRegion = {
            [TaskStatus.Done]: {},
            [TaskStatus.Pending]: {},
            [TaskStatus.Overdue]: {},
        };

        const filteredData = data.filter((task) => {
            return task.obligation.country === 'United States' || task.obligation.countryCode === 'US'
        });

        filteredData.forEach((task) => {
            const geographicalRegionCode = task.obligation.geographicalRegionCode;
            const taskStatus = task.status;
            switch (taskStatus) {
                case TaskStatus.Done: {
                    const currentCount = taskStatusMapPerRegion[TaskStatus.Done][geographicalRegionCode] ?? 0;
                    taskStatusMapPerRegion[TaskStatus.Done] = {
                        ...taskStatusMapPerRegion[TaskStatus.Done],
                        [geographicalRegionCode]: currentCount + 1,
                    }
                    break;
                }
                case TaskStatus.Pending: {
                    const currentCount = taskStatusMapPerRegion[TaskStatus.Pending][geographicalRegionCode] ?? 0;
                    taskStatusMapPerRegion[TaskStatus.Pending] = {
                        ...taskStatusMapPerRegion[TaskStatus.Pending],
                        [geographicalRegionCode]: currentCount + 1,
                    }
                    break;
                }
                case TaskStatus.Overdue: {
                    const currentCount = taskStatusMapPerRegion[TaskStatus.Overdue][geographicalRegionCode] ?? 0;
                    taskStatusMapPerRegion[TaskStatus.Overdue] = {
                        ...taskStatusMapPerRegion[TaskStatus.Overdue],
                        [geographicalRegionCode]: currentCount + 1,
                    }
                    break;
                }
                default:
                    break;
            }
        })
        return taskStatusMapPerRegion;
    }, [data]);

    const handleMouseEnter = useCallback((geo) => {
        const name = geo.properties.name;
        const alphaCode = allStates.find(s => s.val === geo.id).id;
        const pendingTasksCount = mapData[TaskStatus.Pending][alphaCode] ?? 0;
        const completedTasksCount = mapData[TaskStatus.Done][alphaCode] ?? 0;
        const overdueTasksCount = mapData[TaskStatus.Overdue][alphaCode] ?? 0;

        dispatch(setMapChartTooltip({
            mapChartTooltip: {
                name,
                data: {
                    pending: pendingTasksCount,
                    completed:  completedTasksCount,
                    overdue: overdueTasksCount,
                },
            }
        }));
    }, [dispatch, mapData]);

    const handleMouseLeave = () => {
        dispatch(setMapChartTooltip({ mapChartTooltip: INITIAL_MAP_CHART_TOOLTIP_STATE }));
    };

    const getMapColor = useCallback((alphaCode) => {
        const data = {
            [TaskStatus.Pending]: mapData[TaskStatus.Pending]?.[alphaCode],
            [TaskStatus.Done]: mapData[TaskStatus.Done]?.[alphaCode],
            [TaskStatus.Overdue]: mapData[TaskStatus.Overdue]?.[alphaCode],
        };
        return getRegionColor(data);
    }, [getRegionColor, mapData]);

    return (
        <Box data-tip="" sx={classes.root}>
            <ComposableMap height={DEFAULT_MAP_CHART_HEIGHT} projection="geoAlbersUsa">
                <ZoomableGroup
                    zoom={position.zoom}
                    center={position.coordinates}
                    onMoveEnd={handleMoveEnd}
                >
                    <Geographies geography={US_GEO_URL}>
                        {({ geographies }) => (
                            <>
                                {geographies.map(geo => {
                                    const cur = allStates.find(s => s.val === geo.id);
                                    const alphaCode = cur.id;
                                    const fill = getMapColor(alphaCode);

                                    return (
                                        <Geography
                                            key={geo.rsmKey}
                                            geography={geo}
                                            onClick={() => {
                                                dispatch(setTaskPageFilters({
                                                    pageFilters: {
                                                        region: alphaCode
                                                    }
                                                }))
                                            }}
                                            onMouseEnter={() => handleMouseEnter(geo)}
                                            onMouseLeave={handleMouseLeave}
                                            style={{
                                                default: {
                                                    fill,
                                                    stroke: "#F6F6FA",
                                                    outline: "none"
                                                },
                                                hover: {
                                                    fill: HOVER_REGION_COLOR,
                                                    outline: "none"
                                                },
                                                pressed: {
                                                    fill: PRESSED_REGION_COLOR,
                                                    outline: "none"
                                                }
                                            }}
                                        />
                                    );
                                })}
                                {geographies.map(geo => {
                                    const centroid = geoCentroid(geo);
                                    const cur = allStates.find(s => s.val === geo.id);
                                    return (
                                        <g key={geo.rsmKey + "-name"}>
                                            {cur &&
                                                centroid[0] > -160 &&
                                                centroid[0] < -67 &&
                                                (Object.keys(US_MAP_OFFSETS).indexOf(cur.id) === -1 ? (
                                                    <Marker
                                                        coordinates={centroid}
                                                        onMouseEnter={() => handleMouseEnter(geo)}
                                                        onMouseLeave={handleMouseLeave}
                                                    >
                                                        <text y="2" fontSize={14} textAnchor="middle">
                                                            {cur.id}
                                                        </text>
                                                    </Marker>
                                                ) : (
                                                    <Annotation
                                                        subject={centroid}
                                                        dx={US_MAP_OFFSETS[cur.id][0]}
                                                        dy={US_MAP_OFFSETS[cur.id][1]}
                                                        onMouseEnter={() => handleMouseEnter(geo)}
                                                        onMouseLeave={handleMouseLeave}
                                                    >
                                                        <text x={4} fontSize={14} alignmentBaseline="middle">
                                                            {cur.id}
                                                        </text>
                                                    </Annotation>
                                                ))
                                            }
                                        </g>
                                    );
                                })}
                            </>
                        )}
                    </Geographies>
                </ZoomableGroup>
            </ComposableMap>
            <MapChartControls onZoomOut={handleZoomOut} onZoomIn={handleZoomIn} />
        </Box>
    );
};

export default memo(UnitedStatesMapChart);
