import { Grid, Typography } from "@mui/material";

import DetailTextRow from "components/common/DetailTextRow";
import FullPageLoader from "components/common/FullPageLoader";

const BankAccountsDetailContent = ({ data, isLoading = false }) => {
    const {
        currencyCode,
        bankName,
        accountName,
        nickname,
        accountNumber,
        nationalClearingCode,
        iban,
        bic,
        isActive,
        bankAddress,
        address,
    } = data;

    const {
        line1,
        city,
        state,
        postalCode,
        countryCode,
    } = bankAddress ?? address ?? {};

    return (
        <Grid container spacing={3}>
            <FullPageLoader open={isLoading} />
            <Grid item xs={12}>
                <Typography sx={{ m: 1 }} variant="h4">Account Details for {bankName}</Typography>
            </Grid>

            <Grid item xs={12}>
                <Grid container>
                    {/* Account Details */}
                    <Grid item xs={6}>
                        <DetailTextRow header="Currency" detail={currencyCode} />
                        <DetailTextRow header="Account Name" detail={accountName} />
                        <DetailTextRow header="Nickname" detail={nickname} />
                        <DetailTextRow header="Account Number" detail={accountNumber} />
                        <DetailTextRow header="National Clearing Code" detail={nationalClearingCode} />
                        <DetailTextRow header="IBAN" detail={iban} />
                        <DetailTextRow header="SWIFT (BIC) code" detail={bic} />
                    </Grid>

                    {/* Bank Address */}
                    <Grid item xs={6}>
                        <DetailTextRow header="Bank Address (Line 1)" detail={line1} />
                        <DetailTextRow header="Bank City" detail={city} />
                        <DetailTextRow header="Bank State" detail={state} />
                        <DetailTextRow header="Bank Post / Zip Code" detail={postalCode} />
                        <DetailTextRow header="Country" detail={countryCode} />
                        <DetailTextRow header="Active Status" detail={isActive ? 'Active' : 'Inactive'} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default BankAccountsDetailContent;