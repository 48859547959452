export const classes = {
    modal: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "75%",
        backgroundColor: "white",
        boxShadow: 24,
        padding: '32px',
    },
    modalHeader: {
        fontSize: '16px',
        fontWeight: 700,
        color: '#0050B5',
    },
    buttons: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginTop: '16px',
    },
}