export const CustomerSection = {
    Accounting: 'ACCOUNTING',
    TaxAdvisory: 'TAX_ADVISORY',
};

export const CustomerSecionLabelMapping = {
    [CustomerSection.Accounting]: 'Accounting',
    [CustomerSection.TaxAdvisory]: 'Tax Advisory',
};

export const CustomerSectionOptions = [
    CustomerSection.Accounting,
    CustomerSection.TaxAdvisory,
];
