
import { toast } from "react-toastify";

const useToastDisplay = () => {
    const showErrorSnackbar = (message) => toast.error(`Encountered an error: ${message}`, { autoClose: 5000 });
    const showSuccessSnackbar = (message) => toast.success(message, { autoClose: 5000 });

    return {
        showErrorSnackbar,
        showSuccessSnackbar,
    };
};

export default useToastDisplay;
