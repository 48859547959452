
import { Grid, Typography } from "@mui/material";
import FilingStatusCell from "components/filings/FilingStatusCell";
import { FilingStatus, FilingStatusLegendMapping } from "components/filings/constants";

const FilingStatusLegendItem = ({ status }) => (
    <Grid item xs={12} gap="8px" display="flex" alignItems="center">
        <FilingStatusCell status={status} />
        {FilingStatusLegendMapping[status]}
    </Grid>
)

const FilingStatusLegend = () => {
    return (
        <Grid
            container
            spacing={2}
            sx={{
                maxWidth: '350px',
            }}
        >
            <Typography
                variant="h5"
                textAlign="center"
                sx={{ paddingTop: '16px', fontWeight: 700, width: '100%' }}
            >
                STATUS LEGEND
            </Typography>
            <FilingStatusLegendItem status={FilingStatus.NotStarted} />
            <FilingStatusLegendItem status={FilingStatus.Initiated} />
            <FilingStatusLegendItem status={FilingStatus.Prepared} />
            <FilingStatusLegendItem status={FilingStatus.Approved} />
            <FilingStatusLegendItem status={FilingStatus.Submitted} />
            <FilingStatusLegendItem status={FilingStatus.Paid} />
            <FilingStatusLegendItem status={FilingStatus.Confirmed} />
        </Grid>
    );
}

export default FilingStatusLegend;
