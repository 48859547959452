import { Edit as EditIcon } from "@mui/icons-material"
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid, IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField
} from "@mui/material"
import { AuthContext } from "helpers/auth"
import COUNTRIES, { COUNTRY_NAME_TO_COUNTRY_CODES } from "helpers/countries"
import { CANADA_PROVINCE_CODE_TO_NAME, CANADA_PROVINCE_LIST, US_STATES_LIST, US_STATE_CODE_TO_NAME } from "helpers/regions"
import { isEmpty } from "lodash-es"
import { Fragment, useContext, useState } from "react"
import { ValidatorForm } from "react-material-ui-form-validator"
import { toast } from "react-toastify"

const EntityModal = ({ entity, actions }) => {
  const [name, setName] = useState(entity ? entity.name : "")
  const [country, setCountry] = useState(entity ? entity.country : COUNTRIES[0])
  const countryCode = COUNTRY_NAME_TO_COUNTRY_CODES[country];
  const shouldShowRegion = countryCode === 'US' || countryCode === 'CA';
  const regionFilterLabel = countryCode === 'US' ? 'State' : 'Province';
  const [regionCode, setRegionCode] = useState(entity && shouldShowRegion ? entity?.geographicalRegionCode : undefined);
  const [open, setOpen] = useState(false)
  const auth = useContext(AuthContext)

  const handleOpen = () => setOpen(true)
  const handleClose = () => {
    setOpen(false)
    actions.toggleUpdate()
  }

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  }

  const handleSubmit = async () => {
    try {
      const url = entity
        ? `${process.env.REACT_APP_API_PROXY}/api/entities/${encodeURIComponent(entity._id)}`
        : `${process.env.REACT_APP_API_PROXY}/api/entities/`
      const method = entity ? "PUT" : "POST"

      if (shouldShowRegion && isEmpty(regionCode)) {
        return toast.error(`Please select a state/province for ${country}`, { autoClose: 5000 });
      }

      await fetch(url, {
        method: method,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth.user.token
        },
        body: JSON.stringify({ name, country, countryCode, geographicalRegionCode: regionCode })
      })

      if (!entity) { setName(""); setCountry(""); setRegionCode(undefined); }
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Fragment>
      {entity ?
        <IconButton variant="contained" onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      :
        <Button color="primary" variant="contained" onClick={handleOpen}>
          Add Entity
        </Button>
      }
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Header */}
              <Grid item xs={12}>
                {entity ? "Edit" : "Add"} legal entity
                <Divider sx={{ p: 1 }} />
              </Grid>

              {/* Entity name */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>

              {/* Entity country */}
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Country</InputLabel>
                  <Select
                    variant="outlined"
                    label="Country"
                    value={country}
                    onChange={(e) => {
                      const countryName = e.target.value;
                      const countryCode = COUNTRY_NAME_TO_COUNTRY_CODES[countryName];
                      if (!(countryCode === 'US' || countryCode === 'CA')) {
                        setRegionCode(undefined);
                      }
                      setCountry(e.target.value);
                    }}
                  >
                    { COUNTRIES.map(c => <MenuItem key={c} value={c}>{c}</MenuItem>) }
                  </Select>
                </FormControl>
              </Grid>

              {/* Entity state/province */}
              {shouldShowRegion && (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>{regionFilterLabel}</InputLabel>
                    <Select
                      variant="outlined"
                      label={regionFilterLabel}
                      value={regionCode}
                      onChange={(e) => {setRegionCode(e.target.value)}}
                    >
                      {countryCode === 'US' && US_STATES_LIST.map((stateCode) => {
                        const stateName = US_STATE_CODE_TO_NAME[stateCode];
                        return (
                            <MenuItem key={stateCode} value={stateCode}>
                                {stateName}
                            </MenuItem>
                        );
                      })}
                      {countryCode === 'CA' && CANADA_PROVINCE_LIST.map((provinceCode) => {
                        const provinceName = CANADA_PROVINCE_CODE_TO_NAME[provinceCode];
                        return (
                            <MenuItem key={provinceCode} value={provinceCode}>
                                {provinceName}
                            </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {/* Submit */}
              <Grid item xs={12}>
                <Button variant="contained" type="submit">Submit</Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Box>
      </Modal>
    </Fragment>
  )
}

export default EntityModal
