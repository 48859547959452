import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";

import { classes } from "./styles";

const MapChartControls = ({ onZoomOut, onZoomIn }) => {
    return (
        <Box sx={classes.controls}>
            <Tooltip title="Zoom out" position="top" arrow>
                <IconButton onClick={onZoomOut}>
                    <RemoveCircle />
                </IconButton>
            </Tooltip>
            <Tooltip title="Zoom in" position="top" arrow>
                <IconButton onClick={onZoomIn}>
                    <AddCircle />
                </IconButton>
            </Tooltip>
        </Box>
    );
}

export default MapChartControls;