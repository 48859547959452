import { useState, useCallback, memo, useEffect } from "react"
import { Grid } from "@mui/material"
import { GoogleMap, useJsApiLoader, MarkerF } from "@react-google-maps/api"
import GooglePlacesAutocomplete, { geocodeByPlaceId, geocodeByLatLng, getLatLng } from "react-google-places-autocomplete"

const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
const libraries = ["places"]
const containerStyle = { width: "100%", height: "600px" }

const PropertyMap = ({ properties, formValues, formSetters }) => {
  const [zoom, setZoom] = useState(3)
  const [center, setCenter] = useState({ lat: 22, lng: 14 })
  const [, setMap] = useState(null)

  const { isLoaded } = useJsApiLoader({ id: "google-map-script", googleMapsApiKey, libraries })

  const onLoad = useCallback((map) => {
    new window.google.maps.LatLngBounds(center)
    new window.google.maps.places.PlacesService(map)
    setMap(map)
  }, [center])

  const onUnmount = useCallback((map) => {
    setMap(null)
  }, [])

  const onClick = async (e) => {
    if (formSetters) {
      const newCoords = { lat: e.latLng.lat(), lng: e.latLng.lng() }
      const geocode = await geocodeByLatLng(newCoords)
      formSetters.setCoords(newCoords)
      formSetters.setAddress(geocode[0].formatted_address)
    }
  }

  const gpaOnChange = async (val) => {
    if (val) {
      const geocode = await geocodeByPlaceId(val.value.place_id)
      const newCoords = await getLatLng(geocode[0])
      formSetters.setCoords(newCoords)
      formSetters.setAddress(geocode[0].formatted_address)
      setCenter(newCoords)
      setZoom(15)
    }
  }

  useEffect(() => {}, [properties])

  return isLoaded ? (
    <Grid container>
      {/* Google Maps */}
      <Grid item xs={12}>
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={zoom}
          onLoad={onLoad}
          onUnmount={onUnmount}
          onClick={onClick}
        >
          {properties ? 
            properties.map(property => (
              <MarkerF key={property._id} position={property.coords} />
            )) 
          : 
            (formValues.coords.lat ? <MarkerF position={formValues.coords} /> : null)
          }
        </GoogleMap>
      </Grid>

      {/* Search box for address, render only on form */}
      {formSetters ?
        <Grid item xs={12} pt={2} sx={{ zIndex: "1000 !important" }}>
          <GooglePlacesAutocomplete
            apiKey={googleMapsApiKey}
            selectProps={{
              placeholder: "Search address...", 
              isClearable: true,
              onChange: gpaOnChange
            }}
          />
        </Grid>
      : null }
    </Grid>
  ) : (
    <></>
  )
}

export default memo(PropertyMap)
