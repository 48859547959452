import { ALL_COUNTRIES, AMERICAS_COUNTRIES, APAC_COUNTRIES, CANADA_PROVINCE_CODE_TO_NAME, CONTINENT_GROUP, EMEA_COUNTRIES, US_STATE_CODE_TO_NAME } from "helpers/regions";


const useGeographicNameHelper = () => {
    const getContinentCode = ({ countryCode }) => {
        if (AMERICAS_COUNTRIES.includes(countryCode)) {
            return CONTINENT_GROUP.AMERICAS;
        } else if (APAC_COUNTRIES.includes(countryCode)) {
            return CONTINENT_GROUP.APAC;
        } else if (EMEA_COUNTRIES.includes(countryCode)) {
            return CONTINENT_GROUP.EMEA;
        } else if (ALL_COUNTRIES.includes(countryCode)) {
            return CONTINENT_GROUP.GENERAL
        } else {
            return undefined;
        }
    };

    const getStateOrProvinceName = ({ countryCode, geographicalRegionCode }) => {
        switch (countryCode) {
            case 'US':
                return US_STATE_CODE_TO_NAME[geographicalRegionCode];
            case 'CA':
                return CANADA_PROVINCE_CODE_TO_NAME[geographicalRegionCode];
            default:
                return undefined;
        }
    };

    return {
        getStateOrProvinceName,
        getContinentCode,
    };
};

export default useGeographicNameHelper;
