import { createSlice } from "@reduxjs/toolkit";
import { CONTINENT_GROUP } from "helpers/regions";

export const INITIAL_MAP_CHART_TOOLTIP_STATE = {
    name: '',
    data: {},
};

export const INITIAL_FILING_CHART_FILTERS = {
    continent: CONTINENT_GROUP.GENERAL,
    country: '',
    region: '',
    filingType: '',
    legalEntity: '',
    year: '',
    quarter: '',
    month: '',
    filingStatus: '',
};

const initialState = {
    filingList: [],
    filingDetail: {},
    mapChartTooltip: INITIAL_MAP_CHART_TOOLTIP_STATE,
    pageFilters: INITIAL_FILING_CHART_FILTERS,
    mapChartTab: 0,
};

const filingsSlice = createSlice({
    name: 'filings',
    initialState,
    reducers: {
        setFilingList: (state, action) => {
            state.filingList = action.payload.filingList
        },
        setFilingDetail: (state, action) => {
            state.filingDetail = action.payload.filingDetail
        },
        setMapChartTooltip: (state, action) => {
            const { name, data } = action.payload.mapChartTooltip
            state.mapChartTooltip = { name, data }
        },
        setFilingPageFilters: (state, action) => {
            const currentFilters = state.pageFilters
            const updatedFilters = action.payload.pageFilters
            state.pageFilters = { ...currentFilters, ...updatedFilters }
        },
        setMapChartTab: (state, action) => {
            state.mapChartTab = action.payload.mapChartTab
        },
    }
});

export const {
    setFilingList,
    setFilingDetail,
    setMapChartTooltip,
    setFilingPageFilters,
    setMapChartTab,
} = filingsSlice.actions;

export default filingsSlice;