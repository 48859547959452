import { KeyboardArrowRight } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { StyledTableCell, StyledTableRow } from "components/common/StyledTable"
import FilingStatusCell from "components/tasks/FilingStatusCell"
import moment from "moment"
import { Fragment } from "react"
import { Link } from "react-router-dom"

const ProjectRow = ({ task }) => {
  return (
    <Fragment>
      {/* Main cell content */}
      <StyledTableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <StyledTableCell />
        <StyledTableCell>{task.name}</StyledTableCell>
        <StyledTableCell><FilingStatusCell status={task.status} /></StyledTableCell>
        <StyledTableCell>{moment(task.deadlineDate).format("YYYY-MM-DD")}</StyledTableCell>
        <StyledTableCell>{task.country}</StyledTableCell>
        <StyledTableCell>{task.entity?.name}</StyledTableCell>
        <StyledTableCell>{task.createdBy?.firstname} {task.createdBy?.lastname}</StyledTableCell>
        <StyledTableCell>
          <IconButton size="small">
            <Link to={task._id}><KeyboardArrowRight /></Link>
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>
    </Fragment>
  )
}

export default ProjectRow
