import { memo, useCallback, useMemo } from "react";

import { geoCentroid } from "d3-geo";
import { useDispatch } from "react-redux";
import {
    ComposableMap,
    Geographies,
    Geography,
    Marker,
    ZoomableGroup
} from "react-simple-maps";

import { Box } from "@mui/material";
import { TaskStatus } from "components/tasks/constants";
import { DEFAULT_MAP_CHART_HEIGHT } from "helpers/maps";
import useMapChartHelper, { HOVER_REGION_COLOR, PRESSED_REGION_COLOR } from "hooks/useMapChartHelper";
import { INITIAL_MAP_CHART_TOOLTIP_STATE, setMapChartTooltip, setTaskPageFilters } from "redux/slices/tasksSlice";
import canadaProvinces from '../../../../helpers/mapData/canada_provinces.json';
import MapChartControls from "../MapChartControls";
import useMapChartControls from "../MapChartControls/useMapChartControls";
import { classes } from "./styles";

const CanadaMapChart = ({ data = [] }) => {
    const dispatch = useDispatch();
    const {
        position,
        handleZoomIn,
        handleZoomOut,
        handleMoveEnd,
    } = useMapChartControls({
        initialZoom: 0.9,
        initialCoordinates: [-87, 63]
    });
    const { getRegionColor } = useMapChartHelper();

    const mapData = useMemo(() => {
        let taskStatusMapPerRegion = {
            [TaskStatus.Done]: {},
            [TaskStatus.Pending]: {},
            [TaskStatus.Overdue]: {},
        };

        const filteredData = data.filter((task) => {
            return task.obligation.country === 'Canada' || task.obligation.countryCode === 'CA'
        });

        filteredData.forEach((task) => {
            const geographicalRegionCode = task.obligation.geographicalRegionCode;
            const taskStatus = task.status;
            switch (taskStatus) {
                case TaskStatus.Done: {
                    const currentCount = taskStatusMapPerRegion[TaskStatus.Done][geographicalRegionCode] ?? 0;
                    taskStatusMapPerRegion[TaskStatus.Done] = {
                        ...taskStatusMapPerRegion[TaskStatus.Done],
                        [geographicalRegionCode]: currentCount + 1,
                    }
                    break;
                }
                case TaskStatus.Pending: {
                    const currentCount = taskStatusMapPerRegion[TaskStatus.Pending][geographicalRegionCode] ?? 0;
                    taskStatusMapPerRegion[TaskStatus.Pending] = {
                        ...taskStatusMapPerRegion[TaskStatus.Pending],
                        [geographicalRegionCode]: currentCount + 1,
                    }
                    break;
                }
                case TaskStatus.Overdue: {
                    const currentCount = taskStatusMapPerRegion[TaskStatus.Overdue][geographicalRegionCode] ?? 0;
                    taskStatusMapPerRegion[TaskStatus.Overdue] = {
                        ...taskStatusMapPerRegion[TaskStatus.Overdue],
                        [geographicalRegionCode]: currentCount + 1,
                    }
                    break;
                }
                default:
                    break;
            }
        })
        return taskStatusMapPerRegion;
    }, [data]);

    const handleMouseEnter = useCallback((geo) => {
        const name = geo.properties.name;
        const alphaCode = geo.properties.code;
        const pendingTasksCount = mapData[TaskStatus.Pending][alphaCode] ?? 0;
        const completedTasksCount = mapData[TaskStatus.Done][alphaCode] ?? 0;
        const overdueTasksCount = mapData[TaskStatus.Overdue][alphaCode] ?? 0;

        dispatch(setMapChartTooltip({
            mapChartTooltip: {
                name,
                data: {
                    pending: pendingTasksCount,
                    completed:  completedTasksCount,
                    overdue: overdueTasksCount,
                },
            }
        }));
    }, [dispatch, mapData]);

    const handleMouseLeave = () => {
        dispatch(setMapChartTooltip({ mapChartTooltip: INITIAL_MAP_CHART_TOOLTIP_STATE }));
    };

    const getMapColor = useCallback((alphaCode) => {
        const data = {
            [TaskStatus.Pending]: mapData[TaskStatus.Pending]?.[alphaCode],
            [TaskStatus.Done]: mapData[TaskStatus.Done]?.[alphaCode],
            [TaskStatus.Overdue]: mapData[TaskStatus.Overdue]?.[alphaCode],
        };
        return getRegionColor(data);
    }, [getRegionColor, mapData]);

    return (
        <Box data-tip="" sx={classes.root}>
            <ComposableMap
                height={DEFAULT_MAP_CHART_HEIGHT}
                projection='geoMercator'
                projectionConfig={{
                    rotate: [105, -65, 0],
                    scale: 800,
                    center: [8, 0],
                }}
            >
                <ZoomableGroup
                    zoom={position.zoom}
                    center={position.coordinates}
                    onMoveEnd={handleMoveEnd}
                >
                    <Geographies geography={canadaProvinces}>
                        {({ geographies }) => {
                            return (
                                <>
                                    {geographies.map((geo) => {
                                        const alphaCode = geo.properties.code;
                                        const fill = getMapColor(geo.properties.code);
                                        return (
                                            <Geography
                                                onClick={() => {
                                                    dispatch(setTaskPageFilters({
                                                        pageFilters: {
                                                            region: alphaCode
                                                        }
                                                    }))
                                                }}
                                                onMouseEnter={() => handleMouseEnter(geo)}
                                                onMouseLeave={handleMouseLeave}
                                                key={geo.rsmKey}
                                                geography={geo}
                                                style={{
                                                    default: {
                                                        fill,
                                                        stroke: "#F6F6FA",
                                                        outline: "none"
                                                    },
                                                    hover: {
                                                        fill: HOVER_REGION_COLOR,
                                                        outline: "none"
                                                    },
                                                    pressed: {
                                                        fill: PRESSED_REGION_COLOR,
                                                        outline: "none"
                                                    }
                                                }}
                                            />
                                        );
                                    })}

                                    {geographies.map((geo) => {
                                        const provinceCenter = geoCentroid(geo);

                                        return (
                                            <Marker
                                                key={geo.rsmKey}
                                                coordinates={provinceCenter}
                                                onMouseEnter={() => handleMouseEnter(geo)}
                                                onMouseLeave={handleMouseLeave}
                                            >
                                                <text
                                                    fontSize={14}
                                                    textAnchor='middle'
                                                >
                                                    {geo.properties.code}
                                                </text>
                                            </Marker>
                                        );
                                    })}
                                </>
                            );
                        }}
                    </Geographies>
                </ZoomableGroup>
            </ComposableMap>
            <MapChartControls onZoomOut={handleZoomOut} onZoomIn={handleZoomIn} />
        </Box>
    );
};

export default memo(CanadaMapChart);
