import { Box, Modal } from "@mui/material";

import FilingStatusLegend from "components/filings/FilingsMapSection/FilingStatusLegend";
import { classes } from "./styles";

const TaskMapLegend = ({ onClose }) => {
    return (
        <Modal open onClose={onClose}>
            <Box sx={classes.root}>
                <FilingStatusLegend />
            </Box>
        </Modal>
    )
}

export default TaskMapLegend;
