import { useContext, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { Box, Divider, Grid, Paper, Typography } from "@mui/material";
import { isEmpty } from "lodash-es";
import moment from "moment";

import { AuthContext } from "helpers/auth";
import useMonexApiHelper from "hooks/useMonexApiHelper";
import useTaskHelper from "hooks/useTaskHelper";
import { selectPaymentDetail, selectTaskList } from "redux/slices/selectors";

import DetailTextCell from "components/common/DetailTextCell";
import FullPageLoader from "components/common/FullPageLoader";
import PaymentStatusPill from "components/tasks/PaymentStatusCell";

const PaymentDetail = () => {
    const auth = useContext(AuthContext);
    const dispatch = useDispatch();

    const { id: paymentIdParam } = useParams();
    const paymentDetail = useSelector(selectPaymentDetail);
    const taskList = useSelector(selectTaskList);
    const { id: paymentId, bankAccount = {}, authorisedBy, status, currencyCode, amount, valueDate, reference, purpose, instructedOn } = paymentDetail;
    const { accountName, accountNumber, iban, bic } = bankAccount;
    const linkedTask = useMemo(() => taskList.find((task) => task.paymentId === paymentId) ?? {}, [paymentId, taskList]);
    const { _id: taskId, name: taskName } = linkedTask;

    const isPageLoading = useMemo(() => (
        taskList.length === 0 || isEmpty(paymentDetail) || paymentIdParam !== paymentId
    ), [paymentDetail, paymentId, paymentIdParam, taskList.length]);

    const { fetchTaskListData } = useTaskHelper();
    const { fetchPaymentDetail } = useMonexApiHelper();

    useEffect(() => {
        if (auth.user && taskList.length === 0) {
            fetchTaskListData({ auth });
        }
    }, [auth, fetchTaskListData, taskList.length]);

    useEffect(() => {
        if (isEmpty(paymentDetail) || paymentIdParam !== paymentId) {
            fetchPaymentDetail({ paymentId: paymentIdParam });
        }
      }, [dispatch, fetchPaymentDetail, paymentDetail, paymentId, paymentIdParam]);

    return (
        <Grid container spacing={3}>
            <FullPageLoader open={isPageLoading} />
            <Grid item xs={12}>
                <Typography sx={{ m: 1 }} variant="h4">Payment Details for {taskName}</Typography>
            </Grid>

            {/* Payment Details */}
            <Grid item xs={12} md={6}>
                <Paper elevation={6} sx={{ p: 2, display: "flex", flexDirection: "column", height: "100%" }}>
                    <Box sx={{ pb: 1 }}>
                        <Grid container>
                            <DetailTextCell xs={8} header="Linked Task" detail={taskName} />
                            <Grid item xs={4}>
                                <Typography variant="subtitle2" component="div">Payment Status</Typography>
                                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, pt: 1 }}>
                                    <PaymentStatusPill status={status} />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                    <Divider />
                    <Box sx={{ pt: 1 }}>
                        <Grid container>
                            <DetailTextCell xs={4} header="Purpose" detail={purpose} />
                            <DetailTextCell xs={4} header="Client Reference" detail={reference} />
                            <DetailTextCell xs={4} header="Value Date" detail={valueDate} />
                        </Grid>
                    </Box>
                </Paper>
            </Grid>

            {/* Bank Details */}
            <Grid item xs={12} md={6}>
                <Paper elevation={6} sx={{ p: 2, display: "flex", flexDirection: "column", height: "100%" }}>
                    <Box sx={{ pb: 1 }}>
                        <Grid container>
                            <DetailTextCell header="Amount" detail={`${currencyCode} ${amount}`} />
                            <DetailTextCell header="Account Number" detail={accountNumber} />
                            <DetailTextCell xs={12} header="Account Name" detail={accountName} />
                        </Grid>
                    </Box>
                    <Divider />
                    <Box sx={{ pt: 1 }}>
                        <Grid container>
                            <DetailTextCell header="Authorized By" detail={authorisedBy} />
                            <DetailTextCell header="Intructed On" detail={moment(instructedOn).format("llll")} />
                            <DetailTextCell header="IBAN" detail={iban} />
                            <DetailTextCell header="BIC" detail={bic} />
                        </Grid>
                    </Box>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default PaymentDetail;