import { createSlice } from "@reduxjs/toolkit";
import { TaskPieChartFilter } from "components/tasks/constants";
import { CONTINENT_GROUP } from "helpers/regions";

export const INITIAL_MAP_CHART_TOOLTIP_STATE = {
    name: '',
    data: {
        pending: 0,
        completed: 0,
        overdue: 0,
    },
};

export const INITIAL_TASK_CHART_FILTERS = {
    user: '',
    country: '',
    region: '',
    continent: CONTINENT_GROUP.GENERAL,
    year: '',
    quarter: '',
    month: '',
    filingStatus: '',
    entity: '',
    isArchived: false,
};

const initialState = {
    taskList: [],
    taskDetail: {},
    taskCountPerCountry: [],
    pageFilters: INITIAL_TASK_CHART_FILTERS,
    mapChartTooltip: INITIAL_MAP_CHART_TOOLTIP_STATE,
    mapChartTab: 0,
    pieChartTab: TaskPieChartFilter.Filing,
    isPaymentHubHidden: true,
};

const tasksSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {
        setTaskList: (state, action) => {
            state.taskList = action.payload.taskList
        },
        setTaskDetail: (state, action) => {
            state.taskDetail = action.payload.taskDetail
        },
        setTaskCountPerCountry: (state, action) => {
            state.taskCountPerCountry = action.payload.taskCountPerCountry
        },
        setMapChartTooltip: (state, action) => {
            const { name, data } = action.payload.mapChartTooltip
            state.mapChartTooltip = { name, data }
        },
        setTaskPageFilters: (state, action) => {
            const currentFilters = state.pageFilters
            const updatedFilters = action.payload.pageFilters
            state.pageFilters = { ...currentFilters, ...updatedFilters }
        },
        setMapChartTab: (state, action) => {
            state.mapChartTab = action.payload.mapChartTab
        },
        setPieChartTab: (state, action) => {
            state.pieChartTab = action.payload.pieChartTab
        },
        setIsPaymentHubHidden: (state, action) => {
            state.isPaymentHubHidden = action.payload.isPaymentHubHidden
        },
    }
});

export const {
    setTaskList,
    setTaskDetail,
    setTaskCountPerCountry,
    setTaskPageFilters,
    setMapChartTooltip,
    setMapChartTab,
    setPieChartTab,
    setIsPaymentHubHidden,
} = tasksSlice.actions;

export default tasksSlice;