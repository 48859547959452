import { Box, Button, Container, CssBaseline, Grid, TextField, Typography } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import ChangePasswordModal from "components/settings/ChangePasswordModal"
import LoginTwoFactorAuthModal from "components/settings/LoginTwoFactorAuthModal"
import { AuthContext } from "helpers/auth"
import { isEmpty } from "lodash-es"
import { useContext, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { theme } from "theme"

const LoginPage = () => {
  const auth = useContext(AuthContext)
  const navigate = useNavigate()
  const location = useLocation()

  const [loginError, setLoginError] = useState(false)
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);
  const [twoFactorAuthModalProps, setTwoFactorAuthModalProps] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault()
    const data = new FormData(e.currentTarget)
    const email = data.get("email")
    const password = data.get("password")

    // Redirect to index (task page) after signing in
    await auth.signin(email, password, (loginSuccess, firstPage, otpEnabled) => {
      const from = location.state?.from?.pathname || firstPage || "/";
      if (loginSuccess) {
        if (otpEnabled) {
          setTwoFactorAuthModalProps({ from });
        } else {
          navigate(from, { replace: true });
        }
      } else {
        setLoginError(true);
      }
    })
  }

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />

        {/* Image on left part */}
        <Grid item xs={false} sm={4} md={7}
          sx={{
            backgroundImage: "url(/ceg_background.jpg)",
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) => t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />

        {/* Login part */}
        <Box component="main" sx={{ alignItems: "center", display: "flex", flexGrow: 1, minHeight: "100%" }}>
          <Container maxWidth="sm" component="form" noValidate onSubmit={handleSubmit}>
            <Box sx={{ my: 3 }}>
              <img src="/inkarnate_logo.svg" width="250px" alt="" />
              {/* <img src="/ceg_logo.png" width="250px" alt="" /> */}
              {/* <img src="/smile_logo.png" width="250px" alt="" /> */}
              <Typography color="textPrimary" variant="h4">Sign in</Typography>
              <Typography color="textSecondary" gutterBottom variant="body2">
                Sign in on the internal platform
              </Typography>
            </Box>

            {/* Email field */}
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              error={loginError}
              helperText={loginError ? "Incorrect email or password." : ""}
            />

            {/* Password field */}
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              error={loginError}
              helperText={loginError ? "Incorrect email or password." : ""}
            />

            {/* Submit button */}
            <Box sx={{ py: 2 }}>
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Sign In Now
              </Button>
            </Box>

            {/* Change password */}
            <Box sx={{ py: 2, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Button
                color="primary"
                variant="text"
                onClick={() => setIsForgotPasswordModalOpen(true)}
              >
                Change password
              </Button>
            </Box>
          </Container>
        </Box>
        {isForgotPasswordModalOpen && (
          <ChangePasswordModal
            onClose={() => setIsForgotPasswordModalOpen(false)}
          />
        )}
        {!isEmpty(twoFactorAuthModalProps) && (
          <LoginTwoFactorAuthModal
            {...twoFactorAuthModalProps}
            onClose={() => setTwoFactorAuthModalProps({})}
          />
        )}
      </Grid>
    </ThemeProvider>
  )
}

export default LoginPage
