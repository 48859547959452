import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    customerList: [],
};

const customerSlice = createSlice({
    name: 'customer',
    initialState,
    reducers: {
        setCustomerList: (state, action) => {
            state.customerList = action.payload.customerList
        },
    }
});

export const {
    setCustomerList,
} = customerSlice.actions;

export default customerSlice;