import "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Fragment, useMemo } from 'react';

import { PaymentStatus, PaymentStatusColorMapping, PaymentStatusLabelMapping } from 'components/tasks/constants';
import { Doughnut } from 'react-chartjs-2';

const PaymentStatusPieChart = ({ data }) => {
    const datasetLabels = [
        PaymentStatusLabelMapping[PaymentStatus.Pending],
        PaymentStatusLabelMapping[PaymentStatus.Completed],
        PaymentStatusLabelMapping[PaymentStatus.Canceled],
    ];

    const paymentStatusCount = useMemo(() => {
        const donePayments = [];
        const pendingPayments = [];
        const cancelledPayments = [];
        data.forEach((task) => {
            switch (task.paymentStatus) {
                case PaymentStatus.Completed: {
                    donePayments.push(task);
                    break;
                }
                case PaymentStatus.Pending: {
                    pendingPayments.push(task);
                    break;
                }
                case PaymentStatus.Canceled: {
                    cancelledPayments.push(task);
                    break;
                }
                default:
                    break;
            }
        })
        return [pendingPayments.length, donePayments.length, cancelledPayments.length];
    }, [data]);

    const options = {
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle'
                },
                showTooltips: false
            },
            tooltip: {
                enabled: false
            },
            title: {
                display: false
            },
            datalabels: {
                // formatter: (value, context) => {
                //     return value ? datasetLabels[context.dataIndex] + "\n" + value : ""
                // },
                color: "white",
                textAlign: "center"
            },
        },
    };

    const paymentStatusData = {
        labels: datasetLabels,
        datasets: [{
            label: "Payment Status",
            data: paymentStatusCount,
            backgroundColor: [
                PaymentStatusColorMapping[PaymentStatus.Pending],
                PaymentStatusColorMapping[PaymentStatus.Completed],
                PaymentStatusColorMapping[PaymentStatus.Canceled],
            ]
        }],
    }

    const renderEmptyPieChart = () => (
        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            No payment data found.
        </div>
    )

    return (
        <Fragment>
            {paymentStatusCount[0] === 0 && paymentStatusCount[1] === 0 && paymentStatusCount[2] === 0 ? (
                renderEmptyPieChart()
            ) : (
                <Doughnut
                    data={paymentStatusData}
                    plugins={[ChartDataLabels]}
                    options={options}
                />
            )}
        </Fragment>
    );
}

export default PaymentStatusPieChart;
