import { configureStore } from "@reduxjs/toolkit";

import commonSlice from "./slices/commonSlice";
import customerSlice from "./slices/customerSlice";
import filesSlice from "./slices/filesSlice";
import filingsSlice from "./slices/filingsSlice";
import monexSlice from "./slices/monexSlice";
import obligationsSlice from "./slices/obligationsSlice";
import tasksSlice from "./slices/tasksSlice";

const store = configureStore({
    reducer: {
        common: commonSlice.reducer,
        customer: customerSlice.reducer,
        monex: monexSlice.reducer,
        tasks: tasksSlice.reducer,
        filings: filingsSlice.reducer,
        files: filesSlice.reducer,
        obligations: obligationsSlice.reducer,
    }
});

export default store;
