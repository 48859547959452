import { Box, Grid, Paper, Table, TableBody, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import DeleteDialog from "components/common/DeleteDialog"
import { StyledTableCell, StyledTableRow } from "components/common/StyledTable"
import PropertyMap from "components/properties/PropertyMap"
import PropertyModal from "components/properties/PropertyModal"
import { AuthContext } from "helpers/auth"
import moment from "moment"
import { useContext, useEffect, useState } from "react"

const PropertyPage = () => {
  const [properties, setProperties] = useState([])
  const [updated, setUpdated] = useState(0)
  const auth = useContext(AuthContext)
  const toggleUpdate = () => setUpdated(!updated)

  const deleteProperty = async (property) => {
    await fetch(`${process.env.REACT_APP_API_PROXY}/api/properties/${encodeURIComponent(property._id)}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth.user.token
      }
    })
    setProperties((properties) => properties.filter((p) => p._id !== property._id))
  }

  useEffect(() => {
    if (auth.user) {
      const fetchPropertiesData = async () => {
        const propertyResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/properties`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + auth.user.token
          }
        })
        const response = await propertyResponse.json()
        setProperties(response)
      }

      fetchPropertiesData()
    }
  }, [updated, auth])

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", flexWrap: "wrap", m: -1 }}>
          <Typography sx={{ m: 1 }} variant="h4">Real Estate</Typography>
          <Box sx={{ m: 1 }}>
            <PropertyModal actions={{ toggleUpdate }}/>
          </Box>
        </Box>
      </Grid>

      {/* Property map */}
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: "flex", flexDirection: "column", height: "100%" }}>
          <PropertyMap properties={properties} />
        </Paper>
      </Grid>

      {/* Property table */}
      <Grid item xs={12}>
        <TableContainer component={Paper} sx={{ height: "100%" }} >
          <Table sx={{border: "1px grey"}}>
            <colgroup>
              <col style={{width:"12%"}} />
              <col style={{width:"30%"}} />
              <col style={{width:"6%"}} />
              <col style={{width:"6%"}} />
              <col style={{width:"6%"}} />
              <col style={{width:"6%"}} />
              <col style={{width:"6%"}} />
              <col style={{width:"6%"}} />
              <col style={{width:"6%"}} />
              <col style={{width:"6%"}} />
              <col style={{width:"10%"}} />
            </colgroup>
            <TableHead>
              <TableRow>
                <StyledTableCell>Name</StyledTableCell>
                <StyledTableCell>Address</StyledTableCell>
                <StyledTableCell>Property Type</StyledTableCell>
                <StyledTableCell>Coordinates</StyledTableCell>
                <StyledTableCell>Market Value</StyledTableCell>
                <StyledTableCell>Book Value</StyledTableCell>
                <StyledTableCell>Listing Status</StyledTableCell>
                <StyledTableCell>Area (sq. ft.)</StyledTableCell>
                <StyledTableCell>Price/Area (USD/sq. ft.)</StyledTableCell>
                <StyledTableCell>Acquisition Date</StyledTableCell>
                <StyledTableCell align="center">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {properties ? properties.map(property => (
                <StyledTableRow key={property._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <StyledTableCell sx={{ fontWeight: "bold" }}>{property.name}</StyledTableCell>
                  <StyledTableCell>{property.address}</StyledTableCell>
                  <StyledTableCell>{property.propertyType}</StyledTableCell>
                  <StyledTableCell>{property.coords?.lat?.toFixed(6)}, {property.coords?.lng?.toFixed(6)}</StyledTableCell>
                  <StyledTableCell>${property.marketValue}</StyledTableCell>
                  <StyledTableCell>${property.bookValue}</StyledTableCell>
                  <StyledTableCell>{property.listingStatus}</StyledTableCell>
                  <StyledTableCell>{property.area}</StyledTableCell>
                  <StyledTableCell>${property.pricePerArea}</StyledTableCell>
                  <StyledTableCell>{moment(property.acquisitionDate).format("YYYY-MM-DD")}</StyledTableCell>
                  <StyledTableCell align="center">
                    <PropertyModal property={property} actions={{ toggleUpdate }} />
                    <DeleteDialog objectName={"property"} deleteFunction={() => deleteProperty(property)} />
                  </StyledTableCell>
                </StyledTableRow>
              )) : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  )
}

export default PropertyPage
