import { Delete, Download } from "@mui/icons-material";
import { ButtonGroup, Paper, Table, TableBody, TableContainer, TableHead, TableRow } from "@mui/material";

import ButtonWithTooltip from "components/common/ButtonWithTooltip";
import ConfirmFileDeleteDialog from "components/common/ConfirmFileDeleteDialog";
import { StyledTableCell, StyledTableRow } from "components/common/StyledTable";
import { MONTHS } from "helpers/constants";
import { COUNTRY_CODE_TO_NAME } from "helpers/countries";
import useFilesHelper from "hooks/useFilesHelper";
import useGeographicNameHelper from "hooks/useGeographicNameHelper";
import { isEmpty } from "lodash-es";
import moment from "moment";
import { useState } from "react";

const FilesTable = ({ data, onDeleteClick }) => {
    const [fileToDelete, setFileToDelete] = useState({});

    const { getStateOrProvinceName } = useGeographicNameHelper();
    const { handleDeleteFile, handleDownloadFile } = useFilesHelper();

    const onDeleteFile = async (file) => {
        const onClick = () => {
            const updatedFiles = data.filter((f) => f._id !== file._id);
            onDeleteClick?.(updatedFiles);
            setFileToDelete({});
        }

        handleDeleteFile(file, onClick);
    };

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>File Name</StyledTableCell>
                        <StyledTableCell>Date Uploaded</StyledTableCell>
                        <StyledTableCell>Region</StyledTableCell>
                        <StyledTableCell>Country</StyledTableCell>
                        <StyledTableCell>Province/State</StyledTableCell>
                        <StyledTableCell>Legal Entity</StyledTableCell>
                        <StyledTableCell>Year</StyledTableCell>
                        <StyledTableCell>Quarter</StyledTableCell>
                        <StyledTableCell>Month</StyledTableCell>
                        <StyledTableCell/>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {data?.map((row) => {
                        const {
                            _id: filingId,
                            filename,
                            createdAt,
                            continentCode,
                            countryCode,
                            geographicalRegionCode,
                            entity,
                            year,
                            quarter,
                            month,
                        } = row;

                        return (
                            <StyledTableRow
                                key={filingId}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <StyledTableCell>{filename}</StyledTableCell>
                                <StyledTableCell>{moment(createdAt).format("YYYY-MM-DD HH:mm:ss")}</StyledTableCell>
                                <StyledTableCell>{continentCode}</StyledTableCell>
                                <StyledTableCell>{COUNTRY_CODE_TO_NAME[countryCode]}</StyledTableCell>
                                <StyledTableCell>{getStateOrProvinceName({ countryCode, geographicalRegionCode }) ?? '-'}</StyledTableCell>
                                <StyledTableCell>{entity?.name ?? '-'}</StyledTableCell>
                                <StyledTableCell>{year}</StyledTableCell>
                                <StyledTableCell>{quarter}</StyledTableCell>
                                <StyledTableCell>{MONTHS[month] ?? '-'}</StyledTableCell>
                                <StyledTableCell align="right">
                                    <ButtonGroup>
                                        <ButtonWithTooltip
                                            onClick={() => handleDownloadFile(row)}
                                            tooltipText="Download file"
                                            variant="outlined"
                                        >
                                            <Download />
                                        </ButtonWithTooltip>
                                        <ButtonWithTooltip
                                            onClick={() => setFileToDelete(row)}
                                            tooltipText="Delete file"
                                            variant="outlined"
                                        >
                                            <Delete />
                                        </ButtonWithTooltip>
                                    </ButtonGroup>
                                </StyledTableCell>
                            </StyledTableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <ConfirmFileDeleteDialog
                open={!isEmpty(fileToDelete)}
                onClose={() => setFileToDelete({})}
                onDelete={() => onDeleteFile(fileToDelete)}
            />
        </TableContainer>
    );
}

export default FilesTable;