import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Grid, Typography } from "@mui/material";

import useMonexApiHelper from "hooks/useMonexApiHelper";
import { selectThirdPartyBankAccountList } from "redux/slices/selectors";

import TableLoader from "components/common/TableLoader";
import BankAccountsTable from "../BankAccountsTable";

const ThirdPartyAccountsPage = () => {
    const navigate = useNavigate();

    const thirdPartyBankAccountList = useSelector(selectThirdPartyBankAccountList);

    const isPageLoading = useMemo(() => thirdPartyBankAccountList?.length === 0, [thirdPartyBankAccountList?.length]);

    const { fetchThirdPartyBankAccountList } = useMonexApiHelper();

    useEffect(() => {
        if (thirdPartyBankAccountList?.length === 0) {
            fetchThirdPartyBankAccountList();
        }
    }, [fetchThirdPartyBankAccountList, thirdPartyBankAccountList?.length]);

    const handlePaymentDetailRedirect = (paymentId) => {
        navigate(`/third-party-account/${paymentId}`);
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography sx={{ m: 1 }} variant="h4">Third Party Bank Accounts</Typography>
            </Grid>

            <Grid item xs={12}>
                {isPageLoading ? (
                    <TableLoader />
                ) : (
                    <BankAccountsTable data={thirdPartyBankAccountList} onViewClick={handlePaymentDetailRedirect} />
                )}
            </Grid>
        </Grid>
    );
};

export default ThirdPartyAccountsPage;