import { Grid, Typography } from "@mui/material";

const DetailTextRow = ({ xs = 12, header, detail, detailElement }) => {
    return (
        <Grid item xs={xs} sx={{ marginBottom: '8px' }}>
            <Grid container>
                <Grid item xs={6}>
                    <Typography
                        sx={{
                            fontWeight: 500,
                            fontSize: '12px',
                            color: '#6F7378',
                            textTransform: 'uppercase',
                        }}
                    >
                        {header}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    {detailElement || (
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: '14px',
                            }}
                        >
                            {detail ?? '-'}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default DetailTextRow;
