import { Box, Button, Divider, FormControl, FormHelperText, Grid, Modal, TextField, Typography } from "@mui/material";
import { AuthContext } from "helpers/auth";
import { ErrorMessage } from "helpers/validations";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { classes } from "./styles";

const LoginTwoFactorAuthModal = ({ from, onClose }) => {
    const auth = useContext(AuthContext);
    const navigate = useNavigate();
    const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange' });

    const onSubmit = async (data) => {
        const { authCode } = data ?? {};
        const body = {
            token: authCode,
        };

        try {
            const userResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/users/2fa/validate/${encodeURIComponent(auth?.user?._id)}`, {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + auth.user.token
                },
                body: JSON.stringify(body),
            });

            const response = await userResponse.json();
            if (userResponse.status === 200) {
                onClose();
                navigate(from, { replace: true });
            } else if (userResponse.status === 401) {
                toast.error(response.message, { autoClose: 7500 });
            }
          } catch (error) {
                toast.error(`Error encountered: ${error.message}`, { autoClose: 7500 });
          }
    };

    return (
        <Modal open onClose={onClose}>
            <Box sx={classes.modal}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography sx={classes.modalHeader}>
                            TWO-FACTOR AUTHENTICATION (2FA)
                        </Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Box sx={classes.verifySection}>
                            <Typography>
                                Enter the code from Microsoft Authenticator to log in.
                            </Typography>
                            <FormControl sx={{ width: '100%' }}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    label="Authentication Code"
                                    {...register("authCode", { required: ErrorMessage.Required })}
                                    error={Boolean(errors.authCode)}
                                />
                                {errors.authCode && (
                                    <FormHelperText error>
                                        {errors.authCode.message}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid xs={12} sx={classes.buttons}>
                        <Button variant="outlined" onClick={onClose}>
                            CLOSE
                        </Button>
                        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                            LOG IN
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
};

export default LoginTwoFactorAuthModal;
