import { Chip } from "@mui/material";
import { FilingStatusColorMapping, FilingStatusLabelMapping } from "../constants";

const FilingStatusCell = ({ status }) => {
    return (
        <Chip
            sx={{
                minWidth: "100px",
                background: FilingStatusColorMapping[status],
                color: 'white',
                fontWeight: 600,
            }}
            label={FilingStatusLabelMapping[status]}
        />
    )
};

export default FilingStatusCell;
