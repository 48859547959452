import moment from "moment";

import { CustomerSecionLabelMapping } from "components/customers/constants";
import { PHASES, STATUSES, TYPES } from "helpers/constants";
import { COUNTRY_CODE_TO_CURRENCY_CODE, COUNTRY_NAME_TO_COUNTRY_CODES } from "helpers/countries";
import useGeographicNameHelper from "./useGeographicNameHelper";

const useTaskModal = ({ obligation = {}, task = {} }) => {
    const { getContinentCode } = useGeographicNameHelper();
    const {
        country,
        countryCode = COUNTRY_NAME_TO_COUNTRY_CODES[country],
        continentCode = getContinentCode({ countryCode }),
        geographicalRegionCode,
        name = '',
        _id: obligationId,
    } = obligation;

    const currencyCode = COUNTRY_CODE_TO_CURRENCY_CODE[countryCode];

    const defaultValues = {
        name,
        obligation: obligationId,
        countryCode,
        continentCode,
        geographicalRegionCode,
        phase: PHASES[0],
        description: '',
        period: 'Annual',
        deadlineDate: moment(new Date()).format("YYYY-MM-DD"),
        taskType: TYPES[0],
        status: STATUSES[0],
        clientReference: '',
        paymentPurpose: '',
        paymentStatus: task.paymentStatus ?? "",
        ...task,
        entity: task.entity?._id ?? '',
        assignedUsers: task.assignedUsers?.map(user => user._id) ?? [],
        managingPartner: task.managingPartner ?? {},
        contactPerson: task.contactPerson ?? {},
        section: CustomerSecionLabelMapping[task.section] ?? task.section,
    }

    return {
        defaultValues,
        currencyCode,
    };
};

export default useTaskModal;