import { useCallback, useContext, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Edit as EditIcon, ReceiptLong } from "@mui/icons-material";
import { Box, ButtonGroup, IconButton, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { selectIsPaymentHubHidden } from "redux/slices/selectors";

import ButtonWithTooltip from "components/common/ButtonWithTooltip";
import PaymentReceiptModal from "components/payments/PaymentReceipt/PaymentReceiptModal";
import FilingStatusCell from "components/tasks/FilingStatusCell";
import PaymentStatusPill from "components/tasks/PaymentStatusCell";
import TaskPaymentModal from "components/tasks/TaskPaymentModal";
import TradeStatusPill from "components/tasks/TradeStatusCell";
import { AuthContext } from "helpers/auth";
import { MONTHS_LABEL_MAPPING } from "helpers/constants";
import useGeographicNameHelper from "hooks/useGeographicNameHelper";
import useMonexApiHelper from "hooks/useMonexApiHelper";
import usePaymentModal from "hooks/usePaymentModal";
import usePaymentReceiptHelper from "hooks/usePaymentReceiptHelper";
import useTradeModal from "hooks/useTradeModal";
import { setTaskDetail } from "redux/slices/tasksSlice";

import "../../../common/StyledTable/styles.css";

const FETCH_PAYMENT_TOAST_ID = 'fetch-payment-toast-id';

const PaymentHubActions = ({ task, onTradeModalToggle }) => {
    const auth = useContext(AuthContext);
    const { tradeTooltipText, isTradeDisabled } = useTradeModal({ task });
    const { paymentTooltipText, isPaymentDisabled } = usePaymentModal({ task });

    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [openReceiptModal, setOpenReceiptModal] = useState(false);

    const { fetchThirdPartyBankAccountList, fetchBalanceList } = useMonexApiHelper();
    const { fetchPaymentReceiptData } = usePaymentReceiptHelper();

    const showErrorSnackbar = (message) => toast.error(`Encountered an error: ${message}`, { autoClose: 5000 });

    const handleOpenPayment = async () => {
        const spotDate = moment().add(2, 'day').endOf('day').format("YYYY-MM-DD");
        toast('Fetching payment data...', { autoClose: false,  toastId: FETCH_PAYMENT_TOAST_ID });
        const fetchThirdPartyBankAccountListResponse = await fetchThirdPartyBankAccountList();
        const fetchBalanceListResponse = await fetchBalanceList({ date: spotDate });
        toast.done(FETCH_PAYMENT_TOAST_ID);
        if (fetchBalanceListResponse?.message) {
            showErrorSnackbar(fetchBalanceListResponse.message);
        } else if (fetchThirdPartyBankAccountListResponse?.message) {
            showErrorSnackbar(fetchThirdPartyBankAccountListResponse.message);
        } else {
            setOpenPaymentModal(true);
        }
    }

    const handleClosePayment = () => {
        setOpenPaymentModal(false);
    }

    const handleOpenReceiptModal = async () => {
        await fetchPaymentReceiptData({
            taskId: task._id,
            tradeId: task.tradeId,
            paymentId: task.paymentId,
            auth,
            onOpenReceiptModal: () => setOpenReceiptModal(true),
        });
    }

    const handleCloseReceiptModal = () => {
        setOpenReceiptModal(false);
    }

    return (
        <Box sx={{ display: 'flex', width: '260px', boxSizing: 'border-box', gap: '5px', alignItems: 'center', justifyContent: 'flex-end' }}>
            <ButtonWithTooltip
                tooltipText={tradeTooltipText}
                onClick={() => onTradeModalToggle(task)}
                disabled={isTradeDisabled}
            >
                TRADE
            </ButtonWithTooltip>
            <ButtonWithTooltip
                tooltipText={paymentTooltipText}
                onClick={handleOpenPayment}
                disabled={isPaymentDisabled}
            >
                PAY
            </ButtonWithTooltip>
            <ButtonGroup variant="outlined">
                <ButtonWithTooltip
                    tooltipText="Generate payment receipt"
                    variant="outlined"
                    onClick={handleOpenReceiptModal}
                >
                    <ReceiptLong color="inherit" />
                </ButtonWithTooltip>
            </ButtonGroup>
            {openPaymentModal && <TaskPaymentModal task={task} onClose={handleClosePayment} />}
            {openReceiptModal && <PaymentReceiptModal onClose={handleCloseReceiptModal} />}
        </Box>
    )
}

const TaskTable = ({ data, onTradeModalToggle }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isPaymentHubHidden = useSelector(selectIsPaymentHubHidden);

    const { getStateOrProvinceName, getContinentCode } = useGeographicNameHelper();

    const handleEditTaskClick = useCallback((task) => {
        dispatch(setTaskDetail({ taskDetail: task }));
        navigate(`/tasks/${task?._id}`);
    }, [dispatch, navigate]);

    const paymentHubColumns = useMemo(() => (!isPaymentHubHidden ? [
        {
            field: "tradeStatus",
            headerName: "Trade Status",
            headerClassName: "styled-header",
            minWidth: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <TradeStatusPill status={params.value} />
            ),
        },
        {
            field: "paymentDeadline",
            headerName: "Payment Deadline",
            headerClassName: "styled-header",
            minWidth: 150,
            align: "center",
            headerAlign: "center",
        },
        {
            field: "paymentStatus",
            headerName: "Payment Status",
            headerClassName: "styled-header",
            minWidth: 135,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <PaymentStatusPill status={params.value} />
            ),
        },
        {
            field: "paymentHubActions",
            headerName: "Actions",
            headerClassName: "styled-header",
            minWidth: 250,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <PaymentHubActions
                    task={params.row}
                    onTradeModalToggle={onTradeModalToggle}
                />
            ),
        },
    ] : []), [isPaymentHubHidden, onTradeModalToggle])

    const taskColumns = useMemo(() => ([
        {
            field: "action",
            headerName: "",
            sortable: false,
            width: 50,
            renderCell: (params) => (
                <Tooltip title="Edit task" placement="top" arrow>
                    <IconButton onClick={() => handleEditTaskClick(params.row)}>
                        <EditIcon color="inherit" />
                    </IconButton>
                </Tooltip>
            ),
            headerClassName: "styled-header",
        },
        {
            field: "name",
            headerName: "Name",
            minWidth: 250,
            headerClassName: "styled-header",
            flex: 2,
        },
        {
            field: "status",
            headerName: "Filing Status",
            headerClassName: "styled-header",
            minWidth: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                <FilingStatusCell status={params.value} />
            ),
        },
        {
            field: "deadlineDate",
            headerName: "Deadline Date",
            headerClassName: "styled-header",
            minWidth: 120,
            align: "center",
            headerAlign: "center",
            renderCell: (params) => (
                moment(params.value).format("YYYY-MM-DD")
            ),
        },
        {
            field: "region",
            headerName: "Region",
            headerClassName: "styled-header",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => {
                const continentCode = params.row.obligation?.continentCode;
                const countryCode = params.row.obligation?.countryCode;
                return (
                    continentCode ?? getContinentCode({ countryCode: countryCode })
                );
            },
        },
        {
            field: "country",
            headerName: "Country",
            headerClassName: "styled-header",
            align: "center",
            headerAlign: "center",
            minWidth: 180, //120
            renderCell: (params) => {
                const country = params.row.obligation?.country;
                return country;
            },
        },
        {
            field: "geographicalRegionCode",
            headerName: "Province/State",
            headerClassName: "styled-header",
            align: "center",
            headerAlign: "center",
            minWidth: 150,
            renderCell: (params) => {
                const geographicalRegionCode = params.row.obligation?.geographicalRegionCode;
                const countryCode = params.row.obligation?.countryCode;
                return (
                    getStateOrProvinceName({ countryCode, geographicalRegionCode }) ?? '-'
                );
            },
        },
        {
            field: "serviceProvider",
            headerName: "Service Provider",
            headerClassName: "styled-header",
            align: "center",
            headerAlign: "center",
            minWidth: 250,
            renderCell: (params) => params.row.serviceProvider ?? '-',
        },
        {
            field: "entity",
            headerName: "Legal Entity",
            headerClassName: "styled-header",
            align: "center",
            headerAlign: "center",
            minWidth: 250, //120
            renderCell: (params) => params.row.entity?.name ?? '-',
        },
        {
            field: "year",
            headerName: "Year",
            headerClassName: "styled-header",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => params.value ?? '-',
        },
        {
            field: "quarter",
            headerName: "Quarter",
            headerClassName: "styled-header",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => params.value ?? '-',
        },
        {
            field: "month",
            headerName: "Month",
            headerClassName: "styled-header",
            align: "center",
            headerAlign: "center",
            renderCell: (params) => MONTHS_LABEL_MAPPING[params.value] ?? '-',
        },
        ...paymentHubColumns,
    ]), [getContinentCode, getStateOrProvinceName, handleEditTaskClick, paymentHubColumns]);

    return data && (
        <DataGrid
            columns={taskColumns}
            rows={data}
            disableSelectionOnClick
            getRowId={(row) => row._id}
            getRowClassName={() => "styled-row"}
            sx={{
                '.MuiDataGrid-columnSeparator': {
                    display: 'none',
                },
            }}
        />
    );
}

export default TaskTable;
