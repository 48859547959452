import "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Fragment, useMemo } from 'react';

import { TaskStatus, TaskStatusColorMapping, TaskStatusLabelMapping } from 'components/tasks/constants';
import { Doughnut } from 'react-chartjs-2';

const FilingStatusPieChart = ({ data }) => {
    const datasetLabels = [
        TaskStatusLabelMapping[TaskStatus.Pending],
        TaskStatusLabelMapping[TaskStatus.Done],
        TaskStatusLabelMapping[TaskStatus.Overdue]
    ];

    const taskCount = useMemo(() => {
        const doneFilings = [];
        const pendingFilings = [];
        const overdueFilings = [];
        data.forEach((task) => {
            switch (task.status) {
                case TaskStatus.Done: {
                    doneFilings.push(task);
                    break;
                }
                case TaskStatus.Pending: {
                    pendingFilings.push(task);
                    break;
                }
                case TaskStatus.Overdue: {
                    overdueFilings.push(task);
                    break;
                }
                default:
                    break;
            }
        })
        return [pendingFilings.length, doneFilings.length, overdueFilings.length];
    }, [data]);

    const options = {
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle'
                },
                showTooltips: false
            },
            tooltip: {
                enabled: false
            },
            title: {
                display: false
            },
            datalabels: {
                // formatter: (value, context) => {
                //     return value ? datasetLabels[context.dataIndex] + "\n" + value : ""
                // },
                color: "white",
                textAlign: "center",
            },
        },
    };

    const taskStatusData = {
        labels: datasetLabels,
        datasets: [{
            label: "Filing Status",
            data: taskCount,
            backgroundColor: [
                TaskStatusColorMapping[TaskStatus.Pending],
                TaskStatusColorMapping[TaskStatus.Done],
                TaskStatusColorMapping[TaskStatus.Overdue],
            ]
        }],
    }

    return (
        <Fragment>
            <Doughnut
                data={taskStatusData}
                plugins={[ChartDataLabels]}
                options={options}
            />
        </Fragment>
    );
}

export default FilingStatusPieChart;
