import { useState, useContext, useCallback } from "react"
import { Typography, Card, CardContent, Button, Box } from "@mui/material"
import { useDropzone } from "react-dropzone"
import { AuthContext } from "helpers/auth"

const ImportDropzone = ({ actions }) => {
  const [uploadFiles, setUploadFiles] = useState([])

  const onDrop = useCallback(acceptedFiles => {
    setUploadFiles([...uploadFiles, ...acceptedFiles])
  }, [uploadFiles])
  
  const { getRootProps, getInputProps } = useDropzone({ onDrop })
  const auth = useContext(AuthContext)

  const handleSubmit = async (e) => {
    e.preventDefault()

    uploadFiles.map(async (file) => {
      let data = new FormData()
      data.append("xlsx", file)
      
      const uploadResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/import`, {
        method: "POST",
        credentials: "include",
        headers: { "Authorization": "Bearer " + auth.user.token },
        body: data
      })
      const importData = await uploadResponse.json()

      if (uploadResponse.status === 200) {
        setUploadFiles([])
        actions.setImportObligations(importData.obligations)
        actions.setImportTasks(importData.tasks)
      }
    })    
  }

  const files = uploadFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ))

  return (
    <Card elevation={12}>
      <CardContent>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Typography sx={{ fontSize: 14, border: "1px dashed", p: 1, mb: 1 }} color="text.secondary" gutterBottom {...getRootProps({className: 'dropzone'})}>
            <input {...getInputProps()} />
            Drag file here, or click to select file
          </Typography>

          <Typography sx={{ fontSize: 14, mb: 3 }} color="text.primary" gutterBottom {...getRootProps({className: 'dropzone'})}>
            {files}
          </Typography>

          <Button variant="contained" type="submit">Upload</Button>
        </Box>
      </CardContent>
    </Card>
  )
}

export default ImportDropzone
