export const CREATE_TRADE_MODAL_STEPS = [
    {
        label: 'Amount',
        title: 'ENTER TRADE DETAILS FOR',
    },
    {
        label: 'Confirm',
        title: 'CONFIRM RATE FOR',
    },
];

export const COOLDOWN_DURATION_IN_SEC = 10;