
import { Archive, FileDownload, FilterListOff, Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, ButtonGroup, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import ButtonWithTooltip from "components/common/ButtonWithTooltip";
import { TaskStatus, TaskStatusLabelMapping } from "components/tasks/constants";
import { AuthContext } from "helpers/auth";
import { MONTH_MAPPING_PER_QUARTER, QUARTERS, YEARS } from "helpers/constants";

import { COUNTRY_CODE_TO_NAME } from "helpers/countries";
import generateXlsx from "helpers/excel";
import { CANADA_PROVINCE_CODE_TO_NAME, CANADA_PROVINCE_LIST, CONTINENT_GROUP, CONTINENT_GROUP_COUNTRIES_MAPPING, CONTINENT_GROUP_ORDER, US_STATES_LIST, US_STATE_CODE_TO_NAME } from "helpers/regions";
import { UsersContext } from "helpers/users";
import useTaskHelper from "hooks/useTaskHelper";
import { useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectIsPaymentHubHidden, selectMapChartTab, selectTaskPageFilters } from "redux/slices/selectors";
import { INITIAL_TASK_CHART_FILTERS, setIsPaymentHubHidden, setMapChartTab } from "redux/slices/tasksSlice";

const TaskMapFilters = ({ data = [], handleFilterChange }) => {
    const dispatch = useDispatch();
    const users = useContext(UsersContext);
    const auth = useContext(AuthContext);

    const isPaymentHubHidden = useSelector(selectIsPaymentHubHidden);
    const pageFilters = useSelector(selectTaskPageFilters);
    const {
        continent: continentFilter,
        country: countryFilter,
        region: regionFilter,
        year: yearFilter,
        quarter: quarterFilter,
        month: monthFilter,
        user: userFilter,
        filingStatus: filingStatusFilter,
        entity: entityFilter,
    } = useMemo(() => pageFilters ?? {}, [pageFilters]);
    const activeTab = useSelector(selectMapChartTab);

    const regionFilterLabel = countryFilter === 'US' ? 'States' : 'Provinces';
    const shouldShowRegionFilter = useMemo(() => countryFilter === 'US' || countryFilter === 'CA', [countryFilter]);

    const { handleArchivePaidTasks } = useTaskHelper();

    return (
        <Grid item xs={12}>
            <Grid container spacing={2}>
                {/* Region */}
                <Grid item xs={2}>
                    <FormControl style={{ width: "100%" }}>
                        <InputLabel shrink>
                            Region
                        </InputLabel>
                        <Select
                            disabled={activeTab === 1 || activeTab === 2}
                            displayEmpty
                            notched
                            label="Region"
                            value={continentFilter}
                            onChange={(e) => {
                                handleFilterChange({
                                    continent: e.target.value,
                                    country: '',
                                    region: '',
                                })
                            }}
                        >
                            <MenuItem key={"All Regions"} value={CONTINENT_GROUP.GENERAL}>
                                All Regions
                            </MenuItem>
                            {CONTINENT_GROUP_ORDER.map(continentCode => {
                                return (
                                    <MenuItem key={continentCode} value={continentCode}>
                                        {continentCode}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>

                {/* Country */}
                <Grid item xs={2}>
                    <FormControl style={{ width: "100%" }}>
                        <InputLabel shrink>
                            Country
                        </InputLabel>
                        <Select
                            disabled={activeTab === 1 || activeTab === 2}
                            displayEmpty
                            notched
                            label="Country"
                            value={countryFilter}
                            onChange={(e) => {
                                handleFilterChange({
                                    country: e.target.value,
                                    region: '',
                                })
                            }}
                        >
                            <MenuItem key={"All Countries"} value="">
                                All Countries
                            </MenuItem>
                            {CONTINENT_GROUP_COUNTRIES_MAPPING[continentFilter]?.map(countryCode => {
                                return (
                                    <MenuItem key={countryCode} value={countryCode}>
                                        {COUNTRY_CODE_TO_NAME[countryCode]}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </Grid>

                {/* State/Province */}
                {shouldShowRegionFilter && (
                    <Grid item xs={2}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel shrink>
                                {regionFilterLabel}
                            </InputLabel>
                            <Select
                                displayEmpty
                                notched
                                label={regionFilterLabel}
                                value={regionFilter}
                                onChange={(e) => handleFilterChange({ region: e.target.value })}
                            >
                                <MenuItem key={"All Regions"} value="">
                                    All {regionFilterLabel}
                                </MenuItem>
                                {countryFilter === 'US' && US_STATES_LIST.map((stateCode) => {
                                    const stateName = US_STATE_CODE_TO_NAME[stateCode];
                                    return (
                                        <MenuItem key={stateCode} value={stateCode}>
                                            {stateName}
                                        </MenuItem>
                                    );
                                })}
                                {countryFilter === 'CA' && CANADA_PROVINCE_LIST.map((provinceCode) => {
                                    const provinceName = CANADA_PROVINCE_CODE_TO_NAME[provinceCode];
                                    return (
                                        <MenuItem key={provinceCode} value={provinceCode}>
                                            {provinceName}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                )}

                {/* Year */}
                <Grid item xs={2}>
                    <FormControl style={{ width: "100%" }}>
                        <InputLabel shrink>
                            Year
                        </InputLabel>
                        <Select
                            displayEmpty
                            notched
                            label="Year"
                            value={yearFilter}
                            onChange={(e) => {
                                handleFilterChange({
                                    year: e.target.value,
                                })
                            }}
                        >
                            <MenuItem key={"All Years"} value="">
                                All Years
                            </MenuItem>
                            {YEARS.map(year => (
                                <MenuItem
                                    key={year}
                                    value={year}
                                >
                                    {year}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {/* Quarter */}
                <Grid item xs={2}>
                    <FormControl style={{ width: "100%" }}>
                        <InputLabel shrink>
                            Quarter
                        </InputLabel>
                        <Select
                            displayEmpty
                            notched
                            label="Quarter"
                            value={quarterFilter}
                            onChange={(e) => {
                                handleFilterChange({
                                    quarter: e.target.value,
                                    month: '',
                                })
                            }}
                        >
                            <MenuItem key={"All Quarters"} value="">
                                All Quarters
                            </MenuItem>
                            {QUARTERS.map(quarter => (
                                <MenuItem
                                    key={quarter}
                                    value={quarter}
                                >
                                    {quarter}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {/* Month */}
                <Grid item xs={2}>
                    <FormControl style={{ width: "100%" }}>
                        <InputLabel shrink>
                            Month
                        </InputLabel>
                        <Select
                            displayEmpty
                            notched
                            label="Month"
                            value={monthFilter}
                            onChange={(e) => {
                                handleFilterChange({
                                    month: e.target.value,
                                })
                            }}
                        >
                            <MenuItem key={"All Months"} value="">
                                All Months
                            </MenuItem>
                            {(MONTH_MAPPING_PER_QUARTER[quarterFilter] ?? MONTH_MAPPING_PER_QUARTER.ALL).map(month => (
                                <MenuItem
                                    key={month}
                                    value={month}
                                >
                                    {month}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {/* Filing Status */}
                <Grid item xs={2}>
                    <FormControl style={{ width: "100%" }}>
                        <InputLabel shrink>
                            Filing Status
                        </InputLabel>
                        <Select
                            displayEmpty
                            notched
                            label="Filing Status"
                            value={filingStatusFilter}
                            onChange={(e) => handleFilterChange({ filingStatus: e.target.value })}
                        >
                            <MenuItem key={"All Statuses"} value="">
                                All Statuses
                            </MenuItem>
                            <MenuItem key={TaskStatus.Pending} value={TaskStatus.Pending}>
                                {TaskStatusLabelMapping[TaskStatus.Pending]}
                            </MenuItem>
                            <MenuItem key={TaskStatus.Done} value={TaskStatus.Done}>
                                {TaskStatusLabelMapping[TaskStatus.Done]}
                            </MenuItem>
                            <MenuItem key={TaskStatus.Overdue} value={TaskStatus.Overdue}>
                                {TaskStatusLabelMapping[TaskStatus.Overdue]}
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                {/* Ultimate Responsible Party */}
                <Grid item xs={2}>
                    <FormControl style={{ width: "100%" }}>
                        <InputLabel shrink>
                            Ultimate Responsible Party
                        </InputLabel>
                        <Select
                            displayEmpty
                            notched
                            label="Ultimate Responsible Party"
                            value={userFilter}
                            onChange={(e) => handleFilterChange({ user: e.target.value })}
                        >
                            <MenuItem key={"All Users"} value="">
                                All Users
                            </MenuItem>
                            {users?.usersList?.map(user => (
                                <MenuItem key={user._id} value={user._id}>
                                    {user.firstname} {user.lastname}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>

                {/* Legal entity filter */}
                <Grid item xs={2}>
                    <FormControl style={{ width: "100%" }}>
                        <InputLabel shrink>Legal Entity</InputLabel>
                        <Select
                            displayEmpty
                            notched
                            label="Legal Entity"
                            value={entityFilter}
                            onChange={(e) => handleFilterChange({ entity: e.target.value })}
                        >
                            <MenuItem key={"All Entities"} value="">
                                All Entities
                            </MenuItem>
                            {users?.entityList?.entities?.map(ent => (
                                <MenuItem key={ent._id} value={ent._id}>
                                    {ent.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <Grid container marginTop="12px">
                <Box
                    sx={{
                        display: 'flex',
                        height: "100%",
                        width: '100%',
                        justifyContent: 'flex-end',
                        gap: '16px',
                    }}
                >
                    <ButtonGroup>
                        <ButtonWithTooltip
                            tooltipText="Clear all filters"
                            variant="outlined"
                            startIcon={<FilterListOff />}
                            onClick={() => {
                                handleFilterChange(INITIAL_TASK_CHART_FILTERS);
                                dispatch(setMapChartTab({ mapChartTab: 0 }));
                            }}
                        >
                            Clear filters
                        </ButtonWithTooltip>
                        <ButtonWithTooltip
                            tooltipText="Export task data to an Excel file"
                            variant="outlined"
                            startIcon={<FileDownload />}
                            onClick={() => generateXlsx(data, users)}
                        >
                            Export to XLSX
                        </ButtonWithTooltip>
                        {auth?.user?.isAdmin && (
                            <ButtonWithTooltip
                                tooltipText="Archive all tasks with completed payments"
                                variant="outlined"
                                startIcon={<Archive />}
                                onClick={() => handleArchivePaidTasks({ auth })}
                            >
                                Archive paid tasks
                            </ButtonWithTooltip>
                        )}
                        <ButtonWithTooltip
                            tooltipText={`${isPaymentHubHidden ? "Show" : "Hide"} Payment Hub columns`}
                            variant="outlined"
                            startIcon={isPaymentHubHidden ? <Visibility /> : <VisibilityOff />}
                            onClick={() => {
                                dispatch(setIsPaymentHubHidden({ isPaymentHubHidden: !isPaymentHubHidden }));
                            }}
                        >
                            {`${isPaymentHubHidden ? "Show" : "Hide"} Payment Hub`}
                        </ButtonWithTooltip>
                    </ButtonGroup>
                </Box>
            </Grid>
        </Grid>
    );
}

export default TaskMapFilters;
