import { Chip } from "@mui/material";
import { TaskStatusColorMapping, TaskStatusLabelMapping } from "./constants";

const FilingStatusCell = ({ status }) => {
    return (
        <Chip
            sx={{
                minWidth: "100px",
                background: TaskStatusColorMapping[status],
                color: 'white',
                fontWeight: 600,
            }}
            label={TaskStatusLabelMapping[status]}
        />
    )
};

export default FilingStatusCell;
