import { Fragment, useState } from "react"
import { Modal, Box, Divider, Grid, IconButton } from "@mui/material"
import { AddCircle as AddCircleIcon, AttachFile as AttachFileIcon } from "@mui/icons-material"
import BasicDropzone from "components/files/BasicDropzone"
import FileDataGrid from "components/files/FileDataGrid"

const TaskFileModal = ({ task, subtask, actions }) => {
  const [open, setOpen] = useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => { setOpen(false) }

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  }

  return (
    <Fragment>
      <IconButton variant="contained" onClick={handleOpen}>
        {subtask ? <AttachFileIcon /> : <AddCircleIcon />}
      </IconButton>
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <Grid container spacing={3}>
            {/* Header */}
            <Grid item xs={12}>
              Add file {subtask ? "to subtask" : ""}
              <Divider sx={{ p: 1 }} />  
            </Grid>


            {/* Subtask files (if rendered on subtask) */}
            {subtask ? 
              <Grid item xs={12} sx={{ height: "300px" }}>
                <FileDataGrid task={task} subtask={subtask} />
              </Grid>
            : null}

            {/* File upload */}
            <Grid item xs={12}>
              <BasicDropzone actions={{...actions, handleClose}} task={task} subtask={subtask} />
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Fragment>
  )
}

export default TaskFileModal
