import { useCallback, useContext, useEffect, useMemo, useState } from "react"

import {
  Box,
  FormControlLabel,
  Grid,
  Switch
} from "@mui/material"
import moment from "moment"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

import { AuthContext } from "helpers/auth"
import useMonexApiHelper from "hooks/useMonexApiHelper"
import useTaskHelper from "hooks/useTaskHelper"
import { selectBalanceList, selectPaymentList, selectTaskPageFilters, selectTradeList } from "redux/slices/selectors"
import CreateTradeModal from "../CreateTradeModal"
import TaskMapSection from "../TaskMapSection"
import TaskTable from "./TaskTable"
import ButtonWithTooltip from "components/common/ButtonWithTooltip"
import { Repeat } from "@mui/icons-material"
import { isEmpty } from "lodash-es"
import '../styles.css'

const FETCH_TRADE_TOAST_ID = 'fetch-trade-toast-id';
const ROLLOVER_TASKS_TOAST_ID = 'fetch-trade-toast-id';

const TaskPage = () => {
  const dispatch = useDispatch();
  const [currentTask, setCurrentTask] = useState()
  const [showArchived, setShowArchived] = useState(false)
  const [isTradeModalOpen, setIsTradeModalOpen] = useState(false);

  const tradeList = useSelector(selectTradeList);
  const paymentList = useSelector(selectPaymentList);
  const balanceList = useSelector(selectBalanceList);
  const pageFilters = useSelector(selectTaskPageFilters);
  const {
    year: yearFilter,
    country: countryFilter,
  } = useMemo(() => pageFilters ?? {}, [pageFilters]);

  const nextYear = moment().add(1, 'years').year();

  const auth = useContext(AuthContext)

  const { fetchTradeList, fetchPaymentList, fetchBalanceList } = useMonexApiHelper();
  const { taskListTableData, fetchTaskListData } = useTaskHelper();

  const showErrorSnackbar = (message) => toast.error(`Encountered an error: ${message}`, { autoClose: 5000 });

  const handleShowTradeModal = useCallback(async (task) => {
    const openTradeModal = () => {
      setIsTradeModalOpen(true);
      setCurrentTask(task);
    }
    if (balanceList?.length === 0) {
      toast('Fetching trade data...', { autoClose: false, toastId: FETCH_TRADE_TOAST_ID });
      const spotDate = moment().add(2, 'day').endOf('day').format("YYYY-MM-DD");
      const fetchBalanceListResponse = await fetchBalanceList({ date: spotDate });
      toast.done(FETCH_TRADE_TOAST_ID);
      if (fetchBalanceListResponse?.message) {
        showErrorSnackbar(fetchBalanceListResponse.message);
      } else {
        openTradeModal();
      }
    } else {
      openTradeModal();
    }
  }, [balanceList?.length, fetchBalanceList]);


  const rollOverTasks = async () => {
    toast('Creating New tasks for next year...', { autoClose: false, toastId: ROLLOVER_TASKS_TOAST_ID });
    await fetch(`${process.env.REACT_APP_API_PROXY}/api/tasks/rollOver`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth?.user?.token
      },
      body: JSON.stringify({ tasks: taskListTableData })
    }).then((res) => {
      toast.done(ROLLOVER_TASKS_TOAST_ID);
      toast.success(`Successfully created the tasks for next year`, { autoClose: 5000 });
      if (auth?.user) {
        fetchTaskListData({ auth, showArchived });
      }
    });
  }
  const handleHideTradeModal = () => {
    setIsTradeModalOpen(false);
    setCurrentTask(undefined)
  };

  useEffect(() => {
    if (auth?.user) {
      fetchTaskListData({ auth, showArchived });
    }
  }, [auth, dispatch, fetchTaskListData, showArchived])

  useEffect(() => {
    if (tradeList?.length === 0) {
      fetchTradeList();
    }
  }, [fetchTradeList, tradeList?.length]);

  useEffect(() => {
    if (paymentList?.length === 0) {
      fetchPaymentList();
    }
  }, [fetchPaymentList, paymentList?.length]);

  return (
    <Grid container spacing={3}>
      {/* Charts & Filters */}
      <Grid item xs={12} margin="24px">
        <TaskMapSection data={taskListTableData} />
      </Grid>

      {/* Archived filter */}
      {/* <FormControlLabel sx={{ pl: 2 }}
          control={(
            <Switch checked={showArchived} onChange={(e) => setShowArchived(e.target.checked)} />
          )}
          label="Show archived tasks"
        /> */}
      <Grid container item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', marginRight: 2, marginLeft: 2 }}>
        <Grid item xs={6}>
          <FormControlLabel
            control={(
              <Switch checked={showArchived} onChange={(e) => setShowArchived(e.target.checked)} />
            )}
            label="Show archived tasks"
          />
        </Grid>
        {!isEmpty(yearFilter) && !isEmpty(countryFilter) && yearFilter !== nextYear.toString() &&
          <Grid item sx={4}>
            <ButtonWithTooltip
              tooltipText="Rollover to next year"
              variant="outlined"
              startIcon={<Repeat />}
              onClick={() => {
                rollOverTasks()
              }}
            >
              Rollover
            </ButtonWithTooltip>
          </Grid>
        }
      </Grid>

      {/* Tasks */}
      <Grid item xs={12}>
        <Box sx={{ height: '550px' }}>
          <TaskTable
            data={taskListTableData}
            onTradeModalToggle={handleShowTradeModal}
          />
        </Box>
      </Grid>

      {isTradeModalOpen && <CreateTradeModal task={currentTask} onClose={handleHideTradeModal} />}
    </Grid>
  )
}

export default TaskPage
