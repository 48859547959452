import { Download as DownloadIcon } from "@mui/icons-material"
import { IconButton } from "@mui/material"
import { DataGrid } from "@mui/x-data-grid"
import DeleteDialog from "components/common/DeleteDialog"
import { AuthContext } from "helpers/auth"
import moment from "moment"
import { Fragment, useContext, useEffect, useState } from "react"

import "../common/StyledTable/styles.css"

const FileDataGrid = ({ task, subtask, residence, filing, updated, filters, dateColumns = [] }) => {
  const [fileList, setFileList] = useState([])
  const [fileListInclude, setFileListInclude] = useState([])
  const auth = useContext(AuthContext)

  const downloadFile = async (file) => {
    const getFileData = await fetch(`${process.env.REACT_APP_API_PROXY}/api/files/${encodeURIComponent(file._id)}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth?.user?.token
      }
    })

    const downloadURL = (await getFileData.json()).downloadURL
    window.open(downloadURL)
  }

  const fileColumns = [
    {
      field: "action",
      headerName: "Actions",
      sortable: false,
      minWidth: 100,
      renderCell: (params) => (
        <Fragment>
          <DeleteDialog objectName={"file"} deleteFunction={() => deleteFile(params.row)} />
          <IconButton variant="contained" onClick={() => { downloadFile(params.row) }}><DownloadIcon /></IconButton>
        </Fragment>
      ),
      headerClassName: "styled-header",
    },
    { field: "filename", headerName: "Filename", flex: 1, headerClassName: "styled-header", },
    {
      field: "createdAt",
      headerName: "Date Uploaded",
      minWidth: 200,
      renderCell: (params) => (<>{moment(params.row.createdAt).format("YYYY-MM-DD HH:mm:ss")}</>),
      headerClassName: "styled-header",
    },
    ...(dateColumns)
  ];

  const deleteFile = async (file) => {
    await fetch(`${process.env.REACT_APP_API_PROXY}/api/files/${encodeURIComponent(file._id)}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth?.user?.token
      }
    })
    setFileList((fileList) => fileList.filter((f) => f._id !== file._id))
    setFileListInclude((fileListInclude) => fileListInclude.filter((f) => f._id !== file._id))
  }

  useEffect(() => {
    if (auth?.user) {
      const fetchFileData = async () => {
        const hasTask = task ? true : false
        const hasResidence = residence ? true : false
        const hasFiling = filing ? true : false
        let url = `${process.env.REACT_APP_API_PROXY}/api/files`

        if (hasTask) {
          url = `${process.env.REACT_APP_API_PROXY}/api/files/?task_id=${encodeURIComponent(task._id)}`
          if (subtask) { url = url + `&subtask_id=${encodeURIComponent(subtask._id)}` }
        } else if (hasResidence) {
          url = `${process.env.REACT_APP_API_PROXY}/api/files/?residence_id=${encodeURIComponent(residence._id)}`
        } else if (hasFiling) {
          url = `${process.env.REACT_APP_API_PROXY}/api/files/?filing_id=${encodeURIComponent(filing._id)}`
        }

        const fileResponse = await fetch(url, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + auth?.user?.token
          }
        })
        const files = await fileResponse.json()
        setFileList(files)
        setFileListInclude(files)
      }

      fetchFileData()
    }
  }, [updated, auth, task, subtask, residence, filing])

  useEffect(() => {
    if (filters) {
      setFileListInclude(fileList.filter((file) => {
        let includeFile = true

        if (
          (filters.country && file.country !== filters.country) ||
          (filters.year && file.year !== filters.year) ||
          (filters.quarter && file.quarter !== filters.quarter)
        ) includeFile = false

        return includeFile
      }))
    }
  }, [filters, fileList])

  return (
    <DataGrid
      rows={fileListInclude}
      columns={fileColumns}
      getRowId={(row) => row._id}
      disableSelectionOnClick
      getRowClassName={() => "styled-row"}
      sx={{
        '.MuiDataGrid-columnSeparator': {
          display: 'none',
        },
      }}
    />
  )
}

export default FileDataGrid
