import { useCallback, useEffect, useRef, useState } from 'react';

import { noop } from 'lodash-es';

const ELAPSED_TIME_START = 0;

/**
 * @typedef {object} Timer - Returned by `useTimer`.
 * @property {number} value - The current stopwatch / countdown time.
 * @property {Function} startTimer - Starts the timer.
 * @property {Function} stopTimer - Stops the timer.
 */
/**
 * @typedef {object} TimerConfiguration - Timer configuration.
 * @property {number} durationInSeconds - How long the timer should run till it's automatically stopped.
 * @property {number} intervalInSeconds - How often the timer updates in seconds. Defaults to 1 second.
 * @property {boolean} isAscending - Counts up if true; counts down if false. Defaults to false.
 * @property {Function} onTimerStart - Callback to execute when timer starts running. Optional.
 * @property {Function} onTimerStop - Callback to execute when timer stops running. Optional.
 */

/**
 * Can be used as a stopwatch timer or countdown timer.
 * @param {TimerConfiguration} config - Configures the timer.
 * @returns {Timer} The timer state and functions.
 */
const useTimer = ({
    durationInSeconds,
    intervalInSeconds = 1,
    isAscending = false,
    onTimerStart = noop,
    onTimerStop = noop,
}) => {
    const [elapsedTimeInSeconds, setElapsedTimeInSeconds] = useState(ELAPSED_TIME_START);
    const timerValue = isAscending ? elapsedTimeInSeconds : durationInSeconds - elapsedTimeInSeconds;

    const intervalIdRef = useRef();

    const onTimerStartCallback = useCallback(onTimerStart, [onTimerStart]);
    const startTimer = useCallback(() => {
        if (intervalIdRef.current) {
            // eslint-disable-next-line no-console
            console.debug('Timer already started');
            return;
        }

        intervalIdRef.current = setInterval(
            () => setElapsedTimeInSeconds((_elapsedTimeInSeconds) => _elapsedTimeInSeconds + intervalInSeconds),
            intervalInSeconds * 1000,
        );

        onTimerStartCallback();
    }, [intervalInSeconds, onTimerStartCallback]);

    const onTimerStopCallback = useCallback(onTimerStop, [onTimerStop]);
    const stopTimer = useCallback(() => {
        if (intervalIdRef.current) {
            intervalIdRef.current = clearInterval(intervalIdRef.current);
            setElapsedTimeInSeconds(ELAPSED_TIME_START);

            onTimerStopCallback();
        }
    }, [onTimerStopCallback]);

    useEffect(() => {
        if (elapsedTimeInSeconds >= durationInSeconds) {
            stopTimer();
        }
    }, [durationInSeconds, elapsedTimeInSeconds, stopTimer]);

    return {
        value: timerValue,
        startTimer,
        stopTimer,
    };
};

// For progress bar to transition smoothly, update progress percentage every 100 millis
const PROGRESS_INTERVAL_SECONDS = 0.1;

/**
 * Can be used as a stopwatch timer or countdown timer.
 * @param {TimerConfiguration} config - Configures the timer.
 * @returns {Timer & { progressPercentage: number }} The timer state (including progress in percent) and functions.
 */
export const useTimerWithProgress = ({ durationInSeconds, onTimerStart, ...rest }) => {
    const { value: progressTimerValue, startTimer: startProgressTimer } = useTimer({
        durationInSeconds,
        intervalInSeconds: PROGRESS_INTERVAL_SECONDS,
    });
    const timer = useTimer({
        durationInSeconds,
        onTimerStart: () => {
            startProgressTimer();
            onTimerStart?.();
        },
        ...rest,
    });
    return {
        ...timer,
        progressPercentage: (progressTimerValue / durationInSeconds) * 100,
    };
};

export default useTimer;
