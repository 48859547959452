import { AuthContext } from "helpers/auth";
import useFilesHelper from "hooks/useFilesHelper";
import { useContext, useEffect } from "react";
import FilesTableSection from "./FilesTableSection";

const FilePage = () => {
    const auth = useContext(AuthContext);

    const { handleFetchFilesList } = useFilesHelper();

    useEffect(() => {
        if (auth.user) {
            handleFetchFilesList({});
        }
    }, [auth, handleFetchFilesList]);

    return (
        <FilesTableSection />
    );
}

export default FilePage;
