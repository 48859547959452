
import { FilingStatus, FilingStatusColorMapping } from "components/filings/constants";
import { TaskStatus, TaskStatusColorMapping } from "components/tasks/constants";
import { scaleLinear } from "d3-scale";

import { theme } from "theme";

export const NEUTRAL_REGION_COLOR = '#D6D6DA';
export const HOVER_REGION_COLOR = '#800080';
export const PRESSED_REGION_COLOR = '#51414F';

const useMapChartHelper = () => {
    const linearColorScale = scaleLinear()
        .domain([-1, 0, 1])
        .range([theme.palette.warning.main, NEUTRAL_REGION_COLOR, theme.palette.success.main]);

    const getRegionColor = (data) => {
        if (data[TaskStatus.Overdue]) {
            return TaskStatusColorMapping[TaskStatus.Overdue];
        } else if (data[TaskStatus.Pending]) {
            return TaskStatusColorMapping[TaskStatus.Pending];
        } else if (data[TaskStatus.Done]) {
            return TaskStatusColorMapping[TaskStatus.Done];
        }
        return NEUTRAL_REGION_COLOR;
    };

    const getFilingsRegionColor = (data) => {
        if (data[FilingStatus.Initiated]) {
            return FilingStatusColorMapping[FilingStatus.Initiated];
        }
        if (data[FilingStatus.Prepared]) {
            return FilingStatusColorMapping[FilingStatus.Prepared];
        }
        if (data[FilingStatus.Approved]) {
            return FilingStatusColorMapping[FilingStatus.Approved];
        }
        if (data[FilingStatus.Submitted]) {
            return FilingStatusColorMapping[FilingStatus.Submitted];
        }
        if (data[FilingStatus.Paid]) {
            return FilingStatusColorMapping[FilingStatus.Paid];
        }
        if (data[FilingStatus.Confirmed]) {
            return FilingStatusColorMapping[FilingStatus.Confirmed];
        }
        if (data[FilingStatus.NotStarted]) {
            return FilingStatusColorMapping[FilingStatus.NotStarted];
        }
        return NEUTRAL_REGION_COLOR;
    }

    return {
        linearColorScale,
        getRegionColor,
        getFilingsRegionColor,
    };
};

export default useMapChartHelper;
