import { useCallback, useState } from "react";


const useMapChartControls = ({ initialCoordinates = [0, 0], initialZoom = 1 }) => {
    const [position, setPosition] = useState({ coordinates: initialCoordinates, zoom: initialZoom });

    const handleZoomIn = useCallback(() => {
        if (position?.zoom >= 4) return;
        setPosition((pos) => ({ ...pos, zoom: pos.zoom * 2 }));
    }, [position?.zoom]);

    const handleZoomOut = useCallback(() => {
        if (position?.zoom <= 1) return;
        setPosition((pos) => ({ ...pos, zoom: pos.zoom / 2 }));
    }, [position?.zoom]);

    const handleMoveEnd = useCallback((position) => {
        setPosition(position);
    }, []);

    return {
        position,
        handleZoomIn,
        handleZoomOut,
        handleMoveEnd,
    };
};

export default useMapChartControls;
