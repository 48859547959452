export const classes = {
    paper: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        background: 'white',
        padding: '32px',
        borderRadius: '8px',
    },
    charts: {
        height: 'max-content',
        marginBottom: '32px',
    },
    mapChartContainer: {
        margin: '32px 0 0 0',
        backgroundColor: '#F3F6F8',
        borderRadius: '8px',
        width: 'calc(100% - 72px)',
        height: 'min-content',
    },
    pieChartContainer: {
        padding: '32px',
        margin: {
            lg: '32px 0 0 32px',
            md: '32px 0 0 0'
        },
        backgroundColor: '#F3F6F8',
        borderRadius: '8px',
        width: 'calc(100% - 32px)',
        height: {
            lg: 'calc(100% - 32px)',
            md: '100%',
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    pieChart: {
        width: '100%',
        height: '200px',
    },
    pieChartHeaderText: {
        fontSize: '16px',
        fontWeight: 700,
        color: '#0050B5',
        textTransform: 'uppercase',
        marginBottom: '16px',
    },
    pieChartFilterSection: {
        marginTop: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
    },
}
