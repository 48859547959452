import { createSlice } from "@reduxjs/toolkit";
import { DEFAULT_ACTIVE_COUNTRY_PREFERENCE } from "helpers/preferences";

const initialState = {
    activeCountries: DEFAULT_ACTIVE_COUNTRY_PREFERENCE,
};

const commonSlice = createSlice({
    name: 'common',
    initialState,
    reducers: {
        setActiveCountries: (state, action) => {
            state.activeCountries = action.payload.activeCountries
        },
    }
});

export const {
    setActiveCountries,
} = commonSlice.actions;

export default commonSlice;