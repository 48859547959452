import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    quote: {},
    tradeList: [],
    tradeDetail: {},
    thirdPartyBankAccountList: [],
    thirdPartyBankAccountDetail: {},
    ownBankAccountList: [],
    ownBankAccountDetail: {},
    balanceList: [],
    paymentList: [],
    paymentDetail: {},
    initialLoadingState: {
        tradeModal: false,
        paymentModal: false,
    },
    submitLoadingState: {
        tradeModal: false,
        paymentModal: false,
    },
};

const monexSlice = createSlice({
    name: 'monex',
    initialState,
    reducers: {
        setQuote: (state, action) => {
            state.quote = action.payload.quote
        },
        setTradeList: (state, action) => {
            state.tradeList = action.payload.tradeList
        },
        setTradeDetail: (state, action) => {
            state.tradeDetail = action.payload.tradeDetail
        },
        setThirdPartyBankAccountList: (state, action) => {
            state.thirdPartyBankAccountList = action.payload.thirdPartyBankAccountList
        },
        setThirdPartyBankAccountDetail: (state, action) => {
            state.thirdPartyBankAccountDetail = action.payload.thirdPartyBankAccountDetail
        },
        setOwnBankAccountList: (state, action) => {
            state.ownBankAccountList = action.payload.ownBankAccountList
        },
        setOwnBankAccountDetail: (state, action) => {
            state.ownBankAccountDetail = action.payload.ownBankAccountDetail
        },
        setBalanceList: (state, action) => {
            state.balanceList = action.payload.balanceList
        },
        setPaymentList: (state, action) => {
            state.paymentList = action.payload.paymentList
        },
        setPaymentDetail: (state, action) => {
            state.paymentDetail = action.payload.paymentDetail
        },
        setInitialLoadingState: (state, action) => {
            state.initialLoadingState = { ...state.initialLoadingState, ...action.payload.initialLoadingState }
        },
        setSubmitLoadingState: (state, action) => {
            state.submitLoadingState = { ...state.submitLoadingState, ...action.payload.submitLoadingState }
        },
    }
});

export const {
    setQuote,
    setTradeList,
    setTradeDetail,
    setThirdPartyBankAccountDetail,
    setThirdPartyBankAccountList,
    setOwnBankAccountDetail,
    setOwnBankAccountList,
    setBalanceList,
    setPaymentList,
    setPaymentDetail,
    setInitialLoadingState,
    setSubmitLoadingState,
} = monexSlice.actions;

export default monexSlice;