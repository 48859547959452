import "chart.js/auto"
import ChartDataLabels from "chartjs-plugin-datalabels"
import { Fragment, useEffect, useState } from "react"
import { Doughnut } from "react-chartjs-2"
import { schemeCategory10 } from "d3"

const ResidencePieChart = ({ currentCountry, daysCountPerCountry, setCurrentCountry }) => {
  const [data, setData] = useState([])
  const [datasetLabels, setDatasetLabels] = useState([])

  const pieOptions = {
    plugins: {
      legend: {
        display: false,
        showTooltips: false
      },
      tooltip: {
        enabled: false
      },
      title: {
        display: false
      },
      datalabels: {
        formatter: (value, context) => {
          return value ? datasetLabels[context.dataIndex] + "\n" + value : ""
        },
        color: "white",
        textAlign: "center"
      }
    }, 
    onClick: (event, element) => {
      if (element.length > 0) {
        setCurrentCountry(datasetLabels[element[0].index])
      }
    }
  }

  const residenceData = {
    labels: datasetLabels,
    datasets: [
      { 
        label: "Number of days per country", 
        data: data,
        backgroundColor: schemeCategory10
      }
    ]
  }

  useEffect(() => {
    if (daysCountPerCountry.length !== 0) {
      setDatasetLabels(daysCountPerCountry.map(c => c.country))
      setData(daysCountPerCountry.map(c => Math.round(c.count)))
    }
  }, [currentCountry, daysCountPerCountry])

  return (
    <Fragment>
      <Doughnut 
        data={residenceData} 
        plugins={[ChartDataLabels]} 
        options={pieOptions}
      />
    </Fragment>
  )
}

export default ResidencePieChart
