import { Archive as ArchiveIcon, ContentCopy as ContentCopyIcon, KeyboardArrowLeft, Unarchive as UnarchiveIcon } from "@mui/icons-material"
import { Box, Chip, Divider, Grid, IconButton, Paper, Typography } from "@mui/material"
import DeleteDialog from "components/common/DeleteDialog"
import FileDataGrid from "components/files/FileDataGrid"
import ProjectModal from "components/projects/ProjectModal"
import FilingStatusCell from "components/tasks/FilingStatusCell"
import TaskFileModal from "components/tasks/TaskFileModal"
import TaskMessagesModal from "components/tasks/messages/TaskMessagesModal"
import NotesComponent from "components/tasks/notes/NotesComponent"
import SubtasksComponent from "components/tasks/subtasks/SubtasksComponent"
import { AuthContext } from "helpers/auth"
import { SnackbarContext } from "helpers/snackbar"
import moment from "moment"
import { Fragment, useContext, useEffect, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

const ProjectDetail = () => {
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [task, setTask] = useState({})
  const [updated, setUpdated] = useState(0)
  const auth = useContext(AuthContext)
  const snackbar = useContext(SnackbarContext)
  const navigate = useNavigate()
  const toggleUpdate = () => setUpdated(!updated)

  const deleteTask = async (task) => {
    await fetch(`${process.env.REACT_APP_API_PROXY}/api/projects/${encodeURIComponent(task._id)}`, {
      method: "DELETE",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth.user.token
      }
    })
    navigate("/projects")
  }

  const copyTask = async (task) => {
    await fetch(`${process.env.REACT_APP_API_PROXY}/api/projects/`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth.user.token
      },
      body: JSON.stringify({ copyTask: task._id })
    })
    navigate("/projects")
  }

  const toggleIsArchived = async (task) => {
    await fetch(`${process.env.REACT_APP_API_PROXY}/api/tasks/${encodeURIComponent(task._id)}`, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        "Authorization": "Bearer " + auth.user.token
      },
      body: JSON.stringify({ ...task, isArchived: !task.isArchived })
    })
    navigate("/tasks")
    snackbar.setMessage({ message: `Project successfully ${task.isArchived ? "unarchived" : "archived"}!`, severity: "success" })
  }

  useEffect(() => {
    if (auth.user) {
      const fetchTaskData = async () => {
        const taskResponse = await fetch(`${process.env.REACT_APP_API_PROXY}/api/projects/${id}`, {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + auth.user.token
          }
        })
        const response = await taskResponse.json()
        setTask(response)
        setIsLoading(false)
      }

      fetchTaskData()
    }
  }, [id, auth, updated])

  return (
    <Fragment>
      { !isLoading
      ?
        <Grid container spacing={1}>
          {/* Task header */}
          <Grid item xs={0.5}>
            <IconButton size="small">
              <Link to={"/projects"}><KeyboardArrowLeft/></Link>
            </IconButton>
          </Grid>
          <Grid item xs={11.5}>
            <Box sx={{ alignItems: "center", display: "flex", justifyContent: "space-between", flexWrap: "wrap", m: -1 }}>
              <Typography sx={{ m: 1 }} variant="h5">{task.name}</Typography>
            </Box>
          </Grid>

          {/* Task action buttons */}
          <Grid item xs={12}>
            <Grid container>
              {/* Messages button */}
              <Grid item xs={6}>
                <TaskMessagesModal task={task} />
              </Grid>

              {/* Edit and delete buttons */}
              <Grid item xs={6}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <ProjectModal task={task} actions={{ toggleUpdate }} />
                  <DeleteDialog objectName={"project"} deleteFunction={() => deleteTask(task)} />
                  <IconButton size="small" onClick={() => toggleIsArchived(task)}>
                    { task.isArchived ? <UnarchiveIcon /> : <ArchiveIcon /> }
                  </IconButton>
                  <IconButton size="small" onClick={() => copyTask(task)}>
                    <ContentCopyIcon />
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>

          {/* Description and assigned users */}
          <Grid item xs={12} md={8}>
            <Paper elevation={6} sx={{ p: 2, display: "flex", flexDirection: "column", height: "100%" }}>
              <Box sx={{ pb: 1 }}>
                <Typography variant="subtitle2" component="div">Description</Typography>
                <Typography variant="body2" component="div" sx={{ minHeight: "1.2em" }}>{task.description}</Typography>
              </Box>
              <Divider />
              <Box sx={{ pt: 1 }}>
                <Grid container>
                  <Grid item xs={10}>
                    <Typography variant="subtitle2" component="div">Assigned Users</Typography>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, pt: 1 }}>
                      {task.assignedUsers ? task.assignedUsers.map((user) => (
                        <Chip key={user._id} label={`${user.firstname} ${user.lastname}`}/>
                      )) : null}
                    </Box>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="subtitle2" component="div">Task Status</Typography>
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5, pt: 1 }}>
                      <FilingStatusCell status={task.status} />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Paper>
          </Grid>

          {/* Task details */}
          <Grid item xs={12} md={4}>
            <Paper elevation={6} sx={{ p: 2, display: "flex", flexDirection: "column", height: "100%" }}>
              <Grid container>
                <Grid item xs={12} md={6} sx={{ pb: 1 }}>
                  <Typography variant="subtitle2" component="div">Legal Entity</Typography>
                  <Typography variant="body2" component="div">{task.entity?.name}</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ pb: 1 }}>
                  <Typography variant="subtitle2" component="div">Deadline</Typography>
                  <Typography variant="body2" component="div">{moment(task.deadlineDate).format("YYYY-MM-DD")}</Typography>
                </Grid>
                <Grid item xs={12} md={6} sx={{ pb: 1 }}>
                  <Typography variant="subtitle2" component="div">Created by</Typography>
                  <Typography variant="body2" component="div">{task.createdBy?.firstname} {task.createdBy?.lastname}</Typography>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          {/* Subtasks */}
          <Grid item xs={12} md={8}>
            <SubtasksComponent task={task} />
          </Grid>

          {/* Notes and Files */}
          <Grid item xs={12} md={4}>
            <Grid container spacing={1}>
              {/* Notes */}
              <Grid item xs={12}>
                <NotesComponent task={task} />
              </Grid>

              {/* Files */}
              <Grid item xs={12}>
                <Paper elevation={6} sx={{ px: 2, pt: 1, pb: 2, display: "flex", flexDirection: "column", height: "100%" }}>
                  {/* Files header and add button */}
                  <Grid container alignItems="center">
                    <Grid item xs={9}>
                      <Typography variant="subtitle2" component="div">Files</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Box sx={{ display: "flex", justifyContent: "flex-end"}}>
                        <TaskFileModal task={task} actions={{ toggleUpdate }}/>
                      </Box>
                    </Grid>
                  </Grid>

                  {/* Files main content */}
                  <Grid container sx={{ height: "300px" }}>
                    <FileDataGrid task={task} updated={updated} />
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      :
        <div></div>
      }
    </Fragment>
  )
}

export default ProjectDetail
