// COMMON
export const selectActiveCountriesPreference = ({ common }) => common.activeCountries;

// TASKS
export const selectTaskList = ({ tasks }) => tasks.taskList;
export const selectTaskDetail = ({ tasks }) => tasks.taskDetail;
export const selectTaskCountPerCountry = ({ tasks }) => tasks.taskCountPerCountry;
export const selectMapChartTab = ({ tasks }) => tasks.mapChartTab;
export const selectPieChartTab = ({ tasks }) => tasks.pieChartTab;
export const selectMapChartTooltip = ({ tasks }) => tasks.mapChartTooltip;
export const selectTaskPageFilters = ({ tasks }) => tasks.pageFilters;
export const selectTaskPageIsArchivedFilter = ({ tasks }) => tasks.pageFilters.isArchived;
export const selectIsPaymentHubHidden = ({ tasks }) => tasks.isPaymentHubHidden;

// FILINGS
export const selectFilingList = ({ filings }) => filings.filingList;
export const selectFilingDetail = ({ filings }) => filings.filingDetail;
export const selectFilingsMapChartTooltip = ({ filings }) => filings.mapChartTooltip;
export const selectFilingPageFilters = ({ filings }) => filings.pageFilters;
export const selectFilingMapChartTab = ({ filings }) => filings.mapChartTab;

// FILES
export const selectFilesList = ({ files }) => files.filesList;
export const selectFilesPageFilters = ({ files }) => files.pageFilters;

// OBLIGATIONS
export const selectObligationList = ({ obligations }) => obligations.obligationList;

// CUSTOMER
export const selectCustomerList = ({ customer }) => customer.customerList;
export const selectCompanyList = ({ customer }) => {
    const companies = customer.customerList.map((customer) => customer.companyName);
    const uniqueCompanies = companies.filter((item, pos) => {
        return companies.indexOf(item) === pos;
    });
    return uniqueCompanies;
};

// MONEX
export const selectQuote = ({ monex }) => monex.quote;
export const selectTradeList = ({ monex }) => monex.tradeList;
export const selectTradeDetail = ({ monex }) => monex.tradeDetail;
export const selectThirdPartyBankAccountList = ({ monex }) => monex.thirdPartyBankAccountList;
export const selectThirdPartyBankAccountDetail = ({ monex }) => monex.thirdPartyBankAccountDetail;
export const selectOwnBankAccountList = ({ monex }) => monex.ownBankAccountList;
export const selectOwnBankAccountDetail = ({ monex }) => monex.ownBankAccountDetail;
export const selectBalanceList = ({ monex }) => monex.balanceList;
export const selectPaymentList = ({ monex }) => monex.paymentList;
export const selectPaymentDetail = ({ monex }) => monex.paymentDetail;

export const selectInitialLoadingStateTradeModal = ({ monex }) => monex.initialLoadingState.tradeModal;
export const selectInitialLoadingStatePaymentModal = ({ monex }) => monex.initialLoadingState.paymentModal;
export const selectSubmitLoadingStateTradeModal = ({ monex }) => monex.submitLoadingState.tradeModal;
export const selectSubmitLoadingStatePaymentModal = ({ monex }) => monex.submitLoadingState.paymentModal;