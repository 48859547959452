import { useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";

import { selectMapChartTooltip } from "redux/slices/selectors";
import { INITIAL_MAP_CHART_TOOLTIP_STATE } from "redux/slices/tasksSlice";
import { TaskStatus, TaskStatusLabelMapping } from "../constants";

const TaskMapChartTooltip = () => {
    const mapChartTooltip = useSelector(selectMapChartTooltip);
    const { pending, completed, overdue } = mapChartTooltip.data ?? {};
    const shouldRenderTooltip = mapChartTooltip !== INITIAL_MAP_CHART_TOOLTIP_STATE && mapChartTooltip.name;

    return (
        <ReactTooltip>
            {shouldRenderTooltip && (
                <div>
                    <div style={{ "paddingBottom": 5 }}>{mapChartTooltip.name}</div>
                    <div>{TaskStatusLabelMapping[TaskStatus.Pending]}: {pending}</div>
                    <div>{TaskStatusLabelMapping[TaskStatus.Done]}: {completed}</div>
                    <div>{TaskStatusLabelMapping[TaskStatus.Overdue]}: {overdue}</div>
                </div>
            )}
        </ReactTooltip>
    )
}

export default TaskMapChartTooltip;
