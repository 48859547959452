export const classes = {
    modal: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "75%",
        height: "75%",
        overflowY: "scroll",
        backgroundColor: "white",
        boxShadow: 24,
        padding: '32px',
    },
    headerText: {
        fontSize: '16px',
        fontWeight: 700,
        color: '#0050B5',
        textTransform: 'uppercase',
    },
    modalHeader: {
        fontSize: '16px',
        fontWeight: 700,
        color: '#0050B5',
    },
    modalSubtitle: {
        fontSize: '16px',
        fontWeight: 700,
        textTransform: 'uppercase',
    },
    container: {
        padding: '16px',
        backgroundColor: 'white',
        borderRadius: '8px',
        border: '1px solid #E5EAF2',
        gridTemplateColumns: 'auto auto auto auto',
        gap: "24px",
    },
}