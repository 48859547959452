import { Box, Button, Divider, FormControl, FormHelperText, Grid, Modal, TextField, Typography } from "@mui/material";
import { ErrorMessage } from "helpers/validations";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { classes } from "./styles";

const ChangePasswordModal = ({ onClose }) => {
    const { register, handleSubmit, formState: { errors } } = useForm({ mode: 'onChange' });

    const onSubmit = async (data) => {
        const { email, oldPassword, newPassword, newPasswordConfirm } = data ?? {};
        if (newPassword !== newPasswordConfirm) {
            return toast.error("New password does not match.", { autoClose: 5000 });
        }
        try {
            const url = `${process.env.REACT_APP_API_PROXY}/api/users/forgot-password`;
            const body = {
                email,
                oldPassword,
                newPassword,
                newPasswordConfirm,
            }
            const updateUserResponse = await fetch(url, {
                method: "PUT",
                credentials: "include",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(body)
            });
            if (updateUserResponse.status === 401) {
                toast.error("Incorrect password, please type it again.", { autoClose: 5000 });
            } else {
                toast.success("Password updated successfully!", { autoClose: 5000 });
                onClose?.();
            }
        } catch (error) {
            toast.error(`Encountered error: ${error.message}`, { autoClose: 5000 });
        }
    };

    return (
        <Modal open onClose={onClose}>
            <Box sx={classes.modal}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography sx={classes.modalHeader}>
                            UPDATE PASSWORD
                        </Typography>
                        <Divider sx={{ p: 1 }} />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Email"
                                type="email"
                                {...register("email", { required: ErrorMessage.Required })}
                                error={Boolean(errors.email)}
                            />
                            {errors.email && (
                                <FormHelperText error>
                                    {errors.email.message}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Old Password"
                                type="password"
                                {...register("oldPassword", { required: ErrorMessage.Required })}
                                error={Boolean(errors.oldPassword)}
                            />
                            {errors.oldPassword && (
                                <FormHelperText error>
                                    {errors.oldPassword.message}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="New Password"
                                type="password"
                                {...register("newPassword", { required: ErrorMessage.Required })}
                                error={Boolean(errors.newPassword)}
                            />
                            {errors.newPassword && (
                                <FormHelperText error>
                                    {errors.newPassword.message}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl sx={{ width: '100%' }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                label="Confirm New Password"
                                type="password"
                                {...register("newPasswordConfirm", { required: ErrorMessage.Required })}
                                error={Boolean(errors.newPasswordConfirm)}
                            />
                            {errors.newPasswordConfirm && (
                                <FormHelperText error>
                                    {errors.newPasswordConfirm.message}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>
                    <Grid xs={12} sx={classes.buttons}>
                        <Button variant="contained" onClick={handleSubmit(onSubmit)}>
                            SUBMIT
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    )
};

export default ChangePasswordModal;
