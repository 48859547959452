import { useState } from "react"
import { Outlet } from "react-router-dom"
import { styled, ThemeProvider } from "@mui/material/styles"
import { CssBaseline,  Box, Container } from "@mui/material"
import { theme } from "theme"
import Sidebar from "components/layout/Sidebar"
import Navbar from "components/layout/Navbar"

const sidebarWidth = 280;

const DashboardLayoutRoot = styled("div", {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ open }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  paddingLeft: open ? sidebarWidth : 0,
}))

const AppLayout = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(true)

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        
        {/* Sidebar content */}
        <Sidebar 
          sidebarWidth={sidebarWidth}
          onClose={() => setSidebarOpen(false)}
          open={isSidebarOpen}
        />
        
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          {/* Navbar content */}
          <Navbar 
            sidebarWidth={sidebarWidth}
            onSidebarOpen={() => setSidebarOpen((isOpen) => !isOpen)}
            isSidebarOpen={isSidebarOpen}
          />
          
          {/* Main content */}
          <DashboardLayoutRoot open={isSidebarOpen}>
            <Box
              sx={{
                display: "flex",
                flex: "1 1 auto",
                flexDirection: "column",
                width: "100%",
                py: 3
              }}
            >
              <Container maxWidth={false}>
                <Outlet /> 
              </Container>
            </Box>
          </DashboardLayoutRoot>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default AppLayout
