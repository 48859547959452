import { Edit as EditIcon } from "@mui/icons-material"
import { Box, Button, Divider, Grid, IconButton, Modal, TextField } from "@mui/material"
import { AuthContext } from "helpers/auth"
import { COUNTRY_NAME_TO_COUNTRY_CODES } from "helpers/countries"
import useGeographicNameHelper from "hooks/useGeographicNameHelper"
import { isEmpty } from "lodash-es"
import { Fragment, useCallback, useContext, useMemo, useState } from "react"
import { ValidatorForm } from "react-material-ui-form-validator"
import { toast } from "react-toastify"

const ObligationModal = ({ country, geographicalRegionCode, obligation, actions }) => {
  const { getContinentCode } = useGeographicNameHelper();
  const [open, setOpen] = useState(false)
  const countryCode = useMemo(() => COUNTRY_NAME_TO_COUNTRY_CODES[country], [country]);
  const shouldShowRegion = useMemo(() => countryCode === 'US' || countryCode === 'CA', [countryCode]);
  const continentCode = useMemo(() => getContinentCode({ countryCode }), [countryCode, getContinentCode]);

  const handleOpen = () => {
    if (shouldShowRegion && isEmpty(geographicalRegionCode)) {
      return toast.error(`Please select a state/province for ${country}`, { autoClose: 5000 });
    }
    if (country) {
      setOpen(true)
    }
  }
  const handleClose = useCallback(() => {
    setOpen(false)
    actions.toggleUpdate()
    if (!obligation) {
      setName("")
      setPrepareOfFile("")
      setFormat("")
      setDeadlineFormat("")
      setThreshold("")
      setLocalLanguage("")
    }
  }, [actions, obligation]);

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "75%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  }

  const [name, setName] = useState(obligation ? obligation.name : "")
  const [prepareOfFile, setPrepareOfFile] = useState(obligation ? obligation.prepareOfFile : "")
  const [format, setFormat] = useState(obligation ? obligation.format : "")
  const [deadlineFormat, setDeadlineFormat] = useState(obligation ? obligation.deadlineFormat : "")
  const [notificationDeadlineFormat, setNotificationDeadlineFormat] = useState(obligation ? obligation.notificationDeadlineFormat : "")
  const [threshold, setThreshold] = useState(obligation ? obligation.threshold : "")
  const [localLanguage, setLocalLanguage] = useState(obligation ? obligation.localLanguage : "")

  const auth = useContext(AuthContext)

  const handleSubmit = useCallback(async () => {
    try {
      const countryCode = COUNTRY_NAME_TO_COUNTRY_CODES[country];
      const continentCode = getContinentCode({ countryCode });
      const url = obligation ? `${process.env.REACT_APP_API_PROXY}/api/obligations/${encodeURIComponent(obligation._id)}` : `${process.env.REACT_APP_API_PROXY}/api/obligations`
      const method = obligation ? "PUT" : "POST"
      await fetch(url, {
        method: method,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer " + auth?.user?.token
        },
        body: JSON.stringify({
          name,
          country,
          countryCode,
          geographicalRegionCode: !isEmpty(geographicalRegionCode) ? geographicalRegionCode : undefined,
          continentCode,
          prepareOfFile,
          format,
          deadlineFormat,
          notificationDeadlineFormat,
          threshold,
          localLanguage
        })
      })
      handleClose()
    } catch (error) {
      console.log(error)
    }
  }, [auth?.user?.token, country, deadlineFormat, format, geographicalRegionCode, getContinentCode, handleClose, localLanguage, name, notificationDeadlineFormat, obligation, prepareOfFile, threshold]);

  return (
    <Fragment>
      {obligation ?
        <IconButton variant="contained" onClick={handleOpen}>
          <EditIcon />
        </IconButton>
      :
        <Button color="primary" variant="contained" onClick={handleOpen}>
          Add Obligation
        </Button>
      }
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <ValidatorForm onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Header */}
              <Grid item xs={12}>
              {obligation ? "Edit" : "New"} obligation for {country}{geographicalRegionCode ? `, ${geographicalRegionCode}` : ''}
                <Divider sx={{ p: 1 }} />
              </Grid>

              {/* Name */}
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>

              {/* Prepare of File */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Prepare of File"
                  value={prepareOfFile}
                  onChange={(e) => setPrepareOfFile(e.target.value)}
                />
              </Grid>

              {/* Format */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Format"
                  value={format}
                  onChange={(e) => setFormat(e.target.value)}
                />
              </Grid>

              {/* Deadline Format */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Deadline Format"
                  value={deadlineFormat}
                  onChange={(e) => setDeadlineFormat(e.target.value)}
                />
              </Grid>

              {/* Notification Deadline Format */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Notification Deadline Format"
                  value={notificationDeadlineFormat}
                  onChange={(e) => setNotificationDeadlineFormat(e.target.value)}
                />
              </Grid>

              {/* Threshold */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Threshold"
                  value={threshold}
                  onChange={(e) => setThreshold(e.target.value)}
                />
              </Grid>

              {/* Local Language */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Local Language"
                  value={localLanguage}
                  onChange={(e) => setLocalLanguage(e.target.value)}
                />
              </Grid>

              {/* Submit */}
              <Grid item xs={12}>
                <Button variant="contained" type="submit">Submit</Button>
              </Grid>
            </Grid>
          </ValidatorForm>
        </Box>
      </Modal>
    </Fragment>
  )
}

export default ObligationModal
