import { Grid } from "@mui/material";

import useFilesHelper from "hooks/useFilesHelper";
import { useDispatch } from "react-redux";
import { setFilesList, setFilesPageFilters } from "redux/slices/filesSlice";
import FilesTable from "../FilesTable";
import FilesTableFilter from "../FilesTable/FilesTableFilter";

const FilesTableSection = () => {
    const dispatch = useDispatch();

    const { filteredFilesTableData } = useFilesHelper();

    const handleFilterChange = (props) => {
        dispatch(setFilesPageFilters({ pageFilters: props }));
    };

    const handleDeleteFile = (filesList) => {
        dispatch(setFilesList({ filesList }));
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <FilesTableFilter onFilterChange={handleFilterChange} />
            </Grid>
            <Grid item xs={12}>
                <FilesTable data={filteredFilesTableData} onDeleteClick={handleDeleteFile} />
            </Grid>
        </Grid>
    );
}

export default FilesTableSection;