import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { isEmpty } from "lodash-es";

import useMonexApiHelper from "hooks/useMonexApiHelper";
import { selectOwnBankAccountDetail } from "redux/slices/selectors";
import BankAccountsDetailContent from "../BankAccountsDetailContent";

const OwnAccountsDetail = () => {
    const { id: ownAccountIdParam } = useParams();
    const ownBankAccountDetail = useSelector(selectOwnBankAccountDetail);
    const { id: ownAccountId } = ownBankAccountDetail;

    const isPageLoading = useMemo(() => (
        isEmpty(ownBankAccountDetail) || ownAccountIdParam !== ownAccountId
    ), [ownBankAccountDetail, ownAccountId, ownAccountIdParam]);

    const { fetchOwnBankAccountDetail } = useMonexApiHelper();

    useEffect(() => {
        if (isPageLoading) {
            fetchOwnBankAccountDetail({ id: ownAccountIdParam });
        }
      }, [fetchOwnBankAccountDetail, isPageLoading, ownAccountIdParam]);

    return <BankAccountsDetailContent data={ownBankAccountDetail} isLoading={isPageLoading} />
};

export default OwnAccountsDetail;