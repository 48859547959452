import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    obligationList: [],
};

const obligationsSlice = createSlice({
    name: 'obligations',
    initialState,
    reducers: {
        setObligationList: (state, action) => {
            state.obligationList = action.payload.obligationList
        },
    }
});

export const {
    setObligationList,
} = obligationsSlice.actions;

export default obligationsSlice;