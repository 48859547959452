import { Divider, Grid, Typography } from "@mui/material";
import moment from "moment";

import PaymentStatusTag from "../PaymentStatusTag";
import PaymentHeader from "./PaymentHeader";
import PaymentReceiptDetail from "./PaymentReceiptDetail";

const PaymentReceipt = ({ task = {}, payment = {}, trade = {}, targetRef }) => {
    const { name: taskName } = task;
    const { sellCurrencyCode, sellAmount } = trade;
    const { id: paymentId, bankAccount = {}, authorisedBy, status: paymentStatus, currencyCode, amount, valueDate, reference, purpose, instructedOn } = payment;
    const { accountName, accountNumber, iban, bic } = bankAccount;

    return (
        <Grid ref={targetRef} container spacing={3} width={800}>
            <Grid item xs={12}>
                <Grid container>
                    <PaymentHeader header="Linked Task" detail={taskName} />
                    <PaymentHeader header="Payment ID" detail={paymentId} />
                </Grid>
                <Divider sx={{ marginTop: '12px', marginBottom: '12px' }} />
                <Grid container>
                    {/* Payment Details */}
                    <Grid
                        item
                        xs={12}
                        sx={{
                            padding: '32px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 700,
                                fontSize: '16px',
                                color: '#0050B5',
                                marginBottom: '12px',
                            }}
                        >
                            PAYMENT DETAILS
                        </Typography>
                        <PaymentReceiptDetail header="Payment Amount" detail={`${currencyCode} ${amount?.toFixed(2)}`} />
                        <PaymentReceiptDetail header={`Equivalent in ${sellCurrencyCode}`} detail={`${sellCurrencyCode} ${sellAmount?.toFixed(2)}`} />
                        <PaymentReceiptDetail header="Value Date" detail={moment(valueDate).format("lll")} />
                        <PaymentReceiptDetail header="Client Reference" detail={reference} />
                        <PaymentReceiptDetail header="Purpose" detail={purpose} />
                    </Grid>

                    {/* Payment Status */}
                    <Grid
                        item
                        xs={12}
                        sx={{
                            padding: '32px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 700,
                                fontSize: '16px',
                                color: '#0050B5',
                                marginBottom: '12px',
                            }}
                        >
                            PAYMENT STATUS
                        </Typography>
                        <PaymentReceiptDetail header="Payment Status" detailElement={<PaymentStatusTag status={paymentStatus} />} />
                        <PaymentReceiptDetail header="Instructed by" detail={authorisedBy} />
                        <PaymentReceiptDetail header="Instructed on" detail={moment(instructedOn).format("lll")} />
                    </Grid>

                    {/* Bank Account Details */}
                    <Grid
                        item
                        xs={12}
                        sx={{
                            padding: '32px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontWeight: 700,
                                fontSize: '16px',
                                color: '#0050B5',
                                marginBottom: '12px',
                            }}
                        >
                            BANK ACCOUNT DETAILS
                        </Typography>
                        <PaymentReceiptDetail header="Account Name" detail={accountName} />
                        <PaymentReceiptDetail header="Account Number" detail={accountNumber} />
                        <PaymentReceiptDetail header="IBAN" detail={iban} />
                        <PaymentReceiptDetail header="BIC" detail={bic} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default PaymentReceipt;
