export const classes = {
    modal: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "50%",
        maxHeight: "80vh",
        overflow: "auto",
        backgroundColor: "white",
        boxShadow: 24,
        padding: '32px',
    },
    modalHeader: {
        fontSize: '16px',
        fontWeight: 700,
        color: '#0050B5',
    },
    sectionHeader: {
        fontSize: '14px',
        fontWeight: 700,
        color: '#000000',
    },
    verifySection: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        paddingTop: '8px',
    },
    buttons: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        marginTop: '16px',
        gap: '8px',
    },
}