
import { FilterListOff } from "@mui/icons-material";
import { Box, ButtonGroup, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import ButtonWithTooltip from "components/common/ButtonWithTooltip";
import { MONTH_MAPPING_PER_QUARTER, QUARTERS, YEARS } from "helpers/constants";

import { COUNTRY_CODE_TO_NAME } from "helpers/countries";
import { CANADA_PROVINCE_CODE_TO_NAME, CANADA_PROVINCE_LIST, CONTINENT_GROUP, CONTINENT_GROUP_COUNTRIES_MAPPING, CONTINENT_GROUP_ORDER, US_STATES_LIST, US_STATE_CODE_TO_NAME } from "helpers/regions";
import { UsersContext } from "helpers/users";
import { NEUTRAL_REGION_COLOR } from "hooks/useMapChartHelper";
import { useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { INITIAL_FILING_CHART_FILTERS, setMapChartTab } from "redux/slices/filingsSlice";
import { selectFilesPageFilters } from "redux/slices/selectors";

const FilesTableFilter = ({ onFilterChange }) => {
    const dispatch = useDispatch();
    const users = useContext(UsersContext);

    const pageFilters = useSelector(selectFilesPageFilters);
    const {
        continent: continentFilter,
        country: countryFilter,
        region: regionFilter,
        legalEntity: legalEntityFilter,
        year: yearFilter,
        quarter: quarterFilter,
        month: monthFilter,
    } = useMemo(() => pageFilters ?? {}, [pageFilters]);

    const regionFilterLabel = countryFilter === 'US' ? 'States' : 'Provinces';
    const shouldShowRegionFilter = useMemo(() => countryFilter === 'US' || countryFilter === 'CA', [countryFilter]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sx={{ paddingBottom: '16px', marginLeft: '16px', borderBottom: `1px solid ${NEUTRAL_REGION_COLOR}` }}>
                <Grid container spacing={2}>
                    {/* Region */}
                    <Grid item xs={2}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel shrink>
                                Region
                            </InputLabel>
                            <Select
                                displayEmpty
                                notched
                                label="Region"
                                value={continentFilter}
                                onChange={(e) => {
                                    onFilterChange({
                                        continent: e.target.value,
                                        country: '',
                                        region: '',
                                    })
                                }}
                            >
                                <MenuItem key={"All Regions"} value={CONTINENT_GROUP.GENERAL}>
                                    All Regions
                                </MenuItem>
                                {CONTINENT_GROUP_ORDER.map(continentCode => {
                                    return (
                                        <MenuItem key={continentCode} value={continentCode}>
                                            {continentCode}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Country */}
                    <Grid item xs={2}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel shrink>
                                Country
                            </InputLabel>
                            <Select
                                displayEmpty
                                notched
                                label="Country"
                                value={countryFilter}
                                onChange={(e) => {
                                    onFilterChange({
                                        country: e.target.value,
                                        region: '',
                                    })
                                }}
                            >
                                <MenuItem key={"All Countries"} value="">
                                    All Countries
                                </MenuItem>
                                {CONTINENT_GROUP_COUNTRIES_MAPPING[continentFilter].map(countryCode => {
                                    return (
                                        <MenuItem key={countryCode} value={countryCode}>
                                            {COUNTRY_CODE_TO_NAME[countryCode]}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* State/Province */}
                    {shouldShowRegionFilter && (
                        <Grid item xs={2}>
                            <FormControl style={{ width: "100%" }}>
                                <InputLabel shrink>
                                    {regionFilterLabel}
                                </InputLabel>
                                <Select
                                    displayEmpty
                                    notched
                                    label={regionFilterLabel}
                                    value={regionFilter}
                                    onChange={(e) => onFilterChange({ region: e.target.value })}
                                >
                                    <MenuItem key={"All Regions"} value="">
                                        All {regionFilterLabel}
                                    </MenuItem>
                                    {countryFilter === 'US' && US_STATES_LIST.map((stateCode) => {
                                        const stateName = US_STATE_CODE_TO_NAME[stateCode];
                                        return (
                                            <MenuItem key={stateCode} value={stateCode}>
                                                {stateName}
                                            </MenuItem>
                                        );
                                    })}
                                    {countryFilter === 'CA' && CANADA_PROVINCE_LIST.map((provinceCode) => {
                                        const provinceName = CANADA_PROVINCE_CODE_TO_NAME[provinceCode];
                                        return (
                                            <MenuItem key={provinceCode} value={provinceCode}>
                                                {provinceName}
                                            </MenuItem>
                                        );
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                    )}

                    {/* Legal Entity */}
                    <Grid item xs={2}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel shrink>
                                Legal Entity
                            </InputLabel>
                            <Select
                                displayEmpty
                                notched
                                label="Legal Entity"
                                value={legalEntityFilter}
                                onChange={(e) => {
                                    onFilterChange({
                                        legalEntity: e.target.value,
                                    })
                                }}
                            >
                                <MenuItem key={"All Legal Entities"} value="">
                                    All Legal Entities
                                </MenuItem>
                                {users?.entityList?.entities?.map(ent => (
                                    <MenuItem
                                        key={ent._id}
                                        value={ent._id}
                                    >
                                        {ent.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Year */}
                    <Grid item xs={2}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel shrink>
                                Year
                            </InputLabel>
                            <Select
                                displayEmpty
                                notched
                                label="Year"
                                value={yearFilter}
                                onChange={(e) => {
                                    onFilterChange({
                                        year: e.target.value,
                                    })
                                }}
                            >
                                <MenuItem key={"All Years"} value="">
                                    All Years
                                </MenuItem>
                                {YEARS.map(year => (
                                    <MenuItem
                                        key={year}
                                        value={year}
                                    >
                                        {year}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Quarter */}
                    <Grid item xs={2}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel shrink>
                                Quarter
                            </InputLabel>
                            <Select
                                displayEmpty
                                notched
                                label="Quarter"
                                value={quarterFilter}
                                onChange={(e) => {
                                    onFilterChange({
                                        quarter: e.target.value,
                                        month: '',
                                    })
                                }}
                            >
                                <MenuItem key={"All Quarters"} value="">
                                    All Quarters
                                </MenuItem>
                                {QUARTERS.map(quarter => (
                                    <MenuItem
                                        key={quarter}
                                        value={quarter}
                                    >
                                        {quarter}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Month */}
                    <Grid item xs={2}>
                        <FormControl style={{ width: "100%" }}>
                            <InputLabel shrink>
                                Month
                            </InputLabel>
                            <Select
                                displayEmpty
                                notched
                                label="Month"
                                value={monthFilter}
                                onChange={(e) => {
                                    onFilterChange({
                                        month: e.target.value,
                                    })
                                }}
                            >
                                <MenuItem key={"All Months"} value="">
                                    All Months
                                </MenuItem>
                                {(MONTH_MAPPING_PER_QUARTER[quarterFilter] ?? MONTH_MAPPING_PER_QUARTER.ALL).map(month => (
                                    <MenuItem
                                        key={month}
                                        value={month}
                                    >
                                        {month}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item flex={1}>
                <Box
                    sx={{
                        display: 'flex',
                        height: '50px',
                        width: '100%',
                        justifyContent: 'flex-end',
                        gap: '16px',
                    }}
                >
                    <ButtonGroup>
                        <ButtonWithTooltip
                            tooltipText="Clear all filters"
                            variant="outlined"
                            startIcon={<FilterListOff />}
                            onClick={() => {
                                onFilterChange(INITIAL_FILING_CHART_FILTERS);
                                dispatch(setMapChartTab({ mapChartTab: 0 }));
                            }}
                        >
                            CLEAR FILTERS
                        </ButtonWithTooltip>
                    </ButtonGroup>
                </Box>
            </Grid>
        </Grid>
    );
}

export default FilesTableFilter;
